import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./CandidatePipeline.scss";
import { API_URL } from "../../../shared";

const CandidatePipeline = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        setLoading(true);
        const url = `${API_URL}/candidates/pipeline`;
        
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });
        
        if (response.ok) {
          const data = await response.json();
          console.log('Received pipeline candidates:', data); // Debug log
          setCandidates(data);
        } else {
          throw new Error(t('candidatePipeline.messages.loadError'));
        }
      } catch (error) {
        console.error('Error fetching candidates:', error);
        setError(t('candidatePipeline.messages.loadError'));
      } finally {
        setLoading(false);
      }
    };

    fetchCandidates();
  }, []);

  const filteredCandidates = candidates.filter(
    (candidate) =>
      candidate.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      candidate.currentTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      candidate.currentCompany?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      candidate.sourcedFor?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      candidate.sourcedFrom?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleViewDetails = (candidateId) => {
    navigate(`/b2b/dashboard/candidates/pipeline/${candidateId}`);
  };

  const handleMoveToPool = async (candidateId) => {
    try {
      const response = await fetch(`${API_URL}/candidates/move-to-pool/${candidateId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (response.ok) {
        setCandidates(prevCandidates => prevCandidates.filter(c => c.id !== candidateId));
        setError(null);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || t('candidatePipeline.messages.moveToPoolError'));
      }
    } catch (error) {
      console.error('Error moving candidate to pool:', error);
      setError(t('candidatePipeline.messages.moveToPoolError'));
    }
  };

  const handleDeleteCandidate = async (candidateId) => {
    try {
      const response = await fetch(`${API_URL}/candidates/pipeline/${candidateId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (response.ok) {
        setCandidates(prevCandidates => 
          prevCandidates.filter(candidate => candidate.id !== candidateId)
        );
        setError(null);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || t('candidatePipeline.messages.deleteError'));
      }
    } catch (error) {
      console.error('Error deleting candidate:', error);
      setError(t('candidatePipeline.messages.deleteError'));
    }
  };

  const handleJobClick = (jobOfferId) => {
    if (jobOfferId) {
      navigate(`/b2b/dashboard/jobs/${jobOfferId}`);
    }
  };

  const handleActionChange = (e, candidateId) => {
    const action = e.target.value;

    switch (action) {
      case "details":
        handleViewDetails(candidateId);
        break;
      case "moveToPool":
        handleMoveToPool(candidateId);
        break;
      case "delete":
        if (window.confirm(t('candidatePipeline.messages.deleteConfirmation'))) {
          handleDeleteCandidate(candidateId);
        }
        break;
      default:
        break;
    }

    e.target.value = "default";
  };

  return (
    <div className="candidate-pipeline">
      <div className="pipeline-header">
        <h2>{t("candidatePipeline.title")}</h2>
      </div>

      {error && (
        <div className="error-message" style={{ color: 'red', margin: '10px 0' }}>
          {error}
        </div>
      )}

      <div className="search-bar">
        <input
          type="text"
          placeholder={t("candidatePipeline.searchPlaceholder")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="pipeline-table-container">
        {loading ? (
          <div className="loading" style={{ textAlign: 'center', padding: '20px' }}>
            {t("candidatePipeline.loading")}
          </div>
        ) : (
          <table className="pipeline-table">
            <thead>
              <tr>
                <th>{t("candidatePipeline.columns.name")}</th>
                <th>{t("candidatePipeline.columns.currentTitle")}</th>
                <th>{t("candidatePipeline.columns.sourcedFor")}</th>
                <th>{t("candidatePipeline.columns.sourcedFrom")}</th>
                <th>{t("candidatePipeline.columns.linkedin")}</th>
                <th>{t("candidatePipeline.columns.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {filteredCandidates.length > 0 ? (
                filteredCandidates.map((candidate) => (
                  <tr key={candidate.id}>
                    <td>{candidate.name}</td>
                    <td>{candidate.currentTitle}</td>
                    <td>{candidate.sourcedFor}</td>
                    <td>{candidate.sourcedFrom}</td>
                    <td>
                      {candidate.linkedinUrl ? (
                        <a
                          href={candidate.linkedinUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("candidatePipeline.actions.viewProfile")}
                        </a>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="actions-column">
                      <select
                        defaultValue="default"
                        onChange={(e) => handleActionChange(e, candidate.id)}
                        className="action-select"
                      >
                        <option value="default" disabled>
                          {t("candidatePipeline.actions.selectAction")}
                        </option>
                        <option value="details">
                          {t("candidatePipeline.actions.viewDetails")}
                        </option>
                        <option value="moveToPool">
                          {t("candidatePipeline.actions.moveToPool")}
                        </option>
                        <option value="delete">
                          {t("candidatePipeline.actions.delete")}
                        </option>
                      </select>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" style={{ textAlign: 'center' }}>
                    {t("candidatePipeline.messages.noCandidatesFound")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CandidatePipeline;
