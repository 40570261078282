import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./AIPhoneInterviewReturn.scss";
import { API_URL } from '../../../../shared.js';

const AIPhoneInterviewReturn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams(); // Récupère l'id de l'interview depuis l'URL
  const [isLoading, setIsLoading] = useState(true);
  const [interview, setInterview] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInterview = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(`${API_URL}/ai-phone-interviews/${id}`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          console.log("Interview data:", {
            hasQuestions: data.questions?.length > 0,
            hasAnswers: data.answers && Object.keys(data.answers).length > 0,
            hasAnalysis: data.ai_analysis && Object.keys(data.ai_analysis).length > 0
          });
          setInterview(data);
        } else {
          throw new Error("Failed to fetch interview data.");
        }
      } catch (error) {
        console.error("Error fetching interview:", error);
        setError(error.message || "An unexpected error occurred.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchInterview();
  }, [id]);

  if (isLoading) {
    return <div>{t("aiPhoneInterview.details.loading")}</div>;
  }

  if (error) {
    return <div className="error-message">{t("common.error", { error })}</div>;
  }

  if (!interview) {
    return (
      <div className="not-found">{t("aiPhoneInterview.details.notFound")}</div>
    );
  }

  return (
    <div className="mock-interview-page">
      <div className="mock-interview-page__header">
        <h2>{interview.form_name}</h2>
        {interview.Job_Offer && (
          <p className="mock-interview-page__job-role">
            {t("common.jobRole")}: {interview.Job_Offer.role}
          </p>
        )}
      </div>
      
      <div className="mock-interview-page__content">
        {interview.answers && 
         interview.ai_analysis && 
         Object.keys(interview.answers).length > 0 && 
         Object.keys(interview.ai_analysis).length > 0 ? (
          <>
            {/* AI Analysis Summary Section */}
            <div className="mock-interview-page__summary-card">
              <h3 className="summary-title">{t("common.aiAnalysisSummary")}</h3>
              <div className="summary-content">
                {Object.values(interview.ai_analysis).map((analysis, index) => (
                  <div key={`analysis-${index}`} className="analysis-item">
                    <p className="analysis-text">{analysis}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Questions and Answers Section */}
            {interview.questions.map((question, index) => (
              <div key={index} className="mock-interview-page__qa-card">
                <div className="mock-interview-page__question">
                  <span className="question-number">Q{index + 1}.</span>
                  <span className="question-text">{question}</span>
                </div>
                
                {interview.answers[index] && (
                  <div className="mock-interview-page__answer">
                    <span className="answer-label">{t("common.answer")}:</span>
                    <p className="answer-text">{interview.answers[index]}</p>
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <div className="mock-interview-page__no-content">
            {t("aiPhoneInterview.noAnswersYet")}
          </div>
        )}
      </div>

      <button
        onClick={() => navigate(-1)}
        className="mock-interview-page__back-button"
      >
        {t("aiPhoneInterview.back")}
      </button>
    </div>
  );
};

export default AIPhoneInterviewReturn;
