
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "../admin.scss";
import { FaEdit } from "react-icons/fa";
import { MdAddCircle, MdCancel, MdDelete, MdUpload } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../shared";

const AdminDashboardUserListing = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(null);
  const [newUser, setNewUser] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    role: "",
    email: "",
    password: "",
    phone: "",
    city: "",
  });
  const [isEditing, setIsEditing] = useState(false);
   const navigate = useNavigate();
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setNewUser({
      firstName: "",
      lastName: "",
      companyName: "",
      role: "",
      email: "",
      password: "",
      phone: "",
      city: "",
    });
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleSaveUser = async (e) => {
    e.preventDefault(); 
    let path = isEditing ? `${API_URL}/admin/user/${id}` : `${API_URL}/signup`;
    if(isEditing) {
      delete newUser.password;
      delete newUser.companyName;
    }
    try {
      const response = await fetch(path, {
        method: isEditing ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newUser),
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error( errorData.message || "Error Registering User")
        return;
      
      }

      const data = await response.json();

      if (data.success) {
        const message = isEditing ? t("accountEditedSuccessAdmin") : t("accountCreatedSuccessAdmin");
        toast.success(message)
      } else {
        toast.error(data.message || t("errorDuringSignup"))
      }
    } catch (error) {
      console.log("Error during signup:", error);
      console.log(error.message || t("errorDuringSignup"));
      toast.error("Error during signup")
    }
    fetchUsers();
    handleModalClose();
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${API_URL}/admin/user/${id}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        }
      });
   
      if (!response.ok) {
        toast.error("Failed to delete user.")
        throw new Error(`Failed to delete user: ${response.status}`);
      }
   
      const data = await response.json();
      if (data.success) {
        toast.success("User deleted successfully.")
        fetchUsers(); 
      }
   
    } catch (error) {
      console.error("Delete error:", error);
    }
  };

  const handleEdit = (user) => {
    console.log(user);
    setId(user.id);
    setNewUser({
      firstName: user.first_name,
      lastName: user.last_name,
      role: user.role,
      email: user.email,
      phone: user.phone,
      city: user.city,
    });
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const handleLogin = async (user) => {
     try {
          const response = await fetch(`${API_URL}/admin/login-as-user/${user.id}`, {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
          });
    
          console.log("Response status:", response.status);
    
          if (!response.ok) {
             toast.error(result.message || t("loginFailed"))
            throw new Error(`HTTP error! status: ${response.status}`);
          }
    
          const result = await response.json();
          console.log("Response data:", result);
    
          if (result.success) {
            // Stocker les informations complètes de l'utilisateur
            sessionStorage.setItem(
              "user",
              JSON.stringify({
                id: result.user.id,
                role: result.user.role,
                tenant_id: result.user.tenant_id,
                first_name: result.user.first_name,
                last_name: result.user.last_name,
                tenant: result.user.tenant,
                company: result.user.company,
              })
            );
    
            if (typeof setIsAuthenticated === "function") {
              setIsAuthenticated(true);
              console.log("Authentication state updated");
            }
    
              // Navigation basée sur le rôle
              if (result.user.role === "company") {
                console.log("Navigating to company dashboard");
                navigate("/b2b/dashboard");
              } else if (result.user.role === "reseller") {
                console.log("Navigating to reseller dashboard");
                navigate("/b2b/dashboard"); // TODO: change to reseller dashboard
              } else if (result.user.role === "client_console") {
                console.log("Navigating to client console dashboard");
                navigate("/b2b/dashboard"); // TODO: change to client console dashboard  
              } else if (result.user.role === "candidate") {
                console.log("Navigating to candidate dashboard");
                navigate("/b2c/dashboard");
              } else {
                navigate("/");
              }
          } else {
            setError(result.message || t("loginFailed"));
            toast.error(result.message || t("loginFailed"))
            
          }
        } catch (error) {
          console.error("Login error:", error);
          setError(t("loginFailed"));
        }
  }

  const navigateToUpload = () => {
    navigate("/admin/dashboard/users/upload");
  }

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${API_URL}/user`, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        throw new Error("Failed to fetch users");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  
  const fetchCompanies = async () => {
    try {
      const response = await fetch(`${API_URL}/admin/company`, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();        
        setCompanies(data);
      } else {
        throw new Error("Failed to fetch companies");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchCompanies();
  }, []);

  return (
    <>
      {/* Add/Edit User Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="modal-close p-3 m-5" onClick={handleModalClose}>
              <MdCancel />
            </button>
            <div className="modal-form">
              <form onSubmit={handleSaveUser}>
                <div className="form-group">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={newUser.firstName}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lasName"
                    name="lastName"
                    value={newUser.lastName}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                { !isEditing && (
                  <div className="form-group">
                    <label htmlFor="companyName">Company Name</label>
                    <select
                      id="companyName"
                      name="companyName"
                      value={newUser.companyName}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select company</option>
                      {companies.map((company) => {
                        return <option key={company.name} value={company.name}>{ company.name }</option>
                      })}
                    </select>
                  </div>
                )}

                <div className="form-group">
                  <label htmlFor="role">Select Role</label>
                  <select
                    id="role"
                    name="role"
                    value={newUser.role}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select user role</option>
                    <option value="admin">Admin</option>
                    <option value="candidate">Candidate</option>
                    <option value="company">Company</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={newUser.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                { !isEditing && (
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={newUser.password}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                )}

                <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={newUser.phone}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={newUser.city}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <button type="submit">
                  {isEditing ? "Update User" : "Add User"}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Users List */}
      <div className="list">
        <div className="top-header">
          <h2>Users List</h2>
          <div style={{ display: "flex", gap: "2" }}>
            <button onClick={navigateToUpload}>
              Bulk Upload <MdUpload />
            </button>
            <button onClick={handleModalOpen} style={{ marginLeft: "5px"}}>
              Add User <MdAddCircle  className="add-icon-padding"/>
            </button>
          </div>
        </div>
        <table style={{ marginTop: "10px" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Full Name</th>
              <th>Company Name</th>
              <th>Role</th>
              <th>Email</th>
              <th>Phone</th>
              <th>City</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.email}>
                <td>{index + 1}</td>
                <td>
                  {user.first_name} {user.last_name}
                </td>
                <td>{user?.Company?.name}</td>
                <td>{user.role}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>{user.city}</td>
                <td>
                  {/* Hide actions for users with role "Admin" */}
                  {user.role !== "Admin" && user.role !== "admin" && (
                    <div>
                      <button
                        className="trash-icon"
                        onClick={() => {
                          if (
                            window.confirm(
                              t("Are you sure you want to delete this user?")
                            )
                          ) {
                            handleDelete(user.id);
                          }
                        }}
                      >
                        <MdDelete className="trash-icon" />
                      </button>

                      <button
                        onClick={() => handleEdit(user)}
                        className="edit-icon"
                      >
                        <FaEdit className="edit-icon" />
                      </button>

                      <button
                        onClick={() => {
                          if (
                            window.confirm(
                              t("Are you sure you want to login with this user?")
                            )
                          ) {
                            handleLogin(user)
                          }
                        }}
                        className="login-btn"
                      >
                        Login
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AdminDashboardUserListing;
