import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import "./AnalysisSoftSkills.scss";
import { API_URL } from "../../../../../shared.js";

const AnalysisSoftSkills = ({ assessmentId, onClose }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      console.log("Fetching soft skills analysis for assessmentId:", assessmentId);
      try {
        const response = await fetch(`${API_URL}/assessmentCandidateAnswer/${assessmentId}/analysis`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const result = await response.json();
        console.log("Soft skills analysis result:", result);

        const parsedAnalysis = typeof result.analysis_content === 'string' 
          ? JSON.parse(result.analysis_content) 
          : result.analysis_content;
          
        setData({
          ...parsedAnalysis,
          totalScore: result.candidate_score || 0,
          percentage: `${result.candidate_score || 0}%`
        });
      } catch (err) {
        console.error("Error fetching analysis:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (assessmentId) {
      fetchData();
    }
  }, [assessmentId]);

  return (
    <div className="assessment-page-analysis">
      <div className="header">
        <h1>Soft-Skills Assessment Analysis</h1>
        <button className="close-btn" onClick={onClose}>
          <FaTimes />
        </button>
      </div>

      {loading ? (
        <p className="loading-message">Loading analysis...</p>
      ) : error ? (
        <p className="error-message">Error: {error}</p>
      ) : (
        <div className="analysis-report">
          <h2>Evaluation Report</h2>

          <section className="performance-summary">
            <h3>Overall Performance Summary</h3>
            <div className="summary-cards">
              <div className="card">
                <h4>Total Score</h4>
                <p>{data.totalScore}</p>
              </div>
              <div className="card">
                <h4>Questions Evaluated</h4>
                <p>{data.totalQuestions}</p>
              </div>
              <div className="card">
                <h4>Success Rate</h4>
                <p>{data.percentage}</p>
              </div>
            </div>
          </section>

          <section className="skills-analysis">
            <h3>Skills Breakdown</h3>
            <div className="skills-grid">
              {data.skillsAnalysis && Object.entries(data.skillsAnalysis).map(([skill, score], index) => (
                <div key={index} className="skill-card">
                  <h4>{skill}</h4>
                  <div className="skill-score-bar">
                    <div 
                      className="skill-score-progress"
                      style={{ width: `${score}%` }}
                    >
                      {score}%
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>

          <section className="global-analysis">
            <h3>Global Analysis</h3>
            <div className="analysis-item">
              <h4>Key Strengths</h4>
              <p>{data.globalAnalysis.strengths}</p>
            </div>
            <div className="analysis-item">
              <h4>Areas for Improvement</h4>
              <p>{data.globalAnalysis.weaknesses}</p>
            </div>
            <div className="analysis-item">
              <h4>Development Recommendations</h4>
              <p>{data.globalAnalysis.recommendations}</p>
            </div>
          </section>

          <section className="detailed-evaluation">
            <h3>Detailed Response Analysis</h3>
            <table className="evaluation-table">
              <thead>
                <tr>
                  <th>Question</th>
                  <th>Response</th>
                  <th>Expected Answer</th>
                  <th>Score</th>
                  <th>Feedback</th>
                </tr>
              </thead>
              <tbody>
                {data.results.map((item, index) => (
                  <tr key={index} className={item.score === 1 ? "correct" : "incorrect"}>
                    <td>{item.question}</td>
                    <td>{item.candidateAnswer}</td>
                    <td>{item.correctAnswer}</td>
                    <td>{item.score}/1</td>
                    <td>{item.explanation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </div>
      )}
    </div>
  );
};

export default AnalysisSoftSkills; 