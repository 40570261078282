import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import "./TrackingBoardListing.scss";
import { API_URL } from "../../../shared";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const TrackingBoardListing = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [boardName, setBoardName] = useState("");
  const [boards, setBoards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isDashboard = location.pathname === "/b2b/dashboard";
  const maxBoard = 5;
  const limitBoards = isDashboard ? boards.slice(0, maxBoard) : boards;
  const hasMoreBoards = boards.length > maxBoard;
  const navigate = useNavigate();

  const createBoard = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_URL}/v1/kanban/boards`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ name: boardName }),
      });

      if (response.ok) {
        const data = await response.json();
        setBoardName("");
        setIsModalOpen(false);
        getAllboards();
        toast.success(t('trackingBoardListing.actions.create.success', { name: data.board.name }));
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create board');
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error(t('trackingBoardListing.actions.create.error', { message: error.response?.data?.error || error.message }))
    }
  };

  const deleteBoardCard = async (id) => {
    try {
      const response = await axios.delete(`${API_URL}/v1/kanban/boards/${id}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      getAllboards();
      toast.success("Board card deleted successfull!");
    } catch (err) {
      console.log(err.message);
      toast.error(err.message)
    }
  };

  const handleCreateBoard = () => {
    setIsModalOpen(true);
  };

  const getAllboards = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${API_URL}/v1/kanban/boards`, { credentials: "include" });
      if (response.ok) {
        const data = await response.json();
        setBoards(data);
      } else {
        throw new Error("Failed to fetch tracking boards");
      }
    } catch (error) {
      console.error("Error fetching boards:", error);
      toast.error(t('Error fetching tracking boards', { message: error.response?.data?.error || error.message }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllboards();
  }, []);

  return (
    <>
      <div className="tracking-board">
        <h2 className="tracking-board-title">{t('trackingBoardListing.title')}</h2>
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              {!isDashboard && (
                <button
                  type="button"
                  className="modal-close"
                  onClick={() => setIsModalOpen(false)}
                >
                  {t('trackingBoardListing.modal.close')}
                </button>
              )}
              <div className="create-board-form">
                <form onSubmit={createBoard}>
                  <label htmlFor="boardName">{t('trackingBoardListing.modal.form.label')}</label>
                  <input
                    type="text"
                    id="boardName"
                    value={boardName}
                    onChange={(e) => setBoardName(e.target.value)}
                    placeholder={t('trackingBoardListing.modal.form.placeholder')}
                    required
                  />
                  <button type="submit">{t('trackingBoardListing.modal.form.submit')}</button>
                </form>
              </div>
            </div>
          </div>
        )}
        <div className="create_board_btn">
          {!isDashboard && (
            <div className="add_column_btn" onClick={handleCreateBoard}>
              {t('trackingBoardListing.actions.createBoard')}
            </div>
          )}
        </div>
        {isLoading ? (
          <div className="loading">{t('common.loading')}</div>
        ) : Array.isArray(limitBoards) && limitBoards.length === 0 ? (
          <div className="no-boards">
            {t('trackingBoardListing.noBoards')}
          </div>
        ) : (
          <>
            {Array.isArray(limitBoards) && limitBoards.map((board) => (
              <div key={board.id}>
                <div className="boards-request-list">
                  <p>{board.name}</p>
                  <span style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                    <button
                      type="button"
                      className="card_Btn"
                      onClick={() => {
                        if (window.confirm(t('trackingBoardListing.actions.delete.confirm'))) {
                          deleteBoardCard(board?.id);
                        }
                      }}
                    >
                      <MdDelete className="delete_card_icon" />
                    </button>
                    <Link to={`/b2b/dashboard/tracking-boards/${board.id}`}>
                      {t('trackingBoardListing.actions.viewDetails')}
                    </Link>
                  </span>
                </div>
              </div>
            ))}
            {isDashboard && hasMoreBoards && (
              <div className="see-more-boards">
                <button
                  type="button"
                  className="see-more-boards-button"
                  onClick={() => navigate("/b2b/dashboard/tracking-boards")}
                >
                  {t('trackingBoardListing.actions.seeMore')}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TrackingBoardListing;
