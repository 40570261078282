import "./TrackingBoardItemListing.scss";
import { useEffect, useMemo, useState } from "react";
import TrackingBoardItemCard from "./TrackingBoardItemCard.jsx";
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import TrackingBoardTaskCard from "./TrackingBoardTaskCard.jsx";
import { API_URL } from "../../../shared.js";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { MdAddCircle } from "react-icons/md";
import { FaSlideshare } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import "./TrackingBoards.scss";
import ShareTrackingBoard from './Modals/ShareTrackingBoard.jsx';
import { toast } from "react-toastify";

const TrackingBoardItemListing = ({ boards }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [createNewBoard, setCreateBoard] = useState([]);
  const [activeBoard, setActiveBoard] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [activeTask, setActiveTask] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentBoard, setCurrentBoard] = useState({});
  const [boardItem, setBoardItem] = useState("");
  const [isSharing, setIsSharing] = useState(false);

  const navigate = useNavigate();

  const navigateToBoards = () => {
    navigate("/b2b/dashboard/tracking-boards");
  };
  const createBoardItem = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${API_URL}/v1/kanban/lists`,
        { boardId: currentBoard.id, name: boardItem, position: 1 },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      toast.success(`Board item created successfully.`);
      setBoardItem("");
      getCurrentBoard();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error:", error.message);
      toast.error(`Error: ${error.response?.data?.error || error.message}`)
    }
  };

  const updateTaskBoard = async (taskId, newBoardId) => {
    try {
      await axios.put(
        `${API_URL}/v1/kanban/cards/${taskId}`,
        { list_id: newBoardId },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      getCurrentBoard();
      toast.success('Task updated successfull!')
    } catch (error) {
      console.error("Failed to update task:", error);
      toast.error("Failed to update task:", error)
    }
  };

  const handleCreateBoard = () => setIsModalOpen(true);

  const listId = useMemo(
    () => createNewBoard.map((list) => list.id),
    [createNewBoard]
  );
  const deleteBoardCard = async (id, board_id) => {
    try {
      const response = await axios.delete(
        `${API_URL}/v1/kanban/boards/item/${id}`, // URL
        {
          headers: { "Content-Type": "application/json" }, // Headers
          data: { board_id }, // Delete-specific data
          withCredentials: true, // Include credentials if needed
        }
      );
      getCurrentBoard();
      toast.success("Board card deleted successfull!")
      console.log(response);
    } catch (err) {
      // console.log(err.message);
      toast.error(err.message)
    }
  };

  const onDragStart = (event) => {
    const { active } = event;
    if (active.data.current?.type === "task") {
      setActiveTask(active.data.current?.task);
    } else if (active.data.current?.type === "board") {
      setActiveBoard(active.data.current?.board);
    }
  };

  const onDragOver = (event) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const activeType = active.data.current?.type;

    if (activeType === "task") {
      const activeTaskId = active.id;
      const overBoardId = over.id;

      if (over.data.current?.type === "board") {
        // Temporarily update UI for a smooth drag
        setTasks((prevTasks) =>
          prevTasks.map((task) =>
            task.id === activeTaskId ? { ...task, list_id: overBoardId } : task
          )
        );
      }
    }
  };

  const onDragEnd = (event) => {
    const { active, over, collisions } = event;

    if (!over || active.id === over.id) return;
    if (collisions.length > 1) return;

    const activeType = active.data.current?.type;

    if (activeType === "task") {
      const taskId = active.id;
      const newBoardId = over.id; // This should be the list id you want the task to be moved to.

      if (newBoardId) {
        // Update the task's board with an API call
        updateTaskBoard(taskId, newBoardId);
      }
    } else if (activeType === "board") {
      const activeIndex = createNewBoard.findIndex(
        (board) => board.id === active.id
      );
      const overIndex = createNewBoard.findIndex(
        (board) => board.id === over.id
      );

      if (activeIndex !== overIndex) {
        const reorderedBoards = [...createNewBoard];
        const [removed] = reorderedBoards.splice(activeIndex, 1);
        reorderedBoards.splice(overIndex, 0, removed);
        setCreateBoard(reorderedBoards);
      }
    }

    setActiveBoard(null);
    setActiveTask(null);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  );

  const getCurrentBoard = async () => {
    try {
      const response = await axios.get(`${API_URL}/v1/kanban/boards/${id}`, {
        withCredentials: true,
      });
      setCurrentBoard(response?.data);
      setCreateBoard(response?.data?.KanbanLists);
      const allTasks =
        response?.data?.KanbanLists?.flatMap((list) => list.KanbanCards) || [];
      setTasks(allTasks);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getCurrentBoard();
  }, []);

  const createNewTask = async (listId, title, description) => {
    const newTask = {
      list_id: listId,
      title: title,
      description: description,
      position: 1,
    };

    try {
      await axios.post(`${API_URL}/v1/kanban/cards`, newTask, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      toast.success(`Task created successfully!`);
      getCurrentBoard();
    } catch (error) {
      console.error("Error:", error.message);
      toast.error(`Error: ${error.response?.data?.error || error.message}`)
    }
  };

  const deleteTask = (id) => {
    const newTask = tasks.filter((task) => task.id !== id);
    setTasks(newTask);
  };

  const handleShareBoard = () => {
    setIsSharing(true);
  };

  return (
    <>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button
              className="modal-close"
              onClick={() => setIsModalOpen(false)}
            >
              {t('trackingBoardItemListing.modal.close')}
            </button>
            <div className="create-board-form">
              <form onSubmit={createBoardItem}>
                <label htmlFor="boardName">{t('trackingBoardItemListing.modal.form.label')}</label>
                <input
                  type="text"
                  id="boardName"
                  value={boardItem}
                  onChange={(e) => setBoardItem(e.target.value)}
                  placeholder={t('trackingBoardItemListing.modal.form.placeholder')}
                  required
                />
                <button type="submit">{t('trackingBoardItemListing.modal.form.submit')}</button>
              </form>
            </div>
          </div>
        </div>
      )}
      {/* Modal for share the board */}
      {isSharing && (
        <div className="modal-overlay" onClick={() => setIsSharing(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <ShareTrackingBoard onClose={() => setIsSharing(false)} />
          </div>
        </div>
      )}

      <div>
        <button className="backBtn" onClick={navigateToBoards}>
          {t('trackingBoardItemListing.navigation.back')}
        </button>
      </div>

      <div className="create_board_btn">
        <div>
          {t('trackingBoardItemListing.header.boardName')} <h3>{currentBoard.name}</h3>
        </div>

        {/* a réactiver pour la fonctionnalité sharedTrackingBoard */}
        {/* <div className="add_column_btn" onClick={handleShareBoard}>
          Partager le tracking board <FaSlideshare />
        </div> */}
        
        <div className="add_column_btn" onClick={handleCreateBoard}>
          {t('trackingBoardItemListing.header.createItem')} <MdAddCircle />
        </div>
      </div>
      <DndContext
        sensors={sensors}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <SortableContext items={listId}>
          <div className="Kanban_main_container">
            {createNewBoard.map((listItem) => (
              <TrackingBoardItemCard
                key={listItem?.id}
                getCurrentBoard={getCurrentBoard}
                tasks={listItem?.KanbanCards || []}
                listItem={listItem}
                deleteTask={deleteTask}
                createNewTask={createNewTask}
                deleteBoardCard={deleteBoardCard}
              />
            ))}
          </div>
        </SortableContext>
        {createPortal(
          <DragOverlay>
            {activeTask && <TrackingBoardTaskCard task={activeTask} />}
            {activeBoard && <TrackingBoardItemCard card={activeBoard} />}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </>
  );
};

export default TrackingBoardItemListing;
