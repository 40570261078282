import React, { useEffect, useState } from "react";
import { MdDelete, MdCancel, MdAddCircle } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../shared";

const AdminDashboardPromoCodes = () => {
  const [promoCodes, setPromoCodes] = useState([]);
  const [selectedPromoCode, setSelectedPromoCode] = useState(null);
  const [selectedPromoCodeId, setSelectedPromoCodeId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [promoCodeAssignee, setPromoCodeAssignee] = useState(undefined);
  const [users, setUsers] = useState([]);
  const [id, setId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [promoCodeModal, setPromoCodeModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();
  const [newPromoCode, setNewPromoCode] = useState({
    code: "",
    discountPercentage: "",
    validFrom: new Date().toISOString().split("T")[0],
    validTo: new Date().toISOString().split("T")[0],
    isActive: true,
  });

  const handlePromoCodeModalOpen = (promoCode) => {
    setSelectedPromoCode(promoCode.code)
    setSelectedPromoCodeId(promoCode.id)
    if(promoCode.user) setPromoCodeAssignee(promoCode.user?.id)
    setPromoCodeModal(true);
  };

  const handlePromoCodeModalClose = () => {
    setSelectedPromoCode(null)
    setSelectedPromoCodeId(null)
    setPromoCodeAssignee(undefined)
    setPromoCodeModal(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    setNewPromoCode({
      code: "",
      discountPercentage: "",
      validFrom: new Date().toISOString().split("T")[0],
      validTo: new Date().toISOString().split("T")[0],
      isActive: true,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPromoCode({
      ...newPromoCode,
      [name]: name === "validFrom" || name === "validTo" ? value : value.trim(),
    });
  };

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser(value);
  }

  const handleAssignPromoCode = async (e) => {
    e.preventDefault();
    let path = `${API_URL}/admin/promo-code/assign`;
    try {
      const response = await fetch(path, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          promoCodeId: selectedPromoCodeId,
          userId: selectedUser,
        }),
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message || "Error Assigning Promo Code")
        return;
      }

      const data = await response.json();

      if (data.success) {
        const message = isEditing ? "Promo code assigned successfully." : "Promo code assinged successfully.";
        toast.success(message)
      } else {
        toast.error(data.message)
      }
    } catch (error) {
      console.log("Error assigning promo code:", error);
      console.log(error.message);
      toast.error("Error assigning promo code")
    }
    setSelectedPromoCode(null)
    setSelectedPromoCodeId(null)
    setPromoCodeAssignee(undefined)
    fetchPromoCodes();
    handlePromoCodeModalClose();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let path = isEditing ? `${API_URL}/admin/promo-code/${id}` : `${API_URL}/admin/promo-code`;
    try {
      const response = await fetch(path, {
        method: isEditing ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newPromoCode),
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message || "Error Registering Promo Code")
        return;
      }

      const data = await response.json();

      if (data.success) {
        const message = isEditing ? "Promo code updated successfully." : "Promo code created successfully.";
        toast.success(message)
      } else {
        toast.error(data.message)
      }
    } catch (error) {
      console.log("Error creating promo code:", error);
      console.log(error.message);
      toast.error("Error creating promo code")
    }
    fetchPromoCodes();
    handleModalClose();
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${API_URL}/admin/promo-code/${id}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        }
      });

      if (!response.ok) {
        toast.error("Failed to delete promo code.")
        throw new Error(`Failed to delete promo code: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        toast.success("Promo code deleted successfully.")
        fetchPromoCodes();
      }

    } catch (error) {
      console.error("Delete error:", error);
    }
  };

  const handleEdit = (promoCode) => {
    setIsEditing(true);
    setId(promoCode.id);
    setNewPromoCode({
      code: promoCode.code,
      discountPercentage: promoCode.discount_percentage,
      validFrom: new Date(promoCode.valid_from).toISOString().split("T")[0],
      validTo: new Date(promoCode.valid_to).toISOString().split("T")[0],
      isActive: promoCode.is_active,
    });
    handleModalOpen();
  };


  const fetchPromoCodes = async () => {
    try {
      const response = await fetch(`${API_URL}/admin/promo-codes`, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setPromoCodes(data);
      } else {
        throw new Error("Failed to fetch promo codes");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_URL}/user`, {
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          setUsers(data);
        } else {
          throw new Error("Failed to fetch users");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

  useEffect(() => {
    fetchPromoCodes();
    fetchUsers();
  }, []);

  return (
    <>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="modal-close p-3 m-5" onClick={handleModalClose}>
              <MdCancel />
            </button>
            <div className="modal-form">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="code">Promo Code</label>
                  <input
                    type="text"
                    id="code"
                    name="code"
                    value={newPromoCode.code}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="discountPercentage">
                    Discount Percentage
                  </label>
                  <input
                    type="number"
                    id="discountPercentage"
                    name="discountPercentage"
                    value={newPromoCode.discountPercentage}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="validFrom">Valid From</label>
                  <input
                    type="date"
                    id="validFrom"
                    name="validFrom"
                    value={newPromoCode.validFrom}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="validTo">Valid To</label>
                  <input
                    type="date"
                    id="validTo"
                    name="validTo"
                    value={newPromoCode.validTo}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="isActive">Is Active</label>
                  <select
                    id="isActive"
                    name="isActive"
                    value={newPromoCode.isActive}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>

                <button type="submit">
                  {isEditing ? "Update Promo Code" : "Add Promo Code"}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}

      {promoCodeModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="modal-close p-3 m-5" onClick={handlePromoCodeModalClose}>
              <MdCancel />
            </button>
            <div className="modal-form">
              <form onSubmit={handleAssignPromoCode}>
                <div className="form-group">
                  <label htmlFor="code">Promo Code</label>
                  <input
                    type="text"
                    id="code"
                    name="code"
                    value={selectedPromoCode}
                    disabled
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="isActive">User</label>
                  <select
                    id="user"
                    name="user"
                    value={promoCodeAssignee ? promoCodeAssignee : selectedUser}
                    onChange={handleUserChange}
                    required
                    disabled={promoCodeAssignee}
                  >
                     <option value="">Select User</option>
                      {users.map((user) => {
                        return <option key={user.id} value={user.id}>{ user.first_name } { user.last_name }</option>
                      })}
                  </select>
                </div>
                    { !promoCodeAssignee && (
                    <button type="submit">
                        Assign Promo Code
                      </button>
                    )}             
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Promo Codes List */}
      <div className="list">
        <div className="top-header">
          <h2>Promo Codes</h2>
          <button onClick={handleModalOpen}>Add Promo Code <MdAddCircle className="add-icon-padding" /></button>
        </div>
        <table style={{ marginTop: "10px" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Code</th>
              <th>Discount Percentage</th>
              <th>Valid From</th>
              <th>Valid To</th>
              <th>Is Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {promoCodes.map((promoCode, index) => (
              <tr key={promoCode.id}>
                <td>{index + 1}</td>
                <td>{promoCode.code}</td>
                <td>{promoCode.discount_percentage}%</td>
                <td>{new Date(promoCode.valid_from).toLocaleDateString()}</td>
                <td>{new Date(promoCode.valid_to).toLocaleDateString()}</td>
                <td>{promoCode.is_active ? "Yes" : "No"}</td>
                <td>
                  <button
                    className="trash-icon"
                    onClick={() => {
                      if (
                        window.confirm(
                          t("Are you sure you want to delete this Promo Code?")
                        )
                      ) {
                        handleDelete(promoCode.id);
                      }
                    }}
                  >
                    <MdDelete className="trash-icon" />
                  </button>
                  <button
                    onClick={() => handleEdit(promoCode)}
                    className="edit-icon"
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => handlePromoCodeModalOpen(promoCode)}
                  >
                    {promoCode?.user ? 'View Assignee' : 'Assign Code'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AdminDashboardPromoCodes;
