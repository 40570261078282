import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './AddCandidate.scss';
import { API_URL } from '../../../shared.js';
import { toast } from 'react-toastify';

const AddCandidate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    zip_code: '',
    country: '',
    photo: '',
    linkedin_url: '',
    twitter_url: '',
    facebook_url: '',
    instagram_url: '',
    youtube_url: '',
    website_url: '',
    summary: '',
    skills: [],
    education: '',
    experience: '',
    certifications: '',
    languages: '',
    interests: '',
    availability: '',
    desired_salary: '',
    notice_period: '',
    visa_status: '',
    relocation_willingness: '',
    work_arrangement: '',
    resume: null,
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [showSharedPoolModal, setShowSharedPoolModal] = useState(false);
  const [existingCandidate, setExistingCandidate] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Validation spécifique pour le salaire
    if (name === 'desired_salary') {
      const salary = parseFloat(value);
      if (value && salary < 0) { // Vérifie uniquement si c'est négatif
        setErrorMessage(t('addCandidate.validation.salaryNegative'));
      } else {
        setErrorMessage(''); // Efface le message d'erreur si le salaire est valide
      }
    }

    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        resume: file,  
      }));
    }
  };

  const handleSkillsChange = (e) => {
    const skills = e.target.value.split(',').map(skill => skill.trim());
    setFormData(prevState => ({ ...prevState, skills }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');

    try {
      let resumeUrl = null;
      if (formData.resume) {
        const formDataFile = new FormData();
        formDataFile.append('resume', formData.resume);
        
        try {
          console.log('Uploading resume...');
          const uploadResponse = await fetch(`${API_URL}/uploadResume`, {
            method: 'POST',
            credentials: 'include',
            body: formDataFile
          });

          if (!uploadResponse.ok) {
            const errorText = await uploadResponse.text();
            console.error('Upload response error:', errorText);
            throw new Error('Failed to upload resume: ' + errorText);
          }

          const uploadData = await uploadResponse.json();
          console.log('Upload response:', uploadData);
          
          if (!uploadData.success) {
            throw new Error(uploadData.message || 'Failed to upload resume');
          }
          
          resumeUrl = uploadData.resumeUrl;
          console.log('Resume URL:', resumeUrl);
        } catch (uploadError) {
          console.error('Resume upload error:', uploadError);
          throw new Error('Failed to upload resume: ' + uploadError.message);
        }
      }

      // Create candidate with the resume URL
      const candidateData = {
        ...formData,
        resume: resumeUrl
      };

      // Remove the File object
      delete candidateData.resume;
      if (resumeUrl) {
        candidateData.resume = resumeUrl;
      }

      const response = await fetch(`${API_URL}/create-candidate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // Send formData excluding the resume
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          phone: formData.phone,
          address: formData.address,
          city: formData.city,
          zip_code: formData.zip_code,
          country: formData.country,
          linkedin_url: formData.linkedin_url,
          twitter_url: formData.twitter_url,
          facebook_url: formData.facebook_url,
          instagram_url: formData.instagram_url,
          youtube_url: formData.youtube_url,
          website_url: formData.website_url,
          summary: formData.summary,
          skills: formData.skills,
          education: formData.education,
          experience: formData.experience,
          certifications: formData.certifications,
          languages: formData.languages,
          interests: formData.interests,
          availability: formData.availability,
          desired_salary: formData.desired_salary,
          notice_period: formData.notice_period,
          visa_status: formData.visa_status,
          relocation_willingness: formData.relocation_willingness,
          work_arrangement: formData.work_arrangement,
        }),
        credentials: "include",
      });

      const data = await response.json();
      console.log("Server response:", data);

      if (response.status === 409) {
        setIsSubmitting(false);
        
        if (data.status === 'CANDIDATE_IN_SHARED_POOL') {
          setExistingCandidate({
            ...data.candidateDetails,
            candidateId: data.candidateId
          });
          setShowSharedPoolModal(true);
          return;
        } 
        
        if (data.status === 'ALREADY_IN_POOL') {
          setErrorMessage(t('addCandidate.alerts.alreadyInPool'));
          return;
        }
      }

      if (!response.ok) {
        throw new Error(data.message || t('addCandidate.alerts.error'));
      }

      toast.success(t('addCandidate.alerts.success'))

      if (formData.resume) {
        const candidateId = data.candidate.id;
        await uploadResume(formData.resume, candidateId);
      }

      navigate('/b2b/dashboard/candidates');

    } catch (error) {
      console.error('Form submission error:', error);
      setErrorMessage(error.message || t('addCandidate.alerts.error'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const uploadResume = async (resumeFile, candidateId) => {
    try {
      const formData = new FormData();
      formData.append('resume', resumeFile);
      formData.append('candidateId', candidateId); 
      const response = await fetch(`${API_URL}/uploadResume`, {
        method: 'POST',
        body: formData,
        credentials: 'include',
      });
  
      const data = await response.json();
      console.log("Resume upload response:", data);
  
      if (!response.ok) {
        throw new Error(data.message || t('addCandidate.uploadResumeError'));
      }  
    } catch (error) {
      console.error('Error uploading resume:', error);
      setErrorMessage(error.message || t('addCandidate.uploadResumeError'));
    }
  };

  const handleAddToMyPool = async () => {
    try {
      setIsSubmitting(true);
      console.log("Existing candidate data:", existingCandidate); // Debug log
      
      if (!existingCandidate || !existingCandidate.candidateId) {
        throw new Error('Invalid candidate data');
      }
      
      const response = await fetch(`${API_URL}/candidates/add-to-pool`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          id: existingCandidate.candidateId 
        }),
        credentials: 'include',
      });

      const data = await response.json();
      console.log("Add to pool response:", data);

      if (!response.ok) {
        throw new Error(data.message || t('addCandidate.alerts.addToPoolError'));
      }
       
      toast.success(t('addCandidate.alerts.addedToPool'))
      navigate('/b2b/dashboard/candidates');

    } catch (error) {
      console.error('Error adding to pool:', error);
      toast.error(error.message)
    } finally {
      setIsSubmitting(false);
      setShowSharedPoolModal(false);
    }
  };

  return (
    <div className="add-candidate">
      <div className="add-candidate-header">
        <h2>{t('addCandidate.title')}</h2>
      </div>

      {errorMessage && (
        <div className="error-message">
          {errorMessage}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        {/* Personal Information */}
        <div className="form-group">
          <label htmlFor="first_name">{t('addCandidate.personalInfo.firstName.label')}</label>
          <input 
            type="text" 
            id="first_name" 
            name="first_name" 
            value={formData.first_name} 
            onChange={handleChange} 
            placeholder={t('addCandidate.personalInfo.firstName.placeholder')}
            required 
          />
        </div>

        <div className="form-group">
          <label htmlFor="resume">{t('addCandidate.resume.label')}</label>
          <input
            type="file"
            id="resume"
            name="resume"
            onChange={handleFileUpload}
            accept=".pdf,.doc,.docx"
          />
        </div>

        <div className="form-group">
          <label htmlFor="last_name">{t('addCandidate.personalInfo.lastName.label')}</label>
          <input 
            type="text" 
            id="last_name" 
            name="last_name" 
            value={formData.last_name} 
            onChange={handleChange} 
            placeholder={t('addCandidate.personalInfo.lastName.placeholder')}
            required 
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">{t('addCandidate.personalInfo.email.label')}</label>
          <input 
            type="email" 
            id="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            placeholder={t('addCandidate.personalInfo.email.placeholder')}
            required 
          />
        </div>

        <div className="form-group">
          <label htmlFor="phone">{t('addCandidate.personalInfo.phone.label')}</label>
          <input 
            type="tel" 
            id="phone" 
            name="phone" 
            value={formData.phone} 
            onChange={handleChange} 
            placeholder={t('addCandidate.personalInfo.phone.placeholder')}
          />
        </div>

        {/* Location Information */}
        <div className="form-group">
          <label htmlFor="address">{t('addCandidate.location.address.label')}</label>
          <textarea 
            id="address" 
            name="address" 
            value={formData.address} 
            onChange={handleChange} 
            placeholder={t('addCandidate.location.address.placeholder')}
          />
        </div>

        <div className="form-group">
          <label htmlFor="city">{t('addCandidate.location.city.label')}</label>
          <input 
            type="text" 
            id="city" 
            name="city" 
            value={formData.city} 
            onChange={handleChange} 
            placeholder={t('addCandidate.location.city.placeholder')}
          />
        </div>

        {/* Professional Information */}
        <div className="form-group">
          <label htmlFor="skills">{t('addCandidate.professional.skills.label')}</label>
          <input 
            type="text" 
            id="skills" 
            name="skills" 
            value={formData.skills.join(', ')} 
            onChange={handleSkillsChange} 
            placeholder={t('addCandidate.professional.skills.placeholder')}
          />
        </div>

        <div className="form-group">
          <label htmlFor="experience">{t('addCandidate.professional.experience.label')}</label>
          <textarea 
            id="experience" 
            name="experience" 
            value={formData.experience} 
            onChange={handleChange} 
            placeholder={t('addCandidate.professional.experience.placeholder')}
          />
        </div>

        <div className="form-group">
          <label htmlFor="education">{t('addCandidate.professional.education.label')}</label>
          <textarea 
            id="education" 
            name="education" 
            value={formData.education} 
            onChange={handleChange} 
            placeholder={t('addCandidate.professional.education.placeholder')}
          />
        </div>

        {/* Social Media Links */}
        <div className="form-group">
          <label htmlFor="linkedin_url">{t('addCandidate.social.linkedin.label')}</label>
          <input 
            type="url" 
            id="linkedin_url" 
            name="linkedin_url" 
            value={formData.linkedin_url} 
            onChange={handleChange} 
            placeholder={t('addCandidate.social.linkedin.placeholder')}
          />
        </div>

        {/* Work Preferences */}
        <div className="form-group">
          <label htmlFor="desired_salary">{t('addCandidate.preferences.desiredSalary.label')}</label>
          <input 
            type="number" 
            id="desired_salary" 
            name="desired_salary" 
            value={formData.desired_salary} 
            onChange={handleChange} 
            placeholder={t('addCandidate.preferences.desiredSalary.placeholder')}
          />
        </div>

        <div className="form-group">
          <label htmlFor="availability">{t('addCandidate.preferences.availability.label')}</label>
          <input 
            type="text" 
            id="availability" 
            name="availability" 
            value={formData.availability} 
            onChange={handleChange} 
            placeholder={t('addCandidate.preferences.availability.placeholder')}
          />
        </div>

        <div className="form-actions">
          <button 
            type="button" 
            className="cancel-button"
            onClick={() => navigate('/b2b/dashboard/candidates')}
            disabled={isSubmitting}
          >
            {t('addCandidate.actions.cancel')}
          </button>
          <button 
            type="submit" 
            className="submit-button"
            disabled={isSubmitting}
          >
            {isSubmitting ? t('addCandidate.actions.submitting') : t('addCandidate.actions.submit')}
          </button>
        </div>
      </form>

      {showSharedPoolModal && existingCandidate && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>{t('addCandidate.sharedPool.title')}</h3>
            <div className="candidate-details">
              <p>{t('addCandidate.sharedPool.existingEmailMessage')}</p>
              <div className="details-grid">
                <div className="detail-item">
                  <strong>{t('addCandidate.fields.name')}:</strong>
                  <span>{`${existingCandidate.first_name} ${existingCandidate.last_name}`}</span>
                </div>
                <div className="detail-item">
                  <strong>{t('addCandidate.fields.email')}:</strong>
                  <span>{existingCandidate.email}</span>
                </div>
                {existingCandidate.phone && (
                  <div className="detail-item">
                    <strong>{t('addCandidate.fields.phone')}:</strong>
                    <span>{existingCandidate.phone}</span>
                  </div>
                )}
                {existingCandidate.skills && existingCandidate.skills.length > 0 && (
                  <div className="detail-item">
                    <strong>{t('addCandidate.fields.skills')}:</strong>
                    <span>{existingCandidate.skills.join(', ')}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-actions">
              <button 
                onClick={() => {
                  setShowSharedPoolModal(false);
                  setErrorMessage('');
                  setExistingCandidate(null);
                }}
                disabled={isSubmitting}
                className="cancel-button"
              >
                {t('addCandidate.sharedPool.cancel')}
              </button>
              <button 
                onClick={handleAddToMyPool}
                disabled={isSubmitting}
                className="submit-button"
              >
                {isSubmitting 
                  ? t('addCandidate.sharedPool.adding') 
                  : t('addCandidate.sharedPool.addToPool')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddCandidate;


