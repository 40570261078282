import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './SupportTicketList.scss';
import { API_URL } from "../../../shared";

const SupportTicketList = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // Mock data for demonstration
  const mockTickets = [
    {
      id: 'TKT-2024-001',
      title: 'Cannot access assessment results',
      status: 'Open',
      lastUpdate: '2024-03-20T14:30:00',
      priority: 'High'
    },
    {
      id: 'TKT-2024-002',
      title: 'Question about billing cycle',
      status: 'Open',
      lastUpdate: '2024-03-19T09:15:00',
      priority: 'Medium'
    },
    {
      id: 'TKT-2024-003',
      title: 'Feature request: Additional analytics',
      status: 'Open',
      lastUpdate: '2024-03-18T16:45:00',
      priority: 'Low'
    }
  ];

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await fetch(`${API_URL}/support-ticket`, { credentials: "include" });
        if (response.ok) {
          const data = await response.json();          
          setTickets(data);
        } else {
          throw new Error("Failed to fetch hiring requests");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchTickets();
  }, []);

  const handleCreateTicket = () => {
    navigate('/b2b/dashboard/support-tickets/create');
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredTickets = tickets.filter(ticket =>
    ticket.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
    ticket.subject_title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat(i18n.language, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  return (
    <div className="support-ticket-list">
      <div className="header-section">
        <h2>{t('dashboard.supportTickets')}</h2>
        <button 
          className="create-ticket-btn"
          onClick={handleCreateTicket}
        >
          {t('dashboard.createTicket')}
        </button>
      </div>

      <div className="search-bar">
        <input
          type="text"
          placeholder={t('dashboard.searchTickets')}
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

      <table className="tickets-table">
        <thead>
          <tr>
            <th>{t('dashboard.ticketId')}</th>
            <th>{t('dashboard.ticketTitle')}</th>
            <th>{t('dashboard.ticketPriorities')}</th>
            <th>{t('dashboard.lastUpdate')}</th>
            <th>{t('dashboard.ticketStatus')}</th>
            <th>{t('common.actions')}</th>
          </tr>
        </thead>
        <tbody>

          {filteredTickets.length > 0 ? (
            filteredTickets.map(ticket => (
              <tr key={ticket.id}>
                <td>{ticket.ticket_number}</td>
                <td>{ticket.subject_title}</td>
                <td>
                  <span className={`priority-badge ${ticket.priority.toLowerCase()}`}>
                    {t(`dashboard.priorities.${ticket.priority.toLowerCase()}`)}
                  </span>
                </td>
                <td>{formatDate(ticket?.updated_at)}</td>
                <td>
                  <span className={`status-badge ${ticket.status.toLowerCase()}`}>
                    {t(`dashboard.statuses.${ticket.status.toLowerCase()}`)}
                  </span>
                </td>
                <td>
                  <button 
                    className="view-btn"
                    onClick={() => navigate(`/b2b/dashboard/support-tickets/${ticket.id}`)}
                  >
                    {t('dashboard.viewDetails')}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="no-tickets">
                {t('dashboard.noTicketsFound')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SupportTicketList;
