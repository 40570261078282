import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { FaEdit, FaSave, FaUserPlus } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { API_URL } from '../../../../../shared.js';
import './SendAssessmentModal.scss';

const fetchCandidateList = async () => {

  const candidateFromPool = await fetch(`${API_URL}/candidates`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });

  const candidatesResult = await candidateFromPool.json();
  console.log('candidatesResult:', candidatesResult);

  const candidateWithApplication = await fetch(`${API_URL}/candidates-with-application`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  const candidatesWithApplicationResult = await candidateWithApplication.json();
  console.log('candidatesWithApplicationResult:', candidatesWithApplicationResult);

  const candidatesWithApplicationArray = candidatesWithApplicationResult.candidates;

  const uniqueCandidates = [
    ...new Map(
      [...candidatesResult, ...candidatesWithApplicationArray].map(candidate => [candidate.id, candidate])
    ).values()];
  return uniqueCandidates;
}


const SendAssessmentModal = ({ onClose }) => {

  const { t } = useTranslation();
  const { id: assessmentId } = useParams();
  const [candidates, setCandidates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCandidates, setFilteredCandidates] = useState([]);


  const handleOutsideClick = (event) => {
    if (event.target.classList.contains("modal-overlay")) {
      onClose();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const mergedCandidates = await fetchCandidateList();
      setCandidates(mergedCandidates);
      setFilteredCandidates(mergedCandidates);
    }
    fetchData();
  }, []);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = candidates.filter(candidate =>
      `${candidate.first_name} ${candidate.last_name}`.toLowerCase().includes(value)
    );

    setFilteredCandidates(filtered);
  };

  const handleInviteSubmit = async (candidate) => {
    try {
      const response = await fetch(`${API_URL}/assessment-invitation-candidate`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          candidateId: candidate.id,
          email: candidate.email,
          assessmentId: assessmentId,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (result.success) {
        alert(t('assessments.sendModal.invitationSuccess'));
      } else {
        console.error(t('assessments.sendModal.invitationError'), result.message);
      }
    } catch (error) {
      console.error(t('assessments.sendModal.invitationError'), error);
    }
  };

  return (
    <div className="send-assessment-modal">
      <button
        type="button"
        className="modal-close p-3 m-5"
        onClick={onClose}
      >
        ×
      </button>
      <h2 className="send-assessment-modal__title">
        {t('assessments.sendModal.title')}
      </h2>
      <div className="send-assessment-modal__content">
        <div className="send-assessment-modal__content-candidate-list">
          <input
            type="text"
            placeholder={t('assessments.sendModal.searchPlaceholder')}
            value={searchTerm}
            onChange={handleSearch}
            className="send-assessment-modal__content-search-input"
          />

          <div className="send-assessment-modal__content-candidate-container">
            {filteredCandidates.length > 0 ? (
              filteredCandidates.map(candidate => (
                <div
                  key={candidate.id}
                  className="send-assessment-modal__content-candidate-container-item"
                >
                  <p className="send-assessment-modal__content-candidate-container-item-name">
                    {candidate.first_name} {candidate.last_name}
                  </p>
                  <button 
                    onClick={() => handleInviteSubmit(candidate)} 
                    className="send-assessment-modal__content-candidate-container-item-button"
                  >
                    {t('assessments.sendModal.sendButton')}
                  </button>
                </div>
              ))
            ) : (
              <p className="no-results">{t('assessments.sendModal.noCandidates')}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SendAssessmentModal;