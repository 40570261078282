import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const WelcomeMessage = ({ firstName, role }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const goToAdminDashboard = () => {
    console.log("asdfasd");
    
    navigate("/admin/dashboard");
  }

  return (
    <div className="welcome-message">
      {t('dashboard.mainPage.welcome')}, {firstName}!
      { role === "admin" && (
         <button className='switch-button' onClick={goToAdminDashboard}>Switch to Admin Dashboard</button>
      )}
    </div>
  );
};

export default WelcomeMessage;
