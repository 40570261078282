import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./CreateJobOpening.scss";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../shared.js";
import { toast } from "react-toastify";

const CreateJobOpening = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  // Mapping function to convert saved data to form data
  const mapSavedDataToFormData = (savedData) => {
    // Mapping of saved data keys to form data keys
    const keyMapping = {
      jobTitle: 'role',
      companyName: 'company_name',
      companyDescription: 'company_description',
      costCenter: 'cost_center',
      employmentType: 'employment_type',
      contractType: 'contract_type',
      missions: 'mission',
      responsibilities: 'responsibilities',
      requirements: 'requirements',
      minYearsExperience: 'min_year_experience',
      roleLevel: 'role_level',
      skills: 'skills',
      city: 'city',
      startHour: 'start_hour',
      endHour: 'end_hour',
      minSalary: 'min_salary',
      maxSalary: 'max_salary',
      offerContent: 'offer_content',
      perks: 'perks',
      hiringProcess: 'hiring_process',
      tone: 'tone'
    };

    const mappedData = {};
    
    // Map each key, handling special cases
    Object.keys(keyMapping).forEach(savedKey => {
      const formKey = keyMapping[savedKey];
      if (savedData[savedKey] !== undefined) {
        mappedData[formKey] = savedData[savedKey];
      }
    });

    // Additional transformations
    mappedData.is_remote = savedData.workType === 'remote';
    mappedData.is_hybrid = savedData.workType === 'hybrid';
    mappedData.onsite = savedData.workType === 'onsite';

    return mappedData;
  };

  const [formData, setFormData] = useState({
    role: "",
    company_name: "",
    company_description: "",
    cost_center: "",
    contract_type: "",
    employment_type: "",
    mission: "",
    responsibilities: "",
    requirements: "",
    min_year_experience: "",
    role_level: "",
    skills: [],
    start_hour: "",
    end_hour: "",
    city: "",
    country: "",
    is_remote: false,
    is_hybrid: false,
    offer_content: "",
    perks: "",
    min_salary: "",
    max_salary: "",
    hiring_process: "",
    remote: false,
    hybrid: false,
    onsite: false,
    tone: "",
    is_ai_generated: false,
    source: null
  });

  useEffect(() => {
    // Use the same key as in JobOpeningAIGenerator
    const savedData = localStorage.getItem('jobOpeningFormData');
    console.log('Retrieved data from localStorage:', savedData);
    
    if (savedData) {
      try {
        const parsedData = JSON.parse(savedData);
        console.log('Parsed form data:', parsedData);
        
        // Update form data with parsed values
        setFormData(parsedData);
        
        // Clear localStorage after loading
        localStorage.removeItem('jobOpeningFormData');
      } catch (error) {
        console.error('Error parsing form data:', error);
      }
    }
  }, []);

  // Log whenever formData changes
  useEffect(() => {
    console.log('Form data updated:', formData);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSkillsChange = (e) => {
    const skills = e.target.value.split(",").map((skill) => skill.trim());
    setFormData((prevState) => ({ ...prevState, skills }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting form data:', formData);

      const submissionData = {
        ...formData,
        is_public: false,
        is_ai_generated: formData.is_ai_generated,
        source: formData.source
      };

      console.log('Job offer submission data:', submissionData);

      const response = await fetch(`${API_URL}/job-offers`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(submissionData),
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message || "Failed to create job offer")
      }

      const data = await response.json();
      toast.success("Job offer created successfully!")
      navigate("/b2b/dashboard/job-openings");
    } catch (error) {
      console.error("Full error details:", error);
      toast.error("Failed to create job offer: " + error.message);
    }
  };

  return (
    <div className="create-job-opening">
      <div className="create-job-opening-header">
        <h2>{t("createJobOpening.title")}</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="role">{t('createJobOpening.fields.role')}</label>
          <input
            type="text"
            id="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="company_name">{t('createJobOpening.fields.companyName')}</label>
          <input
            type="text"
            id="company_name"
            name="company_name"
            value={formData.company_name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="company_description">
            {t("createJobOpening.fields.companyDescription")}
          </label>
          <textarea
            id="company_description"
            name="company_description"
            value={formData.company_description}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="cost_center">
            {t("createJobOpening.fields.costCenter")}
          </label>
          <input
            type="text"
            id="cost_center"
            name="cost_center"
            value={formData.cost_center}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="contract_type">
            {t("createJobOpening.fields.contractType.label")}
          </label>
          <select
            id="contract_type"
            name="contract_type"
            value={formData.contract_type}
            onChange={handleChange}
            required
          >
            <option value="">
              {t("createJobOpening.fields.contractType.placeholder")}
            </option>
            <option value="full-time">
              {t("createJobOpening.fields.contractType.options.fullTime")}
            </option>
            <option value="part-time">
              {t("createJobOpening.fields.contractType.options.partTime")}
            </option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="employment_type">{t('createJobOpening.fields.employmentType')}</label>
          <select
            id="employment_type"
            name="employment_type"
            value={formData.employment_type}
            onChange={handleChange}
            required
          >
            <option value="">
              {t("createJobOpening.fields.employmentTypePlaceholder")}
            </option>
            <option value="Permanent">
              {t("createJobOpening.fields.employmentTypeOptions.permanent")}
            </option>
            <option value="Fixed-term contract">
              {t("createJobOpening.fields.employmentTypeOptions.fixedTerm")}
            </option>
            <option value="Temporary">
              {t("createJobOpening.fields.employmentTypeOptions.temporary")}
            </option>
            <option value="Internship">
              {t("createJobOpening.fields.employmentTypeOptions.internship")}
            </option>
            <option value="Freelance">
              {t("createJobOpening.fields.employmentTypeOptions.freelance")}
            </option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="mission">{t('createJobOpening.fields.mission')}</label>
          <textarea
            id="mission"
            name="mission"
            value={formData.mission}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="responsibilities">{t('createJobOpening.fields.responsibilities')}</label>
          <textarea
            id="responsibilities"
            name="responsibilities"
            value={formData.responsibilities}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="requirements">{t('createJobOpening.fields.requirements')}</label>
          <textarea
            id="requirements"
            name="requirements"
            value={formData.requirements}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="min_year_experience">{t('createJobOpening.fields.minYearExperience')}</label>
          <input
            type="number"
            id="min_year_experience"
            name="min_year_experience"
            value={formData.min_year_experience}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="role_level">{t('createJobOpening.placeholders.roleLevel')}</label>
          <input
            type="text"
            id="role_level"
            name="role_level"
            value={formData.role_level}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="skills">{t('createJobOpening.fields.skills')}</label>
          <input
            type="text"
            id="skills"
            name="skills"
            value={formData.skills.join(", ")}
            onChange={handleSkillsChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="city">{t("createJobOpening.fields.city")}</label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="country">{t("createJobOpening.fields.country")}</label>
          <input
            type="text"
            id="country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group checkbox-group">
          <label>
            <input
              type="checkbox"
              name="is_remote"
              checked={formData.is_remote}
              onChange={handleChange}
            />
            {t("createJobOpening.fields.workType.remote")}
          </label>
    
          <label>
            <input
              type="checkbox"
              name="is_hybrid"
              checked={formData.is_hybrid}
              onChange={handleChange}
            />
            {t("createJobOpening.fields.workType.hybrid")}
          </label>
        
          <label>
            <input
              type="checkbox"
              name="onsite"
              checked={formData.onsite}
              onChange={handleChange}
            />
            {t("createJobOpening.fields.workType.onsite")}
          </label>
        </div>

        <div className="hour-container">
          <div className="form-group">
            <label htmlFor="start_hour">
              {t("createJobOpening.fields.workHours.start")}
            </label>
            <input
              type="time"
              id="start_hour"
              name="start_hour"
              value={formData.start_hour}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="end_hour">
              {t("createJobOpening.fields.workHours.end")}
            </label>
            <input
              type="time"
              id="end_hour"
              name="end_hour"
              value={formData.end_hour}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="offer_content">
            {t("createJobOpening.fields.offerContent")}
          </label>
          <textarea
            id="offer_content"
            name="offer_content"
            value={formData.offer_content}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="perks">{t("createJobOpening.fields.perks")}</label>
          <textarea
            id="perks"
            name="perks"
            value={formData.perks}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="min_salary">
            {t("createJobOpening.fields.salary.min")}
          </label>
          <input
            type="number"
            id="min_salary"
            name="min_salary"
            value={formData.min_salary}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="max_salary">
            {t("createJobOpening.fields.salary.max")}
          </label>
          <input
            type="number"
            id="max_salary"
            name="max_salary"
            value={formData.max_salary}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="hiring_process">
            {t("createJobOpening.fields.hiringProcess")}
          </label>
          <textarea
            id="hiring_process"
            name="hiring_process"
            value={formData.hiring_process}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tone">{t("createJobOpening.fields.tone")}</label>
          <input
            type="text"
            id="tone"
            name="tone"
            value={formData.tone}
            onChange={handleChange}
          />
        </div>
        <button type="submit">{t("createJobOpening.submit")}</button>
      </form>
    </div>
  );
};

export default CreateJobOpening;
