import React, { useState, useEffect, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { getJobBoard, getSearchJobs } from "../services/jobOfferService";
import { helmet } from "react-helmet-async";
import FooterMenuGeneral from "./FooterMenuGeneral";
import B2BDashboardHeader from "./B2B/B2BDashboard/B2BDashboardHeader";
import B2CDashboardHeader from "./B2C/B2CDashboard/B2CDashboardHeader";
import logo from "../assets/Logo.png";
import { ThemeContext } from "./B2B/B2BDashboard/ThemeContext";
import { FaMapMarkerAlt } from 'react-icons/fa';

import "./JobBoard.scss";

// Import flag images
import ukFlag from "../assets/flags/en-flag.png";
import frFlag from "../assets/flags/fr-flag.png";
import esFlag from "../assets/flags/es-flag.png";
import itFlag from "../assets/flags/it-flag.png";
import ptFlag from "../assets/flags/pt-flag.png";
import deFlag from "../assets/flags/de-flag.png";
import { Helmet } from "react-helmet";

const JobBoard = () => {
  let theme = "default"; // Valeur par défaut
  let customColors = {};
  let updateCustomColors = () => {};

  // Vérifiez si le contexte existe dans l'environnement
  try {
    const themeContext = useContext(ThemeContext);
    if (themeContext) {
      theme = themeContext.theme;
      customColors = themeContext.customColors;
      updateCustomColors = themeContext.updateCustomColors;
    }
  } catch (err) {
    console.warn(
      'ThemeContext is not available. Defaulting to theme "default".'
    );
  }

  const [jobOffers, setJobOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState(null);

  // États pour les filtres de recherche
  const [searchQuery, setSearchQuery] = useState("");
  const [contractType, setContractType] = useState("");
  const [city, setCity] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  useEffect(() => {
    fetchJobOffers();
    checkAuthStatus();
  }, []);

  const checkAuthStatus = () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      setIsAuthenticated(true);
      setUserType(user.role);
    }
  };

  const fetchJobOffers = async () => {
    try {
      setLoading(true);
      const response = await getJobBoard();
      if (Array.isArray(response)) {
        setJobOffers(response);
      } else if (response.message) {
        setJobOffers([]);
        setError(response.message);
      }
    } catch (err) {
      console.error("Error fetching job offers:", err);
      setError(t("errorFetchingJobOffers"));
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent form submission
  };

  const handleSearchQueryChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const newTimeout = setTimeout(() => {
      handleSearch(value, contractType, city);
    }, 300);

    setSearchTimeout(newTimeout);
  };

  const handleContractTypeChange = (e) => {
    const value = e.target.value;
    setContractType(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const newTimeout = setTimeout(() => {
      handleSearch(searchQuery, value, city);
    }, 300);

    setSearchTimeout(newTimeout);
  };

  const handleCityChange = (e) => {
    const value = e.target.value;
    setCity(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const newTimeout = setTimeout(() => {
      handleSearch(searchQuery, contractType, value);
    }, 300);

    setSearchTimeout(newTimeout);
  };

  // Fonction de recherche modifiée
  const handleSearch = useCallback(async (query, contract, location) => {
    try {
      const params = new URLSearchParams();
      if (query) params.append("query", query);
      if (contract) params.append("contractType", contract);
      if (location) params.append("city", location);

      const response = await getSearchJobs(params);
      setJobOffers(response);
    } catch (err) {
      console.error("Search error:", err);
      setError(t("jobBoard.error.search"));
    }
  }, []);

  const toggleLangMenu = () => {
    setIsLangMenuOpen(!isLangMenuOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsLangMenuOpen(false);
  };

  const getCurrentLanguageFlag = () => {
    switch (i18n.language) {
      case "fr":
        return frFlag;
      case "es":
        return esFlag;
      case "it":
        return itFlag;
      case "pt":
        return ptFlag;
      case "de":
        return deFlag;
      default:
        return ukFlag;
    }
  };

  const EmptyState = () => (
    <div className="empty-state">
      <div className="empty-state-content">
        <div className="empty-state-icon">
          <i className="fas fa-briefcase"></i>
        </div>
        <h2>{t("jobBoard.noJobs.title")}</h2>
        <p>{t("jobBoard.noJobs.message")}</p>
        <p className="sub-message">{t("jobBoard.noJobs.checkLater")}</p>
      </div>
    </div>
  );

  const contractTypeMapping = {
    "full-time": "fullTime",
    "part-time": "partTime",
    "contract": "contract",
    "internship": "internship",
    "fulltime": "fullTime",
    "parttime": "partTime",
    "Full Time": "fullTime",
    "Part Time": "partTime",
    "Full-Time": "fullTime",
    "Part-Time": "partTime",
    "Contract": "contract",
    "Internship": "internship",
    "permanent": "permanent",
    "Permanent": "permanent",
    "PERMANENT": "permanent"
  };

  const formatDate = (createdAt, updatedAt) => {
    console.log("Date inputs:", { createdAt, updatedAt });

    try {
      const dateString = (createdAt || updatedAt || "").replace(/"/g, "");
      console.log("Cleaned date string:", dateString);

      if (!dateString) {
        console.log("No date available");
        return t("jobBoard.jobCard.noDate");
      }

      const date = new Date(dateString);
      console.log("Parsed date:", date);

      if (isNaN(date.getTime())) {
        console.log("Invalid date");
        return t("jobBoard.jobCard.noDate");
      }

      const formattedDate = new Intl.DateTimeFormat(i18n.language, {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(date);
      console.log("Formatted date:", formattedDate);
      return formattedDate;
    } catch (error) {
      console.error("Date formatting error:", error);
      return t("jobBoard.jobCard.noDate");
    }
  };

  const handleJobClick = (jobId) => {
    let pathPrefix = "/b2c";
    if (window.location.pathname.includes("/b2b/dashboard")) {
      pathPrefix = "/b2b/dashboard";
    } else if (window.location.pathname.includes("/b2b")) {
      pathPrefix = "/b2b";
    }
    navigate(`${pathPrefix}/job-board/${jobId}`);
  };

  // useEffect(() => {
  //   return () => {
  //     if (searchTimeout) {
  //       clearTimeout(searchTimeout);
  //     }
  //   };
  // }, [searchTimeout]);

  if (loading) return <div className="loading">{t("jobBoard.loading")}</div>;
  if (error)
    return (
      <div className="error">{t("jobBoard.error", { message: error })}</div>
    );

  return (
    <div className={`job-board-page ${theme}`}>

      <Helmet>

        {/* Balises générales */}
        <title>{t("meta.jobBoardPage.title")}</title>
        <meta name="description" content={t("meta.jobBoardPage.description")} />
        <meta name="keywords" content={t("meta.jobBoardPage.keywords")} />
        <meta name='author' content={t("meta.jobBoardPage.author")} />

        {/* Balises Open Graph pour les partages sur les réseaux Facebook & Linkedin */}
        <meta property="og:title" content={t("meta.jobBoardPage.title")} />
        <meta property="og:description" content={t("meta.jobBoardPage.description")} />
        <meta property="og:image" content={logo} />
        <meta property="og:url" content="https://hrnov.ai/job-board" />
        <meta property="og:type" content="website" />

        {/* Balises pour le partage sur twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t("meta.jobBoardPage.title")} />
        <meta name="twitter:description" content={t("meta.jobBoardPage.description")} />
        <meta name="twitter:image" content={logo} />
        <meta name="twitter:creator" content="@hrnovai" />
        <meta name='twitter:url' content="https://hrnov.ai/job-board" />
        <meta name="twitter:site" content="@hrnovai" />

        {/* Balise pour le référencement naturel */}
        {/* permet de définir pour les robots des moteurs de recherche, quelle est  */}
        {/* la page de référence pour éviter la dupplication de contenu (multilangue) qui est sanctionné par google */}
        <link rel="canonical" href="https://hrnov.ai/job-board" />

      </Helmet>

      {/* {isAuthenticated ? (
        userType === "company" ? (
          <B2BDashboardHeader userAddOns={{}} />
        ) : userType === "admin" ? (
          <B2BDashboardHeader userAddOns={{}} />
        ) : userType === "reseller" ? (
          <B2BDashboardHeader userAddOns={{}} />
        ) : userType === "candidate" ? (
          <B2CDashboardHeader userAddOns={{}} />
        ) : null
      ) 
      : null}  */}
      {/* (
        <header className="header-nav">
          <div className="logo-container">
            <Link to="/" className="logo-link">
              <img src={logo} alt="Logo" className="logo" />
            </Link>
          </div>
          <ul className="nav-links">
            <li className="language-selector">
              <button onClick={toggleLangMenu} className="lang-toggle">
                <img
                  src={getCurrentLanguageFlag()}
                  alt={i18n.language}
                  className="flag-icon"
                />
                <span className="current-lang">
                  {i18n.language.toUpperCase()}
                </span>
              </button>
              <ul className={`lang-dropdown ${isLangMenuOpen ? "open" : ""}`}>
                <li>
                  <button onClick={() => changeLanguage("en")}>
                    <img src={ukFlag} alt="EN" />
                    EN
                  </button>
                </li>
                <li>
                  <button onClick={() => changeLanguage("fr")}>
                    <img src={frFlag} alt="FR" />
                    FR
                  </button>
                </li>
                <li>
                  <button onClick={() => changeLanguage("es")}>
                    <img src={esFlag} alt="ES" />
                    ES
                  </button>
                </li>
                <li>
                  <button onClick={() => changeLanguage("it")}>
                    <img src={itFlag} alt="IT" />
                    IT
                  </button>
                </li>
                <li>
                  <button onClick={() => changeLanguage("pt")}>
                    <img src={ptFlag} alt="PT" />
                    PT
                  </button>
                </li>
                <li>
                  <button onClick={() => changeLanguage("de")}>
                    <img src={deFlag} alt="DE" />
                    DE
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </header>
      )} */
      }

      <main className="job-board">
        <h1>{t("jobBoard.title")}</h1>

        <div className="search-filters">
          <input
            type="text"
            placeholder={t("jobBoard.search.keywords")}
            value={searchQuery}
            onChange={handleSearchQueryChange}
            autoComplete="off"
            className="search-input"
            onFocus={(e) => e.target.select()}
          />
          <select
            value={contractType}
            onChange={handleContractTypeChange}
            className="contract-select"
          >
            <option value="">
              {t("jobBoard.filters.contractTypes.chooseContractType")}
            </option>
            <option value="full-time">
              {t("jobBoard.filters.contractTypes.fullTime")}
            </option>
            <option value="part-time">
              {t("jobBoard.filters.contractTypes.partTime")}
            </option>
            <option value="contract">
              {t("jobBoard.filters.contractTypes.contract")}
            </option>
            <option value="internship">
              {t("jobBoard.filters.contractTypes.internship")}
            </option>
          </select>
          <input
            type="text"
            placeholder={t("jobBoard.search.city")}
            value={city}
            onChange={handleCityChange}
            autoComplete="off"
            className="location-input"
            onFocus={(e) => e.target.select()}
          />
        </div>

        <div className="job-list">
          {jobOffers.length === 0 ? (
            <div className="empty-state">
              <div className="empty-state-content">
                <div className="empty-state-icon">
                  <i className="fas fa-briefcase"></i>
                </div>
                <h2>{t("jobBoard.noJobs.title")}</h2>
                <p>{t("jobBoard.noJobs.message")}</p>
                <p className="sub-message">{t("jobBoard.noJobs.checkLater")}</p>
              </div>
            </div>
          ) : (
            jobOffers.map((job) => (
              <div
                key={job.id}
                className="job-card"
                onClick={() => handleJobClick(job.id)}
              >
                <h2>{job.role}</h2>
                <p className="company">{job.company_name}</p>
                <p className="contract-type">
                  {t(`jobBoard.filters.contractTypes.${
                    contractTypeMapping[job.contract_type?.toLowerCase()] || 'fullTime'
                  }`)}
                </p>
                <p className="location">
                  <FaMapMarkerAlt />{" "}
                  {job.city && job.country
                    ? `${job.city}, ${job.country}`
                    : job.city
                    ? `${job.city}, ${t("jobBoard.jobCard.location.countryNotSpecified")}`
                    : job.country
                    ? `${t("jobBoard.jobCard.location.cityNotSpecified")}, ${job.country}`
                    : t("jobBoard.jobCard.location.notSpecified")}
                </p>
                <p className="created-at">
                  {t("jobBoard.jobCard.createdAt")}:{" "}
                  {formatDate(job.created_at, job.updated_at)}
                </p>
              </div>
            ))
          )}
        </div>
        <Outlet />
      </main>
    </div>
  );
};

export default JobBoard;