import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './locales/en/translation.json';
import frTranslations from './locales/fr/translation.json';
import esTranslations from './locales/es/translation.json';
import itTranslations from './locales/it/translation.json';
import deTranslations from './locales/de/translation.json';
import ptTranslations from './locales/pt/translation.json';

//console.log('i18n initialization starting...');

// Détection immédiate de la langue du navigateur
const browserLang = navigator.language.split('-')[0];
const supportedLangs = ['en', 'fr', 'es', 'it', 'de', 'pt'];
const defaultLang = supportedLangs.includes(browserLang) ? browserLang : 'en';

// console.log('Browser detected language:', browserLang);
// console.log('Using language:', defaultLang);

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      fr: { translation: frTranslations },
      es: { translation: esTranslations },
      it: { translation: itTranslations },
      de: { translation: deTranslations },
      pt: { translation: ptTranslations },
    },
    lng: defaultLang, // Forcer la langue détectée
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr', 'es', 'it', 'de', 'pt'],
    interpolation: {
      escapeValue: false,
    },
    debug: false
  });

i18n.on('languageChanged', (lng) => {
  //console.log('Language changed to:', lng);
  document.documentElement.lang = lng;
});

export default i18n;
