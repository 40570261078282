import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import { FaUserPlus } from "react-icons/fa";
import "./AssesmentDetailPageSoftSkills.scss";
import { API_URL } from "../../../../shared.js";
import SendAssessmentModal from "./composants/SendAssessmentModal.jsx";

const AssesmentDetailPageSoftSkills = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [assessment, setAssessment] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isInvitation, setIsInvitation] = useState(false);

  useEffect(() => {
    const fetchAssessment = async () => {
      try {
        const response = await fetch(`${API_URL}/assessment/${id}/soft-skills-analysis`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("Fetched assessment:", result);
        
        if (result.success && result.data) {
          setAssessment(result.data);
        } else {
          throw new Error("Invalid assessment data structure");
        }
      } catch (err) {
        console.error("Error fetching assessment:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAssessment();
  }, [id]);

  const handleInviteModalOpen = () => {
    setIsInvitation(true);
  };

  const handleInviteModalClose = () => {
    setIsInvitation(false);
  };

  return (
    <div className="assessment-page">
      <div className="assessment-header">
        <div className="header-top">
          <h1>{assessment?.title || t('assessment.softSkills')}</h1>
          <button 
            className="invite-btn"
            onClick={handleInviteModalOpen}
          >
            <FaUserPlus className="icon" />
            {t('assessment.inviteCandidate')}
          </button>
        </div>
      </div>

      {isInvitation && (
        <div className="modal-overlay" onClick={handleInviteModalClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <SendAssessmentModal onClose={handleInviteModalClose} />
          </div>
        </div>
      )}

      <div className="content-section">
        {loading ? (
          <div className="loading">
            <ClipLoader color="#123abc" loading={loading} size={50} />
          </div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : assessment ? (
          <div className="assessment-content">
            {/* Introduction Section */}
            <section className="section">
              <h2>{t('assessment.introduction')}</h2>
              <div className="assessment-details">
                <p className="purpose">{assessment.purpose}</p>
                {assessment.time && (
                  <div className="meta-info">
                    <span>{t('assessment.time')}: {assessment.time}</span>
                  </div>
                )}
              </div>
            </section>

            {/* Sections */}
            {assessment.sections?.map((section, sectionIndex) => (
              <section key={sectionIndex} className="section">
                <h2>{section.title}</h2>
                {section.description && (
                  <p className="section-description">{section.description}</p>
                )}
                
                <div className="questions-container">
                  {section.questions?.map((question, qIndex) => (
                    <div key={qIndex} className="question-block">
                      <div className="question-header">
                        <h3>{t('assessment.question')} {qIndex + 1}</h3>
                        {question.skill && (
                          <span className="skill-tag">{question.skill}</span>
                        )}
                      </div>
                      <p className="question-text">{question.question}</p>
                      {question.options && (
                        <div className="options">
                          {question.options.map((option, oIndex) => (
                            <div key={oIndex} className="option">
                              <span className="option-letter">{option.id}.</span>
                              <span className="option-text">{option.text}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </section>
            ))}

            {/* Closing Message */}
            {assessment.closingMessage && (
              <div className="closing-message">
                <p>{assessment.closingMessage}</p>
              </div>
            )}
          </div>
        ) : (
          <div className="no-data">
            {t('assessment.noData')}
          </div>
        )}
      </div>
    </div>
  );
};

export default AssesmentDetailPageSoftSkills;