import React, { useEffect, useState } from "react";
import { MdAddCircle, MdCancel, MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import "../admin.scss";
import { API_URL } from "../../../shared";



const AdminDashboardSubscriptionListing = () => {
   const { t } = useTranslation();
    const [subscriptions, setSubscriptions] = useState([]);
    
     const fetchSubscription = async () => {
        try {
          const response = await fetch(`${API_URL}/admin/subscription`, {
            credentials: "include",
          });
          if (response.ok) {
            const data = await response.json();
            setSubscriptions(data);
          } else {
            throw new Error("Failed to fetch Subscription");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      };
    
      useEffect(() => {
        fetchSubscription();
      }, []);
  return (
    <div className="list">
           <div className="top-header">
             <h2>Subscriptions</h2>
           </div>
           <table style={{ marginTop: "10px" }}>
             <thead>
               <tr>
                 <th>S.No</th>
                 <th>User Name</th>
                 <th>Subscription Type</th>
                 <th>Quantity</th>
                 <th>Price</th>
                 <th>Payment Type</th>
                 <th>Payment Term</th>
                 <th>Start Date</th>
                 <th>End Date</th>
               </tr>
             </thead>
             <tbody>
               {subscriptions.map((subscription, index) => (
                 <tr key={subscription.type}>
                   <td>{index + 1}</td>
                   <td>{subscription?.User?.first_name} {subscription?.User?.last_name}</td>
                   <td>{subscription.type}</td>
                   <td>{subscription.quantity}</td>
                   <td>{subscription.price}</td>
                   <td>{subscription.payment_type}</td>
                   <td>{subscription.payment_term}</td>
                   <td>{new Date(subscription.start_date).toLocaleDateString()}</td>
                   <td>{new Date(subscription.end_date).toLocaleDateString()}</td>
                 </tr>
               ))}
             </tbody>
           </table>
         </div>
  );
};

export default AdminDashboardSubscriptionListing;
