import i18next from 'i18next';

const getCountries = () => [
  { value: 'AF', label: 'Afghanistan', translationKey: 'Afghanistan' },
  { value: 'AL', label: 'Albania', translationKey: 'Albania' },
  { value: 'DZ', label: 'Algeria', translationKey: 'Algeria' },
  { value: 'AD', label: 'Andorra', translationKey: 'Andorra' },
  { value: 'AO', label: 'Angola', translationKey: 'Angola' },
  { value: 'AG', label: 'Antigua and Barbuda', translationKey: 'AntiguaAndBarbuda' },
  { value: 'AR', label: 'Argentina', translationKey: 'Argentina' },
  { value: 'AM', label: 'Armenia', translationKey: 'Armenia' },
  { value: 'AU', label: 'Australia', translationKey: 'Australia' },
  { value: 'AT', label: 'Austria', translationKey: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan', translationKey: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas', translationKey: 'Bahamas' },
  { value: 'BH', label: 'Bahrain', translationKey: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh', translationKey: 'Bangladesh' },
  { value: 'BB', label: 'Barbados', translationKey: 'Barbados' },
  { value: 'BY', label: 'Belarus', translationKey: 'Belarus' },
  { value: 'BE', label: 'Belgium', translationKey: 'Belgium' },
  { value: 'BZ', label: 'Belize', translationKey: 'Belize' },
  { value: 'BJ', label: 'Benin', translationKey: 'Benin' },
  { value: 'BT', label: 'Bhutan', translationKey: 'Bhutan' },
  { value: 'BO', label: 'Bolivia', translationKey: 'Bolivia' },
  { value: 'BA', label: 'Bosnia and Herzegovina', translationKey: 'BosniaAndHerzegovina' },
  { value: 'BW', label: 'Botswana', translationKey: 'Botswana' },
  { value: 'BR', label: 'Brazil', translationKey: 'Brazil' },
  { value: 'BN', label: 'Brunei', translationKey: 'Brunei' },
  { value: 'BG', label: 'Bulgaria', translationKey: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso', translationKey: 'BurkinaFaso' },
  { value: 'BI', label: 'Burundi', translationKey: 'Burundi' },
  { value: 'KH', label: 'Cambodia', translationKey: 'Cambodia' },
  { value: 'CM', label: 'Cameroon', translationKey: 'Cameroon' },
  { value: 'CA', label: 'Canada', translationKey: 'Canada' },
  { value: 'CV', label: 'Cape Verde', translationKey: 'CapeVerde' },
  { value: 'CF', label: 'Central African Republic', translationKey: 'CentralAfricanRepublic' },
  { value: 'TD', label: 'Chad', translationKey: 'Chad' },
  { value: 'CL', label: 'Chile', translationKey: 'Chile' },
  { value: 'CN', label: 'China', translationKey: 'China' },
  { value: 'CO', label: 'Colombia', translationKey: 'Colombia' },
  { value: 'KM', label: 'Comoros', translationKey: 'Comoros' },
  { value: 'CG', label: 'Congo', translationKey: 'Congo' },
  { value: 'CD', label: 'Congo, Democratic Republic of the', translationKey: 'CongoDemocraticRepublicofthe' },
  { value: 'CR', label: 'Costa Rica', translationKey: 'CostaRica' },
  { value: 'CI', label: 'Côte d\'Ivoire', translationKey: 'CotedIvoire' },
  { value: 'HR', label: 'Croatia', translationKey: 'Croatia' },
  { value: 'CU', label: 'Cuba', translationKey: 'Cuba' },
  { value: 'CY', label: 'Cyprus', translationKey: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic', translationKey: 'CzechRepublic' },
  { value: 'DK', label: 'Denmark', translationKey: 'Denmark' },
  { value: 'DJ', label: 'Djibouti', translationKey: 'Djibouti' },
  { value: 'DM', label: 'Dominica', translationKey: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic', translationKey: 'DominicanRepublic' },
  { value: 'EC', label: 'Ecuador', translationKey: 'Ecuador' },
  { value: 'EG', label: 'Egypt', translationKey: 'Egypt' },
  { value: 'SV', label: 'El Salvador', translationKey: 'ElSalvador' },
  { value: 'GQ', label: 'Equatorial Guinea', translationKey: 'EquatorialGuinea' },
  { value: 'ER', label: 'Eritrea', translationKey: 'Eritrea' },
  { value: 'EE', label: 'Estonia', translationKey: 'Estonia' },
  { value: 'SZ', label: 'Eswatini', translationKey: 'Eswatini' },
  { value: 'ET', label: 'Ethiopia', translationKey: 'Ethiopia' },
  { value: 'FJ', label: 'Fiji', translationKey: 'Fiji' },
  { value: 'FI', label: 'Finland', translationKey: 'Finland' },
  { value: 'FR', label: 'France', translationKey: 'France' },
  { value: 'GA', label: 'Gabon', translationKey: 'Gabon' },
  { value: 'GM', label: 'Gambia', translationKey: 'Gambia' },
  { value: 'GE', label: 'Georgia', translationKey: 'Georgia' },
  { value: 'DE', label: 'Germany', translationKey: 'Germany' },
  { value: 'GH', label: 'Ghana', translationKey: 'Ghana' },
  { value: 'GR', label: 'Greece', translationKey: 'Greece' },
  { value: 'GD', label: 'Grenada', translationKey: 'Grenada' },
  { value: 'GT', label: 'Guatemala', translationKey: 'Guatemala' },
  { value: 'GN', label: 'Guinea', translationKey: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau', translationKey: 'GuineaBissau' },
  { value: 'GY', label: 'Guyana', translationKey: 'Guyana' },
  { value: 'HT', label: 'Haiti', translationKey: 'Haiti' },
  { value: 'HN', label: 'Honduras', translationKey: 'Honduras' },
  { value: 'HU', label: 'Hungary', translationKey: 'Hungary' },
  { value: 'IS', label: 'Iceland', translationKey: 'Iceland' },
  { value: 'IN', label: 'India', translationKey: 'India' },
  { value: 'ID', label: 'Indonesia', translationKey: 'Indonesia' },
  { value: 'IR', label: 'Iran', translationKey: 'Iran' },
  { value: 'IQ', label: 'Iraq', translationKey: 'Iraq' },
  { value: 'IE', label: 'Ireland', translationKey: 'Ireland' },
  { value: 'IL', label: 'Israel', translationKey: 'Israel' },
  { value: 'IT', label: 'Italy', translationKey: 'Italy' },
  { value: 'JM', label: 'Jamaica', translationKey: 'Jamaica' },
  { value: 'JP', label: 'Japan', translationKey: 'Japan' },
  { value: 'JO', label: 'Jordan', translationKey: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan', translationKey: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya', translationKey: 'Kenya' },
  { value: 'KI', label: 'Kiribati', translationKey: 'Kiribati' },
  { value: 'KP', label: 'Korea, North', translationKey: 'KoreaNorth' },
  { value: 'KR', label: 'Korea, South', translationKey: 'KoreaSouth' },
  { value: 'KW', label: 'Kuwait', translationKey: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan', translationKey: 'Kyrgyzstan' },
  { value: 'LA', label: 'Laos', translationKey: 'Laos' },
  { value: 'LV', label: 'Latvia', translationKey: 'Latvia' },
  { value: 'LB', label: 'Lebanon', translationKey: 'Lebanon' },
  { value: 'LS', label: 'Lesotho', translationKey: 'Lesotho' },
  { value: 'LR', label: 'Liberia', translationKey: 'Liberia' },
  { value: 'LY', label: 'Libya', translationKey: 'Libya' },
  { value: 'LI', label: 'Liechtenstein', translationKey: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania', translationKey: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg', translationKey: 'Luxembourg' },
  { value: 'MG', label: 'Madagascar', translationKey: 'Madagascar' },
  { value: 'MW', label: 'Malawi', translationKey: 'Malawi' },
  { value: 'MY', label: 'Malaysia', translationKey: 'Malaysia' },
  { value: 'MV', label: 'Maldives', translationKey: 'Maldives' },
  { value: 'ML', label: 'Mali', translationKey: 'Mali' },
  { value: 'MT', label: 'Malta', translationKey: 'Malta' },
  { value: 'MH', label: 'Marshall Islands', translationKey: 'MarshallIslands' },
  { value: 'MR', label: 'Mauritania', translationKey: 'Mauritania' },
  { value: 'MU', label: 'Mauritius', translationKey: 'Mauritius' },
  { value: 'MX', label: 'Mexico', translationKey: 'Mexico' },
  { value: 'FM', label: 'Micronesia', translationKey: 'Micronesia' },
  { value: 'MD', label: 'Moldova', translationKey: 'Moldova' },
  { value: 'MC', label: 'Monaco', translationKey: 'Monaco' },
  { value: 'MN', label: 'Mongolia', translationKey: 'Mongolia' },
  { value: 'ME', label: 'Montenegro', translationKey: 'Montenegro' },
  { value: 'MA', label: 'Morocco', translationKey: 'Morocco' },
  { value: 'MZ', label: 'Mozambique', translationKey: 'Mozambique' },
  { value: 'MM', label: 'Myanmar', translationKey: 'Myanmar' },
  { value: 'NA', label: 'Namibia', translationKey: 'Namibia' },
  { value: 'NR', label: 'Nauru', translationKey: 'Nauru' },
  { value: 'NP', label: 'Nepal', translationKey: 'Nepal' },
  { value: 'NL', label: 'Netherlands', translationKey: 'Netherlands' },
  { value: 'NZ', label: 'New Zealand', translationKey: 'NewZealand' },
  { value: 'NI', label: 'Nicaragua', translationKey: 'Nicaragua' },
  { value: 'NE', label: 'Niger', translationKey: 'Niger' },
  { value: 'NG', label: 'Nigeria', translationKey: 'Nigeria' },
  { value: 'MK', label: 'North Macedonia', translationKey: 'NorthMacedonia' },
  { value: 'NO', label: 'Norway', translationKey: 'Norway' },
  { value: 'OM', label: 'Oman', translationKey: 'Oman' },
  { value: 'PK', label: 'Pakistan', translationKey: 'Pakistan' },
  { value: 'PW', label: 'Palau', translationKey: 'Palau' },
  { value: 'PS', label: 'Palestine', translationKey: 'Palestine' },
  { value: 'PA', label: 'Panama', translationKey: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea', translationKey: 'PapuaNewGuinea' },
  { value: 'PY', label: 'Paraguay', translationKey: 'Paraguay' },
  { value: 'PE', label: 'Peru', translationKey: 'Peru' },
  { value: 'PH', label: 'Philippines', translationKey: 'Philippines' },
  { value: 'PL', label: 'Poland', translationKey: 'Poland' },
  { value: 'PT', label: 'Portugal', translationKey: 'Portugal' },
  { value: 'QA', label: 'Qatar', translationKey: 'Qatar' },
  { value: 'RO', label: 'Romania', translationKey: 'Romania' },
  { value: 'RU', label: 'Russia', translationKey: 'Russia' },
  { value: 'RW', label: 'Rwanda', translationKey: 'Rwanda' },
  { value: 'KN', label: 'Saint Kitts and Nevis', translationKey: 'SaintKittsAndNevis' },
  { value: 'LC', label: 'Saint Lucia', translationKey: 'SaintLucia' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines', translationKey: 'SaintVincentAndTheGrenadines' },
  { value: 'WS', label: 'Samoa', translationKey: 'Samoa' },
  { value: 'SM', label: 'San Marino', translationKey: 'SanMarino' },
  { value: 'ST', label: 'São Tomé and Príncipe', translationKey: 'SãoToméAndPríncipe' },
  { value: 'SA', label: 'Saudi Arabia', translationKey: 'SaudiArabia' },
  { value: 'SN', label: 'Senegal', translationKey: 'Senegal' },
  { value: 'RS', label: 'Serbia', translationKey: 'Serbia' },
  { value: 'SC', label: 'Seychelles', translationKey: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone', translationKey: 'SierraLeone' },
  { value: 'SG', label: 'Singapore', translationKey: 'Singapore' },
  { value: 'SK', label: 'Slovakia', translationKey: 'Slovakia' },
  { value: 'SI', label: 'Slovenia', translationKey: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands', translationKey: 'SolomonIslands' },
  { value: 'SO', label: 'Somalia', translationKey: 'Somalia' },
  { value: 'ZA', label: 'South Africa', translationKey: 'SouthAfrica' },
  { value: 'SS', label: 'South Sudan', translationKey: 'SouthSudan' },
  { value: 'ES', label: 'Spain', translationKey: 'Spain' },
  { value: 'LK', label: 'Sri Lanka', translationKey: 'SriLanka' },
  { value: 'SD', label: 'Sudan', translationKey: 'Sudan' },
  { value: 'SR', label: 'Suriname', translationKey: 'Suriname' },
  { value: 'SE', label: 'Sweden', translationKey: 'Sweden' },
  { value: 'CH', label: 'Switzerland', translationKey: 'Switzerland' },
  { value: 'SY', label: 'Syria', translationKey: 'Syria' },
  { value: 'TW', label: 'Taiwan', translationKey: 'Taiwan' },
  { value: 'TJ', label: 'Tajikistan', translationKey: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania', translationKey: 'Tanzania' },
  { value: 'TH', label: 'Thailand', translationKey: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste', translationKey: 'TimorLeste' },
  { value: 'TG', label: 'Togo', translationKey: 'Togo' },
  { value: 'TO', label: 'Tonga', translationKey: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago', translationKey: 'TrinidadAndTobago' },
  { value: 'TN', label: 'Tunisia', translationKey: 'Tunisia' },
  { value: 'TR', label: 'Turkey', translationKey: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan', translationKey: 'Turkmenistan' },
  { value: 'TV', label: 'Tuvalu', translationKey: 'Tuvalu' },
  { value: 'UG', label: 'Uganda', translationKey: 'Uganda' },
  { value: 'UA', label: 'Ukraine', translationKey: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates', translationKey: 'UnitedArabEmirates' },
  { value: 'GB', label: 'United Kingdom', translationKey: 'UnitedKingdom' },
  { value: 'US', label: 'United States', translationKey: 'UnitedStates' },
  { value: 'UY', label: 'Uruguay', translationKey: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan', translationKey: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu', translationKey: 'Vanuatu' },
  { value: 'VA', label: 'Vatican City', translationKey: 'VaticanCity' },
  { value: 'VE', label: 'Venezuela', translationKey: 'Venezuela' },
  { value: 'VN', label: 'Vietnam', translationKey: 'Vietnam' },
  { value: 'YE', label: 'Yemen', translationKey: 'Yemen' },
  { value: 'ZM', label: 'Zambia', translationKey: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe', translationKey: 'Zimbabwe' }
].map(country => ({
  ...country,
  label: i18next.t(`hiringMarket.countries.${country.translationKey}`)
})).sort((a, b) => a.label.localeCompare(b.label));

export default getCountries;