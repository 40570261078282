import React, { useEffect, useRef, useState } from "react";
import "../admin.scss";
import { MdAddCircle, MdCancel, MdDelete } from "react-icons/md";
import { API_URL } from "../../../shared";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";

const AdminDashboardBlogs = () => {
  const fileInputRef = useRef(null);
  const [posts, setPosts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [id, setId] = useState(null);
  const [newPost, setNewPost] = useState({
    title: "",
    subject: "",
    authorName: "",
    category: "",
    content: ""
  });

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    setNewPost({
      title: "",
      subject: "",
      authorName: "",
      category: "",
      content: ""
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPost({ ...newPost, [name]: value });
  };

  const handleDelete = async (id) => {
     try {
          const response = await fetch(`${API_URL}/blog-post/${id}`, {
            method: "DELETE",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            }
          });
    
          if (!response.ok) {
            toast.error("Failed to delete blog post.")
            throw new Error(`Failed to delete blog post: ${response.status}`);
          }
    
          const data = await response.json();
          if (data.success) {
            toast.success("Blog post deleted successfully.")
            fetchPosts();
          }
    
        } catch (error) {
          console.error("Delete error:", error);
        }
  }

  const handleEdit = (post) => {
    setIsEditing(true);
    setId(post.id);
    setNewPost({
      title: post.title,
      subject: post.subject,
      authorName: post.author_name,
      category: post.category,
      content: post.content,
      imageUrl: post.image_url || '',
    });
    handleModalOpen();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if a file was selected
    const file = fileInputRef.current.files[0];
    if (!file && !newPost.imageUrl) {
      console.log("No file selected");
      return;
    }

    // Prepare form data
    const formData = new FormData();
    formData.append("image", file);
    formData.append("title", newPost.title);
    formData.append("subject", newPost.subject);
    formData.append("authorName", newPost.authorName);
    formData.append("category", newPost.category);
    formData.append("content", newPost.content);

    let path = isEditing ? `${API_URL}/blog-post/${id}` : `${API_URL}/blog-post`;
    try {
      const response = await fetch(path, {
        method: isEditing ? "PUT" : "POST",
        body: file ? formData : JSON.stringify(newPost),
        headers: file ? {} : {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message || "Error Registering blog post")
        return;
      }

      const data = await response.json();

      if (data.success) {
        const message = isEditing ? "Blog post updated successfully." : "Blog post created successfully.";
        toast.success(message)
        fetchPosts();
        handleModalClose();
      } else {
        toast.error(data.message)
      }
    } catch (error) {
      console.log("Error creating blog post:", error);
      console.log(error.message);
      toast.error("Error creating blog post")
    }
  };

  const fetchPosts = async () => {
    try {
      const response = await fetch(`${API_URL}/blog-post`);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setPosts(data);
    } catch (error) {
      console.error("Error fetching blog posts:", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="modal-close p-3 m-5" onClick={handleModalClose}>
              <MdCancel />
            </button>
            <div className="modal-form">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="code">Title</label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    value={newPost.title}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="discountPercentage">
                    Subject
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={newPost.subject}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="validTo">Author Name</label>
                  <input
                    type="text"
                    id="authorName"
                    name="authorName"
                    value={newPost.authorName}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="category">Category</label>
                  <select
                    id="category"
                    name="category"
                    value={newPost.category}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select a category</option>
                    <option value="Technology">Technology</option>
                    <option value="Health">Health</option>
                    <option value="Lifestyle">Lifestyle</option>
                    <option value="Education">Education</option>
                    <option value="Business">Business</option>
                    <option value="Entertainment">Entertainment</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="content">Image</label>
                  {newPost.imageUrl && (
                    <div>
                      <img
                        src={newPost.imageUrl}
                        alt="Current blog post"
                        style={{ width: '100px', height: 'auto' }}
                      />
                      <p>Current Image</p>
                    </div>
                  )}
                    <input
                    type="file"
                    id="image"
                    name="image"
                    ref={fileInputRef}
                    required={!newPost.imageUrl} 
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="content">Content</label>
                  <textarea
                    id="content"
                    name="content"
                    value={newPost.content}
                    onChange={handleInputChange}
                    required
                    rows="5"
                    placeholder="Enter the content here"
                  />
                </div>

                <button type="submit">
                  {isEditing ? "Update Blog Post" : "Add Blog Post"}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
      {/* Blogs */}
      <div className="list">
        <div className="top-header">
          <h2>Blogs</h2>
          <button onClick={handleModalOpen} style={{ marginLeft: "5px" }}>
            Add Blog <MdAddCircle className="add-icon-padding" />
          </button>
        </div>
        <table style={{ marginTop: "10px" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Title</th>
              <th>Subject</th>
              <th>Content</th>
              <th>Author Name</th>
              <th>Category</th>
              <th>Image Url</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {posts.map((blog, index) => (
              <tr key={blog.id}>
                <td>{index + 1}</td>
                <td>{blog.title}</td>
                <td>{blog.subject}</td>
                <td>{blog.content}</td>
                <td>{blog.author_name}</td>
                <td>{blog.category}</td>
                <td>{blog.image_url}</td>
                <td>
                  <div>
                    <button
                      className="trash-icon"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this blog?"
                          )
                        ) {
                          handleDelete(blog.id);
                        }
                      }}
                    >
                      <MdDelete className="trash-icon" />
                    </button>

                    <button
                      onClick={() => handleEdit(blog)}
                      className="edit-icon"
                    >
                      <FaEdit className="edit-icon" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AdminDashboardBlogs;
