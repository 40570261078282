import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import "./AnalysisLanguage.scss";
import { API_URL } from "../../../../../shared.js";

// Define languages object outside the component
const languages = {
  en: "English",
  fr: "Français",
  es: "Español",
  it: "Italiano",
  de: "Deutsch",
  pt: "Português",
};

const AnalysisLanguage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // Set English as default language
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);

  useEffect(() => {
    if (id) {
      fetchAnalysis();
    } else {
      setError("No assessment ID provided");
      setLoading(false);
    }
  }, [id]);

  const fetchAnalysis = async () => {
    try {
      const response = await fetch(`${API_URL}/assessment/${id}/analysis`, {
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch analysis");
      }

      const result = await response.json();
      console.log("Raw API Response:", result);

      if (!result.success || !result.data) {
        throw new Error(result.message || "No analysis data available");
      }

      setAnalysis(transformAnalysisData(result.data));
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const transformAnalysisData = (data) => ({
    candidateName: `${data.candidate.firstName} ${data.candidate.lastName}`,
    candidateEmail: data.candidate.email,
    score: data.score,
    analysis: {
      strengths: data.analysis.globalAnalysis.strengths,
      weaknesses: data.analysis.globalAnalysis.weaknesses,
      recommendations: data.analysis.globalAnalysis.recommendations,
      detailedAnalysis: data.analysis.results.map(result => ({
        question: result.question,
        candidateAnswer: result.candidateAnswer,
        correctAnswer: result.correctAnswer,
        score: result.score,
        section: result.section
      }))
    }
  });

  const handleLanguageChange = async (lang) => {
    try {
      setIsTranslating(true);
      setShowLanguageMenu(false);

      const response = await fetch(
        `${API_URL}/assessment/${id}/analysis/translate?targetLanguage=${lang}`,
        {
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Translation failed");
      }

      const result = await response.json();
      console.log("Translation response:", result);

      if (result.success && result.data.analysis) {
        // Keep original candidate data and update only translated content
        setAnalysis(prevAnalysis => ({
          ...prevAnalysis,
          analysis: {
            strengths: result.data.analysis.globalAnalysis.strengths,
            weaknesses: result.data.analysis.globalAnalysis.weaknesses,
            recommendations: result.data.analysis.globalAnalysis.recommendations,
            detailedAnalysis: result.data.analysis.results.map(item => ({
              question: item.question,
              candidateAnswer: item.candidateAnswer,
              correctAnswer: item.correctAnswer,
              score: item.score,
              section: item.section
            }))
          }
        }));
        setSelectedLanguage(lang);
      } else {
        throw new Error("Invalid translation data received");
      }
    } catch (error) {
      console.error("Translation error:", error);
      setError(error.message);
    } finally {
      setIsTranslating(false);
    }
  };

  const handleBackToList = () => navigate("/b2b/dashboard/assessments/results");

  if (loading) {
    return (
      <div className="analysis-modal">
        <div className="analysis-content loading">
          <ClipLoader color="#6920b0" size={50} />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="analysis-modal">
        <div className="analysis-content error">
          <h2>Error</h2>
          <p>{error}</p>
          <button onClick={handleBackToList}>Back to List</button>
        </div>
      </div>
    );
  }

  return (
    <div className="analysis-modal">
      <div className="analysis-content">
        <div className="analysis-header">
          <h2>{t("assessment.title")}</h2>
          <div className="header-controls">
            <button className="back-button" onClick={handleBackToList}>
              {t("assessment.backToList")}
            </button>
            <div className="language-selector">
              <button
                className="translate-button"
                onClick={() => setShowLanguageMenu(!showLanguageMenu)}
                title={t("Change Language")}
                disabled={isTranslating}
              >
                {isTranslating ? <ClipLoader size={20} color="#6920b0" /> : "🌐"}
              </button>
              {showLanguageMenu && !isTranslating && (
                <div className="language-menu">
                  {Object.entries(languages).map(([code, name]) => (
                    <button
                      key={code}
                      className={`language-option ${selectedLanguage === code ? "active" : ""}`}
                      onClick={() => handleLanguageChange(code)}
                    >
                      {name}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="analysis-body">
          <div className="candidate-info">
            <h3>{analysis.candidateName}</h3>
            <p className="email">{analysis.candidateEmail}</p>
            <div className="score">{t("assessment.overallScore")}: {analysis.score}%</div>
          </div>

          <div className="analysis-sections">
            <section>
              <h4>{t("assessment.strengths")}</h4>
              <p>{analysis.analysis.strengths}</p>
            </section>

            <section>
              <h4>{t("assessment.weaknesses")}</h4>
              <p>{analysis.analysis.weaknesses}</p>
            </section>

            <section>
              <h4>{t("assessment.recommendations")}</h4>
              <p>{analysis.analysis.recommendations}</p>
            </section>

            <section className="candidate-answers">
              <h4>{t("assessment.assessmentResults")}</h4>
              <table className="evaluation-table">
                <thead>
                  <tr>
                    <th>{t("assessment.question")}</th>
                    <th>{t("assessment.candidateAnswer")}</th>
                    <th>{t("assessment.correctAnswer")}</th>
                    <th>{t("assessment.score")}</th>
                  </tr>
                </thead>
                <tbody>
                  {analysis.analysis.detailedAnalysis.map((item, index) => (
                    <tr key={index} className={item.score > 0 ? "correct" : "incorrect"}>
                      <td>{item.question}</td>
                      <td>{item.candidateAnswer}</td>
                      <td>{item.correctAnswer}</td>
                      <td>{item.score > 0 ? "✓" : "✗"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalysisLanguage;
