import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './StructuredInterviewsList.scss';
import { API_URL } from '../../../shared';

const StructuredInterviewsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [interviews, setInterviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [interviewToDelete, setInterviewToDelete] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(true);

  const fetchInterviews = async () => {
    try {
      const response = await fetch(`${API_URL}/interview-questionnaires`, {
        credentials: 'include',
        headers: {
          'Accept': 'application/json'
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Server error:', errorData);
        throw new Error(errorData.message || 'Failed to fetch interviews');
      }
      
      const data = await response.json();
      if (isSubscribed) {
        console.log('Fetched interviews:', data);
        setInterviews(data || []);
      }
    } catch (error) {
      if (isSubscribed) {
        console.error('Error details:', error);
        toast.error(t('structuredInterview.errors.fetchFailed'));
      }
    } finally {
      if (isSubscribed) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setIsSubscribed(true);
    fetchInterviews();
    return () => {
      setIsSubscribed(false);
    };
  }, []);

  const handleActionChange = (e, interviewId) => {
    const action = e.target.value;
    
    switch (action) {
      case 'view':
        navigate(`/b2b/dashboard/structured-interviews/${interviewId}`);
        break;
      case 'delete':
        setInterviewToDelete(interviewId);
        setShowDeleteConfirm(true);
        break;
      default:
        break;
    }
    
    e.target.value = 'default';
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${API_URL}/interview-questionnaires/${interviewToDelete}`, {
        method: 'DELETE',
        credentials: 'include'
      });

      if (!response.ok) throw new Error('Failed to delete interview');

      toast.success(t('structuredInterview.deleteSuccess'));
      await fetchInterviews();
    } catch (error) {
      console.error('Error:', error);
      toast.error(t('structuredInterview.errors.deleteFailed'));
    } finally {
      setShowDeleteConfirm(false);
      setInterviewToDelete(null);
    }
  };

  if (loading) {
    return <div className="loading">{t('common.loading')}</div>;
  }

  return (
    <div className="structured-interviews-list">
      <ToastContainer />
      <div className="list-header">
        <h2>{t('structuredInterview.list.title')}</h2>
        <div className="header-buttons">
          <button 
            onClick={() => navigate('/b2b/dashboard/structured-interview-questionnaire')}
            className="generate-ai-button"
          >
            {t('structuredInterview.list.generateAI')}
          </button>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>{t('structuredInterview.list.jobOpening')}</th>
            <th>{t('structuredInterview.list.candidate')}</th>
            <th>{t('structuredInterview.list.candidateProfile')}</th>
            <th>{t('structuredInterview.list.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {interviews.map((interview) => (
            <tr key={interview.id}>
              <td>{interview.JobOfferQuestionnaire?.role || t('common.notAvailable')}</td>
              <td>
                {`${interview.Candidate?.first_name} ${interview.Candidate?.last_name}` || t('common.notAvailable')}
              </td>
              <td>
                {interview.Candidate && (
                  <Link 
                    to={`/b2b/dashboard/candidates/${interview.Candidate.id}`}
                    className="view-profile-link"
                  >
                    {t('structuredInterview.list.viewProfile')}
                  </Link>
                )}
              </td>
              <td>
                <select 
                  onChange={(e) => handleActionChange(e, interview.id)}
                  defaultValue="default"
                  className="action-select"
                >
                  <option value="default" disabled>
                    {t('common.selectAction')}
                  </option>
                  <option value="view">{t('common.view')}</option>
                  <option value="delete">{t('common.delete')}</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showDeleteConfirm && (
        <div className="delete-confirmation-overlay">
          <div className="delete-confirmation-dialog">
            <p>{t('structuredInterview.list.deleteConfirm')}</p>
            <div className="dialog-actions">
              <button 
                className="cancel-button"
                onClick={() => {
                  setShowDeleteConfirm(false);
                  setInterviewToDelete(null);
                }}
              >
                {t('common.cancel')}
              </button>
              <button 
                className="delete-button"
                onClick={handleDelete}
              >
                {t('common.delete')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StructuredInterviewsList; 