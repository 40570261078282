import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FaPlus } from "react-icons/fa";
import "./AIPhoneInterviewList.scss";
import { API_URL } from '../../../../shared.js';

const AIPhoneInterviewList = () => {
  const [interviews, setInterviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();


  const handleActionSelect = (event, interviewId) => {
    const action = event.target.value;
    const selectedInterview = interviews.find((i) => i.id === interviewId);

    switch (action) {
      case "view":
        navigate(`/b2b/dashboard/ai-phone-interviews/${interviewId}`);
        break;
      case "edit":
        navigate(`/b2b/dashboard/ai-phone-interviews/${interviewId}`);
        break;
      case "delete":
        handleDelete(selectedInterview);
        console.log("Delete interview:", interviewId);
        break;
      default:
        break;
    }
  };

  const handleCreateInterview = () => {
    console.log("Create button clicked");
    navigate("create");
  };
  const handleDelete = async (selectedInterview) => {
    if (window.confirm(t("aiPhoneInterview.confirmDelete"))) {
      try {
        const response = await fetch(
          `${API_URL}/ai-phone-interviews/${selectedInterview.id}`,
          {
            method: "DELETE",
            credentials: "include",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          // Supprimer l'interview localement après la suppression côté serveur
          setInterviews((prevInterviews) =>
            prevInterviews.filter((i) => i.id !== selectedInterview.id)
          );
          alert(t("aiPhoneInterview.deleted"));
        } else {
          alert(t("aiPhoneInterview.details.deleteFailed"));
        }
      } catch (error) {
        console.error("Error deleting interview:", error);
        alert(t("aiPhoneInterview.details.deleteError"));
      }
    }
  };

  // Fetch AI phone interviews
  useEffect(() => {
    const fetchInterviews = async () => {
      try {
        const response = await fetch(`${API_URL}/ai-phone-interviews`, {
          method: "GET",
          credentials: "include",
          headers: { 
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch interviews");
        }
        const data = await response.json();
        setInterviews(data);
      } catch (error) {
        console.error("Error fetching interviews:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInterviews();
  }, []);

  if (isLoading) {
    return <div className="loading-spinner">Loading...</div>;
  }

  return (
    <div className="ai-phone-interview-list">
      <div className="ai-phone-interview-header">
        <div className="header-content">
          <h2>{t("aiPhoneInterview.title")}</h2>
          <p>{t("aiPhoneInterview.subtitle")}</p>
        </div>
        <button
          onClick={handleCreateInterview}
          className="create-interview-button"
        >
          <FaPlus /> {t("aiPhoneInterview.create")}
        </button>
      </div>

      <div className="interview-list">
        {interviews.length === 0 ? (
          <div className="no-interviews">
            <p>{t("aiPhoneInterview.noInterviews")}</p>
          </div>
        ) : (
          <table className="interview-table">
            <thead>
              <tr>
                <th>{t("aiPhoneInterview.formName")}</th>
                <th>{t("aiPhoneInterview.jobOpening")}</th>
                <th>{t("aiPhoneInterview.createdAt")}</th>
                <th>{t("aiPhoneInterview.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {interviews.map((interview) => (
                <tr key={interview.id}>
                  <td>{interview.form_name || "Unnamed Interview"}</td>
                  <td>
                    <Link
                      to={`/b2b/dashboard/job-openings/${interview.JobOffer?.id}`}
                      className="job-link"
                    >
                      {interview.JobOffer?.role || "Unknown Job Title"}
                    </Link>
                  </td>
                  <td>
                    {moment(interview.created_at).format("DD/MM/YYYY HH:mm")}
                  </td>
                  <td className="actions-column">
                    <select
                      className="action-select"
                      onChange={(e) => handleActionSelect(e, interview.id)}
                      defaultValue="default"
                    >
                      <option value="default" disabled>
                        {t("aiPhoneInterview.selectAction")}
                      </option>
                      <option value="view">{t("aiPhoneInterview.view")}</option>
                      <option value="edit">{t("aiPhoneInterview.edit")}</option>
                      <option value="delete">
                        {t("aiPhoneInterview.delete")}
                      </option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default AIPhoneInterviewList;