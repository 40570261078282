import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../../../shared';
import './CandidatePipelineDetails.scss';

const CandidatePipelineDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const [candidate, setCandidate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCandidateDetails = async () => {
      try {
        console.log('Fetching candidate details for ID:', id);
        setLoading(true);
        
        // Use the new endpoint that gets specific candidate by ID
        const response = await fetch(`${API_URL}/candidates/pipeline/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Failed to fetch candidate details');
        }

        const candidateData = await response.json();
        console.log('Received candidate data:', candidateData);
        setCandidate(candidateData);
        setError(null);
      } catch (err) {
        console.error('Error fetching candidate details:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchCandidateDetails();
    }
  }, [id]);

  const handleStageChange = (newStage) => {
    // Handle stage change logic here
    console.log('Stage changed to:', newStage);
  };

  const handleBack = () => {
    navigate('/b2b/dashboard/candidates/pipeline');
  };

  const handleMoveToPool = async () => {
    try {
      const response = await fetch(`${API_URL}/candidates/move-to-pool/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (response.ok) {
        // Navigate back to pipeline after successful move
        navigate('/b2b/dashboard/candidates/pipeline');
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || t('dashboard.moveToPoolError'));
      }
    } catch (error) {
      console.error('Error moving candidate to pool:', error);
      setError(t('dashboard.moveToPoolError'));
    }
  };

  const handleDeleteCandidate = async () => {
    if (window.confirm(t('candidatePipeline.messages.deleteConfirmation'))) {
      try {
        const response = await fetch(`${API_URL}/candidates/pipeline/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });

        if (response.ok) {
          // Navigate back to pipeline after successful deletion
          navigate('/b2b/dashboard/candidates/pipeline');
        } else {
          const errorData = await response.json();
          throw new Error(errorData.message || t('candidatePipeline.messages.deleteError'));
        }
      } catch (error) {
        console.error('Error deleting candidate:', error);
        setError(t('candidatePipeline.messages.deleteError'));
      }
    }
  };

  if (loading) {
    return (
      <div className="candidate-pipeline-details loading-state">
        <button className="back-button" onClick={handleBack}>
          {t('dashboard.backToPipeline')}
        </button>
        <div className="loading-message">{t('dashboard.loadingCandidate')}</div>
      </div>
    );
  }

  if (error || !candidate) {
    return (
      <div className="candidate-pipeline-details error-state">
        <button className="back-button" onClick={handleBack}>
          {t('dashboard.backToPipeline')}
        </button>
        <div className="error-message">{error || t('dashboard.candidateNotFound')}</div>
      </div>
    );
  }

  return (
    <div className="candidate-pipeline-details">
      <button className="back-button" onClick={handleBack}>
        {t('dashboard.backToPipeline')}
      </button>

      <div className="candidate-header">
        <h2>{candidate.name}</h2>
        <span className="position-badge">{candidate.position}</span>
        <span className="stage-badge">{candidate.currentStage}</span>
      </div>

      <div className="details-grid">
        <div className="detail-section">
          <h3>{t('dashboard.personalInfo')}</h3>
          <div className="info-group">
            {candidate.linkedinUrl && (
              <p>
                <strong>{t('dashboard.linkedin_profile_link')}:</strong>{' '}
                <a 
                  href={candidate.linkedinUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkedin-link"
                >
                  {candidate.linkedinUrl}
                </a>
              </p>
            )}
            {candidate.most_recent_experience && (
              <p><strong>{t('dashboard.most_resent_experience')}:</strong> {candidate.most_recent_experience}</p>
            )}
            {candidate.currentTitle && (
              <p><strong>{t('dashboard.currentTitle')}:</strong> {candidate.currentTitle}</p>
            )}
          </div>
        </div>

        <div className="detail-section">
          <h3>{t('dashboard.skills')}</h3>
          <div className="skills-container">
            {candidate.skills && candidate.skills.length > 0 ? (
              candidate.skills.map((skill, index) => (
                <span key={index} className="skill-badge">{skill}</span>
              ))
            ) : (
              <p className="no-data">{t('dashboard.noSkillsListed')}</p>
            )}
          </div>
        </div>

        {candidate.documents && candidate.documents.length > 0 && (
          <div className="detail-section">
            <h3>{t('dashboard.documents')}</h3>
            <div className="documents-list">
              {candidate.documents.map((doc, index) => (
                <div key={index} className="document-card">
                  <span className="document-icon">📄</span>
                  <div className="document-info">
                    <p className="document-name">{doc.name}</p>
                    <p className="document-type">{doc.type}</p>
                    <p className="document-date">{doc.uploadDate}</p>
                  </div>
                  <button className="download-btn">{t('dashboard.download')}</button>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="detail-section full-width">
          <div className="action-buttons">
            <button 
              className="success-btn"
              onClick={handleMoveToPool}
            >
              {t('dashboard.addToPool')}
            </button>
            <button 
              className="danger-btn"
              onClick={handleDeleteCandidate}
            >
              {t('dashboard.dismiss')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidatePipelineDetails;
