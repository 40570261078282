import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../../shared.js";
import { useNavigate } from "react-router-dom";
import Spinner from '../../../common/Spinner/Spinner';
import "./CreateLanguageAssessment.scss";

import "./CreateAssessment.scss";

const CreateLanguageAssessment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedJob, setSelectedJob] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [jobOpenings, setJobOpenings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJobOpenings = async () => {
      try {
        const response = await fetch(`${API_URL}/job-offers`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setJobOpenings(data);
      } catch (err) {
        console.error("Error fetching job openings:", err);
        setError(t("assessments.errors.jobOpenings"));
      }
    };

    fetchJobOpenings();
  }, [t]);

  const languages = ["English", "French", "German", "Spanish", "Italian", "Portuguese"];
  const levels = ["A1", "A2", "B1", "B2", "C1", "C2"];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const selectedJobOpening = jobOpenings.find(
      (job) => job.id === selectedJob
    );

    if (selectedJobOpening) {
      try {
        const response = await fetch(
          `${API_URL}/languageskillsassessmentgenerated`, {
            method: "POST",
            headers: { 
              "Content-Type": "application/json" 
            },
            credentials: "include",
            body: JSON.stringify({
              language: selectedLanguage,
              level: selectedLevel,
              type: 'language',
              jobId: selectedJob
            })
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("Assessment generated:", result);

        if (result.success && result.analysisId) {
          navigate(`/b2b/dashboard/assessments/language-skills/${result.analysisId}`, {
            state: { data: result }
          });
        } else {
          throw new Error(t("assessments.errors.generation"));
        }
      } catch (err) {
        console.error("Error generating assessment:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      setError(t("assessments.errors.noJobSelected"));
      setLoading(false);
    }
  };

  return (
    <div className="create-assessment">
      {loading && (
        <div className="loading-overlay">
          <Spinner />
          <div className="waiting-message">
            {t("assessments.generationTime")}
          </div>
        </div>
      )}

      <div className="create-assessment-header">
        <h2>{t("assessments.createLanguage")}</h2>
      </div>

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="jobOpening">{t("assessments.selectJob")}</label>
          <select
            id="jobOpening"
            value={selectedJob}
            onChange={(e) => setSelectedJob(e.target.value)}
            required
          >
            <option value="" disabled>
              {t("assessments.selectJobOption")}
            </option>
            {jobOpenings.map((job) => (
              <option key={job.id} value={job.id}>
                {job.role}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="language">{t("assessments.selectLanguage")}</label>
          <select
            id="language"
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
            required
          >
            <option value="" disabled>
              {t("assessments.selectLanguageOption")}
            </option>
            {languages.map((lang) => (
              <option key={lang} value={lang}>
                {t(`assessments.languages.${lang}`)}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="level">{t("assessments.selectLevel")}</label>
          <select
            id="level"
            value={selectedLevel}
            onChange={(e) => setSelectedLevel(e.target.value)}
            required
          >
            <option value="" disabled>
              {t("assessments.selectLevelOption")}
            </option>
            {levels.map((level) => (
              <option key={level} value={level}>
                {t(`assessments.levels.${level}`)}
              </option>
            ))}
          </select>
        </div>

        <div className="form-actions">
          <button 
            type="submit" 
            className="submit-button"
            disabled={loading}
          >
            {loading ? (
              <Spinner small />
            ) : (
              t("assessments.generateLanguage")
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateLanguageAssessment;
