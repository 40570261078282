import React from 'react';
import { useTranslation } from 'react-i18next';

import './ELearningVideos.scss';

const ELearningVideos = ({ userType = 'company' }) => {
  const { t } = useTranslation();
  const getVisibleSections = () => {
    switch (userType) {
      case 'company':
        return ['generalTourEnterprise', 'talexaChatEnterprise', 'dashboardEnterpriseJobOpenings', 'dashboardEnterpriseTracking', 'dashboardEnterpriseCandidates', 'dashboardEnterpriseJobBoard', 'dashboardEnterpriseAddOns'];
      case 'candidate':
        return ['generalTourCandidate', 'talexaChatCandidate', 'jobSearch'];
      case 'admin':
      case 'reseller':
        return [
          'generalTourReseller',
          'generalTourEnterprise',
          'generalTourCandidate',
          'talexaChatEnterprise',
          'talexaChatCandidate',
          'dashboardReseller',
          'dashboardEnterpriseJobOpenings',
          'dashboardEnterpriseTracking',
          'dashboardEnterpriseCandidates',
          'dashboardEnterpriseJobBoard',
          'jobSearch',
          'clientManagement',
          'dashboardEnterpriseAddOns',
        ];
      default:
        return ['generalTour'];
    }
  };

  const sectionVideos = {
    generalTourEnterprise: [
      { name: t('eLearningVideos.sectionVideos.sections.generalTourEnterprise.firstLogin.title'), about: t('eLearningVideos.sectionVideos.sections.generalTourEnterprise.firstLogin.description'), youtubeId: 's4gMWjNzVK8' },
      { name: t('eLearningVideos.sectionVideos.sections.generalTourEnterprise.profile.title'), about: t('eLearningVideos.sectionVideos.sections.generalTourEnterprise.profile.description'), youtubeId: 'znAj7e_ag5A' },
      { name: t('eLearningVideos.sectionVideos.sections.generalTourEnterprise.help.title'), about: t('eLearningVideos.sectionVideos.sections.generalTourEnterprise.help.description'), youtubeId: 'F804_kMoPDA' },
    ],
    generalTourCandidate: [
      { name: 'React JS Explained In 10 Minutes', about: 'Core concepts every React developer should know.', youtubeId: 's2skans2dP4' },
      { name: '6 Étapes Pour Réussir sa Recherche d’Emploi', about: 'Les étapes essentielles pour trouver un emploi avec succès.', youtubeId: 'HNdL-NJoDCc' },  
      { name: '5 ÉTAPES POUR RÉUSSIR SA RECHERCHE D\'EMPLOI', about: 'Les étapes clés d\'une recherche d\'emploi réussie.', youtubeId: 'WR-vJPS9qUQ' },  
      { name: 'Organiser sa recherche d\'emploi - Les Tips pour l\'Emploi', about: 'Conseils pour organiser efficacement votre recherche d\'emploi.', youtubeId: '0GDAt9nizhI' },  
    ],

    generalTourReseller: [
      { name: 'Digital Marketing Course 2024', about: 'Everything You Need To Know', youtubeId: 'jVgYgN0zcWs' },
      { name: 'Réussir sa vidéo d\'entreprise - Partie 1/3', about: 'Les clés pour une vidéo d\'entreprise réussie.', youtubeId: 'aagd-14DRXU' },
      { name: 'Les conseils pour bien présenter son entreprise en vidéo', about: 'Astuces pour une présentation efficace en vidéo.', youtubeId: '86Q3qXEbfug' },
      { name: 'Comment présenter son entreprise en vidéo ?', about: 'Stratégies pour une présentation mémorable de votre entreprise.', youtubeId: 'tyywBU5m2Vw' },
      { name: 'React JS Explained In 10 Minutes', about: 'Core concepts every React developer should know.', youtubeId: 's2skans2dP4' },
      { name: '6 Étapes Pour Réussir sa Recherche d’Emploi', about: 'Les étapes essentielles pour trouver un emploi avec succès.', youtubeId: 'HNdL-NJoDCc' }, 
      { name: '5 ÉTAPES POUR RÉUSSIR SA RECHERCHE D\'EMPLOI', about: 'Les étapes clés d\'une recherche d\'emploi réussie.', youtubeId: 'WR-vJPS9qUQ' }, 
      { name: 'Organiser sa recherche d\'emploi - Les Tips pour l\'Emploi', about: 'Conseils pour organiser efficacement votre recherche d\'emploi.', youtubeId: '0GDAt9nizhI' }, 
      { name: 'Comment devenir le meilleur vendeur | Processus de vente dans une entreprise', about: 'Apprenez les étapes clés pour exceller dans le processus de vente au sein d\'une entreprise.', youtubeId: 'IhZBMtPVB_Q',
      },
    ],

    talexaChatEnterprise: [
      { name: t('eLearningVideos.sectionVideos.sections.talexaChatEnterprise.chat.title'), about: t('eLearningVideos.sectionVideos.sections.talexaChatEnterprise.chat.description'), youtubeId: 'tUenrWYIKDw' },
      { name: t('eLearningVideos.sectionVideos.sections.talexaChatEnterprise.sourcing.title'), about: t('eLearningVideos.sectionVideos.sections.talexaChatEnterprise.sourcing.description'), youtubeId: 'rvMRGWzdO9k' },  
      { name: t('eLearningVideos.sectionVideos.sections.talexaChatEnterprise.comparison.title'), about: t('eLearningVideos.sectionVideos.sections.talexaChatEnterprise.comparison.description'), youtubeId: 'liVvEdGBY2g' }, 
    ],

    talexaChatCandidate: [
      { name: 'Live demo of GPT-4o', about: 'A live demonstration of OpenAI’s GPT-4o.', youtubeId: 'c2DFg53Zhvw' },
      { name: 'Comment les chatbots transforment le recrutement', about: 'Découvrez l\'impact des chatbots dans le processus de recrutement.', youtubeId: 'dQw4w9WgXcQ' },  
      { name: 'Les avantages des chatbots pour les candidats', about: 'Comprendre comment les chatbots facilitent la recherche d\'emploi.', youtubeId: 'eYq7WapuDLU' }, 
      { name: 'Préparer son entretien avec un chatbot recruteur', about: 'Conseils pour interagir efficacement avec un chatbot lors du recrutement.', youtubeId: 'fJ9rUzIMcZQ' },  
    ],

    dashboardEnterpriseJobOpenings: [
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseJobOpenings.sections.createManual.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseJobOpenings.sections.createManual.description'), youtubeId: 'DI7-9rHlt0w' },
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseJobOpenings.sections.createAI.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseJobOpenings.sections.createAI.description'), youtubeId: 'HgcxQ0kcB9Q' },
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseJobOpenings.sections.hiringRequest.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseJobOpenings.sections.hiringRequest.description'), youtubeId: 'iGHMIWpjcp4' },
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseJobOpenings.sections.generateFromRequest.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseJobOpenings.sections.generateFromRequest.description'), youtubeId: 'tAQP0WouJdg' },
    ],

    dashboardEnterpriseTracking: [
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseTracking.sections.trackingBoard.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseTracking.sections.trackingBoard.description'), youtubeId: 'oQgY5RHZhko' },
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseTracking.sections.emailTemplates.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseTracking.sections.emailTemplates.description'), youtubeId: 'S9THR5gfaa8' },
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseTracking.sections.receivedApplications.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseTracking.sections.receivedApplications.description'), youtubeId: '6Z5vVsoiFEc' },
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseTracking.sections.structuredInterviews.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseTracking.sections.structuredInterviews.description'), youtubeId: 'G3_UD9Ef2Fg' },
    ],

    dashboardEnterpriseCandidates: [
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseCandidates.sections.createCandidate.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseCandidates.sections.createCandidate.description'), youtubeId: '85lm8w9RDLU' },
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseCandidates.sections.candidateMyPool.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseCandidates.sections.candidateMyPool.description'), youtubeId: 'e3PJpidE12U' },
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseCandidates.sections.candidateSharedPool.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseCandidates.sections.candidateSharedPool.description'), youtubeId: '_MvPadNV5D0' },
    ],

    dashboardEnterpriseJobBoard: [
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseJobBoard.sections.viewJobBoard.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseJobBoard.sections.viewJobBoard.description'), youtubeId: '73OvlyDQ95Q' },
    ],

    dashboardEnterpriseAddOns : [
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseAddOns.sections.whiteLabel.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseAddOns.sections.whiteLabel.description'), youtubeId: 'Sb8jrvKzwuA' },
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseAddOns.sections.skillsAssessment.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseAddOns.sections.skillsAssessment.description'), youtubeId: 'eqgK4zRAa_E' },
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseAddOns.sections.marketHiringTrends.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseAddOns.sections.marketHiringTrends.description'), youtubeId: 'Rf4XkQOi3vo' },
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseAddOns.sections.companyHiringTrends.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseAddOns.sections.companyHiringTrends.description'), youtubeId: 'rkVsr003obs' },
      { name: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseAddOns.sections.aiWebInterview.title'), about: t('eLearningVideos.sectionVideos.sections.dashboardEnterpriseAddOns.sections.aiWebInterview.description'), youtubeId: '0xLmJZI030k' },
    ],

    dashboardReseller: [
      { name: 'How to Build Excel Interactive Dashboards', about: 'Learn to showcase KPIs with Excel.', youtubeId: 'MTlQvyNQ3PM' },
      { name: 'Maîtrisez la création de tableaux de bord professionnels avec Excel', about: 'Formation complète pour créer des tableaux de bord percutants.', youtubeId: 'hZUJB6gMcPM' },  
      { name: 'Créer un INCROYABLE tableau de bord Excel interactif en moins de 45 minutes', about: 'Guide étape par étape pour un tableau de bord dynamique.', youtubeId: 'KtwHzoAEJEw' }, 
      { name: 'Conception de tableau de bord interactif de A à Z sur Excel - Niveau intermédiaire', about: 'Apprenez à concevoir un tableau de bord interactif complet.', youtubeId: 'vtXEGoJOXBw' },  
       { name: 'Formation Vente digitale 49/50 - Pilotage et gestion - TABLEAU DE BORD', about: 'Apprenez à piloter et gérer vos ventes digitales grâce à un tableau de bord efficace.', youtubeId: 'zxhFiGIDkSU' },

    ],
    jobSearch: [
      { name: 'Job Hunting Tips', about: 'Learn the best tips for job hunting success.', youtubeId: 'example12345' },
      { name: '5 ÉTAPES POUR RÉUSSIR SA RECHERCHE D\'EMPLOI', about: 'Les étapes clés d\'une recherche d\'emploi réussie.', youtubeId: 'WR-vJPS9qUQ' }, 
      { name: '6 Étapes Pour Réussir sa Recherche d’Emploi', about: 'Les étapes essentielles pour trouver un emploi avec succès.', youtubeId: 'HNdL-NJoDCc' },  
      { name: 'Recherche d\'emploi: par où commencer [7 étapes pour être efficace]', about: 'Conseils pour bien démarrer et organiser votre recherche d\'emploi.', youtubeId: '-MUu4o2JS2c' },  
    ],
    clientManagement: [
      { name: 'Client Management Best Practices', about: 'Learn to manage clients effectively.', youtubeId: 'exampleClient123' },
      { name: 'La Gestion de la Relation Client : les 5 principes actifs à connaître', about: 'Découvrez les cinq principes clés pour une gestion efficace de la relation client.', youtubeId: '0V5neDFc5gY' },
    ],
  };

  const sectionTitles = {
    dashboardEnterpriseJobOpenings: t('eLearningVideos.sectionVideos.jobOpenings'),
    dashboardEnterpriseTracking: t('eLearningVideos.sectionVideos.tracking'),
    dashboardEnterpriseCandidates: t('eLearningVideos.sectionVideos.candidates'),
    dashboardEnterpriseJobBoard: t('eLearningVideos.sectionVideos.jobBoard')
  };


  const visibleSections = getVisibleSections();

  return (
    <div className="e-learning-videos">
      <div className="header-section">
        <h2>{t('dashboard.eLearning.title')}</h2>
      </div>

      {visibleSections.map((section) => (
        <div key={section} className="video-section">
          <div className="section-header">
            <h3>{sectionTitles[section] || t(`eLearningVideos.sectionVideos.sections.${section}.title`)}</h3>
            <p>{t(`eLearningVideos.sectionVideos.sections.${section}.description`)}</p>
          </div>


          <div className="videos-grid">
            {sectionVideos[section]?.map((video, index) => (
              <div key={index} className="video-card">
                <div className="video-thumbnail">
                  <iframe
                    src={`https://www.youtube.com/embed/${video.youtubeId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title={video.name}
                  ></iframe>
                </div>
                <div className="video-info">
                  <h4>{video.name}</h4>
                  <p>{video.about}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ELearningVideos;
