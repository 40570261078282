import React, { useState, useContext } from 'react';
import { ThemeContext } from './ThemeContext';  
import { FaUserCircle, FaSignOutAlt, FaUsers, FaRegCreditCard, FaCog, FaIndustry, FaTags, FaVideo, FaFileInvoice, FaFileContract, FaRegNewspaper } from 'react-icons/fa'; 
import { Link, useLocation, useNavigate} from 'react-router-dom';
import { logError } from '../../services/errorLogging'; 
import './AdminSidebar.scss'; 
import Logo from '../../assets/Logo.png';
import { API_URL } from '../../shared';

const Sidebar = () => {
  const { customColors } = useContext(ThemeContext);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation(); 
  const navigate = useNavigate();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const isActive = (path) => {
    return (window.location.pathname === path || window.location.pathname.startsWith(path)) ? "active" : '';
  };
  
  const switchToCompany = () => {
    navigate("/b2b/dashboard");
  }

   const handleLogout = async () => {
      try {
  
        const response = await fetch(`${API_URL}/logout`, {
          credentials: 'include',
        });
    
        if (!response.ok) {
          throw new Error('Authentication check failed');
        }
        // Supprimez les informations de l'utilisateur du sessionStorage
        sessionStorage.removeItem('user');
        sessionStorage.clear();
        // Supprimez le token d'authentification (si vous en utilisez un)
        localStorage.removeItem('authToken');
        // Redirigez l'utilisateur vers la page de connexion
        navigate('/login');
      } catch (error) {
        logError(error);
      }
    };

  return (
    <div className="sidebar-layout">
      {/* Sidebar Header */}
      <div className="sidebar-header" style={{ backgroundColor: customColors.primaryColor }}>
        <div className="logo-container">
          <img src={Logo} height="50px" alt="Company Logo" className="company-logo-circle" />
          <span>HRNOV AI</span>
        </div>
      </div>

      {/* Sidebar Tabs */}
      <div className="sidebar-tabs" style={{ backgroundColor: customColors.primaryColor }}>
        <div className={`sidebar-item ${isActive('/admin/dashboard/users')}`}>
          <Link to="/admin/dashboard/users" className={`sidebar-link ${isActive('/admin/dashboard/users')}`}>
            <FaUsers className="sidebar-icon" />
            <span>Users</span>
          </Link>
        </div>
        <div className={`sidebar-item ${isActive('/admin/dashboard/companies')}`}>
          <Link to="/admin/dashboard/companies" className={`sidebar-link ${isActive('/admin/dashboard/companies')}`}>
            <FaIndustry className="sidebar-icon" />
            <span>Companies</span>
          </Link>
        </div>
        <div className={`sidebar-item ${isActive('/admin/dashboard/subscriptions')}`}>
          <Link to="/admin/dashboard/subscriptions" className={`sidebar-link ${isActive('/admin/dashboard/subscriptions')}`}>
            <FaRegCreditCard className="sidebar-icon" />
            <span>Subscriptions</span>
          </Link>
        </div>
        <div className={`sidebar-item ${isActive('/admin/dashboard/promo-codes')}`}>
          <Link to="/admin/dashboard/promo-codes" className={`sidebar-link ${isActive('/admin/dashboard/promo-codes')}`}>
            <FaTags className="sidebar-icon" />
            <span>Promo Codes</span>
          </Link>
        </div>
        <div className={`sidebar-item ${isActive('/admin/dashboard/e-learning-videos')}`}>
          <Link to="/admin/dashboard/e-learning-videos" className={`sidebar-link ${isActive('/admin/dashboard/e-learning-videos')}`}>
            <FaVideo className="sidebar-icon" />
            <span>E-learning Videos</span>
          </Link>
        </div>
        <div className={`sidebar-item ${isActive('/admin/dashboard/invocies')}`}>
          <Link to="/admin/dashboard/invocies" className={`sidebar-link ${isActive('/admin/dashboard/invocies')}`}>
            <FaFileInvoice className="sidebar-icon" />
            <span>Invoices</span>
          </Link>
        </div>
        <div className={`sidebar-item ${isActive('/admin/dashboard/contracts')}`}>
          <Link to="/admin/dashboard/contracts" className={`sidebar-link ${isActive('/admin/dashboard/contracts')}`}>
            <FaFileContract className="sidebar-icon" />
            <span>Contracts</span>
          </Link>
        </div>
        <div className={`sidebar-item ${isActive('/admin/dashboard/blogs')}`}>
          <Link to="/admin/dashboard/blogs" className={`sidebar-link ${isActive('/admin/dashboard/blogs')}`}>
            <FaRegNewspaper className="sidebar-icon" />
            <span>Blogs</span>
          </Link>
        </div>
        <div className={`sidebar-item ${isActive('/admin/dashboard/settings')}`}>
          <Link to="/admin/dashboard/settings" className={`sidebar-link ${isActive('/admin/dashboard/settings')}`}>
            <FaCog className="sidebar-icon" />
            <span>Settings</span>
          </Link>
        </div>
      </div>

      {/* Sidebar Footer */}
      <div className="sidebar-footer">
        <div className="switch-button" onClick={switchToCompany}>
          <span>Switch to Company</span>
        </div>
        <div className="logout-button" onClick={handleLogout}>
          <FaSignOutAlt className="sidebar-icon" />
         <span>Logout</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
