import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./CandidatePool.scss";
import { API_URL } from "../../../shared.js";
import { toast } from "react-toastify";

const CandidatePool = ({ userAddOns }) => {
  const { t } = useTranslation();
  const [candidates, setCandidates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddingCandidate, setIsAddingCandidate] = useState(false);
  const [newCandidate, setNewCandidate] = useState({
    firstName: "",
    lastName: "",
    jobRole: "",
    email: "",
    phone: "",
    linkedinUrl: "",
    languages: [],
    academicExperiences: [],
    workExperiences: [],
  });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [candidateToDelete, setCandidateToDelete] = useState(null);
  // Tracking board variable states
  const [trackingBoards, setTrackingBoards] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [selectedList, setSelectedList] = useState(null);
  const [showTrackingBoardModal, setShowTrackingBoardModal] = useState(false);
  const [candidateToAdd, setCandidateToAdd] = useState(null);
  const [isLoadingBoards, setIsLoadingBoards] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Variable for conditionning the display of the "See more" button
  const navigate = useNavigate();
  const isDashboard = location.pathname === "/b2b/dashboard";
  const maxCandidate = 5;
  const limitedCandidates = isDashboard
    ? candidates.slice(0, maxCandidate)
    : candidates;
  const hasMoreCandidates = candidates.length > maxCandidate;

  useEffect(() => {
    fetchCandidates();
  }, []);

  const fetchCandidates = async () => {
    try {
      const response = await fetch(`${API_URL}/candidates`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch candidates");
      }

      const data = await response.json();
      console.log("Fetched candidates:", data);
      setCandidates(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error fetching candidates:", error);
      setCandidates([]);
    }
  };

  // fetch tracking boards
  const fetchTrackingBoards = async () => {
    try {
      setIsLoadingBoards(true);
      const response = await fetch(`${API_URL}/v1/kanban/boards`, {
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch tracking boards");
      }
      const data = await response.json();
      console.log("Fetched tracking boards:", data);
      setTrackingBoards(data);
    } catch (error) {
      console.error("Error fetching tracking boards:", error);
    } finally {
      setIsLoadingBoards(false);
    }
  };

  // fetching boards détails
  const fetchBoardDetails = async (boardId) => {
    try {
      const response = await fetch(`${API_URL}/v1/kanban/boards/${boardId}`, {
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch board details");
      }

      const boardDetails = await response.json();

      // Définit `KanbanLists` comme tableau vide si non défini ou incorrect
      boardDetails.KanbanLists = Array.isArray(boardDetails.KanbanLists)
        ? boardDetails.KanbanLists
        : [];
      console.log("Fetched board details:", boardDetails);
      setSelectedBoard(boardDetails);
    } catch (error) {
      console.error("Error fetching board details:", error);
    }
  };

  // Open modal to add candidate to tracking board
  const handleOpenModal = () => {
    setShowTrackingBoardModal(true);
    fetchTrackingBoards();
  };

  const handleSelectBoard = (board) => {
    if (!board) {
      console.error("No board selected.");
      return;
    }

    board.KanbanLists = Array.isArray(board.KanbanLists)
      ? board.KanbanLists
      : [];
    setSelectedBoard(board);
    fetchBoardDetails(board.id); // Met à jour les détails du board
  };

  const handleCloseModal = () => {
    console.log("Closing modal...");
    setShowTrackingBoardModal(false);
    setSelectedBoard(null);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCandidates = Array.isArray(candidates)
    ? limitedCandidates.filter((candidate) => {
        const searchLower = searchTerm.toLowerCase();
        return (
          candidate.first_name?.toLowerCase().includes(searchLower) ||
          candidate.last_name?.toLowerCase().includes(searchLower) ||
          candidate.email?.toLowerCase().includes(searchLower) ||
          (userAddOns?.add_on_language_assessment &&
            candidate.Languages?.some((lang) =>
              lang.language?.toLowerCase().includes(searchLower)
            ))
        );
      })
    : [];

  const handleAddCandidate = () => {
    navigate("/b2b/dashboard/create-candidate");
  };

  const handleSaveCandidate = async () => {
    try {
      const candidateData = {
        first_name: newCandidate.firstName,
        last_name: newCandidate.lastName,
        email: newCandidate.email,
        phone: newCandidate.phone,
        linkedin_url: newCandidate.linkedinUrl,
        // Ajoutez d'autres champs si nécessaire
      };

      const response = await fetch(`${API_URL}/create-candidate`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(candidateData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create candidate");
      }

      const data = await response.json();
      toast.success("Candidate created successfully:", data)
      
      // Rafraîchir la liste des candidats
      await fetchCandidates();
      
      setIsAddingCandidate(false);
      setNewCandidate({
        firstName: "",
        lastName: "",
        jobRole: "",
        email: "",
        phone: "",
        linkedinUrl: "",
        languages: [],
        academicExperiences: [],
        workExperiences: [],
      });
    } catch (error) {
      console.error("Error adding candidate:", error);
      toast.error(error.message || "Failed to create candidate")
    }
  };

  const handleDeleteCandidate = async (id) => {
    if (window.confirm(t('candidatePool.alerts.deleteConfirm'))) {
      try {
        const response = await fetch(`${API_URL}/candidates/${id}`, {
          method: 'DELETE',
          credentials: 'include'
        });

        if (response.ok) {
          toast.success(t('candidatePool.alerts.deleteSuccess'));
          fetchCandidates();
        } else {
          const error = await response.json();
          toast.error(t('candidatePool.alerts.deleteError', { message: error.message }))
        }
      } catch (error) {
        toast.error(t('candidatePool.alerts.unexpectedError'))
      }
    }
  };

  const handleCopyToPool = async (candidateId) => {
    try {
      const response = await fetch(`${API_URL}/candidates/copy-to-pool`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: candidateId }),
        credentials: 'include',
      });

      const data = await response.json();

      if (!response.ok) {
        toast.error(data.message || 'Failed to copy candidate to pool')
      }

      toast.success(t('candidatePool.alerts.copiedToPool'))
      fetchCandidates(); // Refresh the candidate list

    } catch (error) {
      console.error('Error copying to pool:', error);
      toast.error(error.message)
    }
  };

  const handleActionChange = async (e, candidateId) => {
    const action = e.target.value;

    switch (action) {
      case "details":
        navigate(`/b2b/dashboard/candidates/${candidateId}`);
        break;

      case "update":
        navigate(`/b2b/dashboard/candidates/${candidateId}/edit`);
        break;

      case "delete":
        setCandidateToDelete(candidateId);
        setShowDeleteConfirm(true);
        break;

      case "addToTrackingBoard":
        const selectedCandidate = candidates.find(
          (candidate) => candidate.id === candidateId
        );
        console.log("Selected Candidate for Tracking:", selectedCandidate);
        setCandidateToAdd(selectedCandidate);
        setShowTrackingBoardModal(true);
        fetchTrackingBoards();
        break;

      default:
        break;
    }

    // Reset select to default
    e.target.value = "default";
  };

  // checking if candidate is on a board
  const isCandidateOnBoard = (candidateId, boardId) => {
    const board = trackingBoards.find((board) => board.id === boardId);

    if (!board) {
      console.error("Board not found:", boardId);
      return false;
    }

    // Checking if `KanbanLists` is defined and an array
    if (!Array.isArray(board.KanbanLists)) {
      console.error(
        "KanbanLists not defined or not an array for board:",
        board
      );
      return false;
    }

    // Vérifiez si le candidat existe sur le tableau
    return board.KanbanLists.some((list) =>
      list.KanbanCards?.some((card) => card.candidate_id === candidateId)
    );
  };

  const confirmDelete = async () => {
    if (candidateToDelete) {
      await handleDeleteCandidate(candidateToDelete);
      setShowDeleteConfirm(false);
      setCandidateToDelete(null);
    }
  };

  const renderCandidateRow = (candidate) => (
    <tr key={candidate.id}>
      <td>{candidate.first_name}</td>
      <td>{candidate.last_name}</td>
      <td>{candidate.email?.endsWith('@placeholder.com') ? t('candidatePool.notAvailable') : candidate.email || t('candidatePool.notAvailable')}</td>
      {!isDashboard && <td>{candidate.phone || "-"}</td>}{" "}
      <td>
        {candidate.linkedin_url ? (
          <a
            href={candidate.linkedin_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('candidatePool.actions.viewProfile')}
          </a>
        ) : (
          "-"
        )}
      </td>
      {!isDashboard && (
        <td>{new Date(candidate.created_at).toLocaleDateString()}</td>
      )}{" "}
      <td className="actions-column">
        <select
          onChange={(e) => handleActionChange(e, candidate.id)}
          defaultValue="default"
          className="action-select"
        >
          <option value="default" disabled>
            {t('candidateApplications.actions.choose')} 
          </option>
          <option value="details">{t('candidatePool.actions.details')}</option>
          <option value="update">{t('candidatePool.actions.update')}</option>
          <option value="addToTrackingBoard">
            {t("candidatePool.addCandidateBoard")}
          </option>
          <option value="delete">{t('candidatePool.actions.delete')}</option>
        </select>
      </td>
    </tr>
  );

  const CandidateDescription = ({ candidate }) => {
    if (!candidate) return null;

    return (
      <div>
        <p>
          <strong>{t("candidatePool.name")}:</strong> {candidate.first_name}{" "}
          {candidate.last_name}
        </p>
        <p>
          <strong>{t("candidatePool.phone")}:</strong>{" "}
          {candidate.phone || t("candidatePool.noPhone")}
        </p>
        <p>
          <strong>{t("candidatePool.linkedin")}:</strong>{" "}
          {candidate.linkedin_url ? (
            <a
              href={candidate.linkedin_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("candidatePool.viewProfile")}
            </a>
          ) : (
            t("candidatePool.noLinkedIn")
          )}
        </p>
      </div>
    );
  };

  const handleAddToBoard = async (candidate) => {
    if (!candidate) {
      toast.error(t('candidatePool.alerts.noCandidateSelected'))
      return;
    }
    setCandidateToAdd(candidate);
    handleOpenModal();
  };

  return (
    <div className="candidate-pool">
      <div className="candidate-pool-header">
        <h2>{t('candidatePool.title')}</h2>
        {!isDashboard && (
          <button
            type="button"
            onClick={handleAddCandidate}
            className="add-candidate-button"
          >
            {t('candidatePool.actions.addNewCandidate')}
          </button>
        )}
      </div>
      <div className="search-bar">
        <input
          type="text"
          placeholder={t('candidatePool.search.placeholder')}
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      {isAddingCandidate && (
        <div className="add-candidate-form">
          <input
            type="text"
            placeholder={t('addCandidate.personalInfo.firstName.placeholder')}
            value={newCandidate.firstName}
            onChange={(e) =>
              setNewCandidate({ ...newCandidate, firstName: e.target.value })
            }
          />
          <input
            type="text"
            placeholder={t('addCandidate.personalInfo.lastName.placeholder')}
            value={newCandidate.lastName}
            onChange={(e) =>
              setNewCandidate({ ...newCandidate, lastName: e.target.value })
            }
          />
          <input
            type="text"
            placeholder={t('addCandidate.professional.jobRole.placeholder')}
            value={newCandidate.jobRole}
            onChange={(e) =>
              setNewCandidate({ ...newCandidate, jobRole: e.target.value })
            }
          />
          <input
            type="email"
            placeholder={t('addCandidate.personalInfo.email.placeholder')}
            value={newCandidate.email}
            onChange={(e) =>
              setNewCandidate({ ...newCandidate, email: e.target.value })
            }
          />
          <input
            type="tel"
            placeholder={t('addCandidate.personalInfo.phone.placeholder')}
            value={newCandidate.phone}
            onChange={(e) =>
              setNewCandidate({ ...newCandidate, phone: e.target.value })
            }
          />
          <input
            type="text"
            placeholder={t('addCandidate.social.linkedin.placeholder')}
            value={newCandidate.linkedinUrl}
            onChange={(e) =>
              setNewCandidate({ ...newCandidate, linkedinUrl: e.target.value })
            }
          />
          <button type="button" onClick={handleSaveCandidate}>
            {t('addCandidate.actions.submit')}
          </button>
        </div>
      )}
      <table className="candidates-table">
        <thead>
          <tr>
            <th>{t('candidatePool.columns.firstName')}</th>
            <th>{t('candidatePool.columns.lastName')}</th>
            <th>{t('candidatePool.columns.email')}</th>
            {!isDashboard && <th>{t('candidatePool.columns.phone')}</th>}
            <th>{t('candidatePool.columns.linkedin')}</th>
            {!isDashboard && <th>{t('candidatePool.columns.createdAt')}</th>}
            <th>{t('candidatePool.columns.actions')}</th>
          </tr>
        </thead>
        <tbody>{filteredCandidates.map(renderCandidateRow)}</tbody>
      </table>
      {isDashboard && hasMoreCandidates && (
        <div className="see-more-candidates">
          <button
            type="button"
            className="see-more-candidates-button"
            onClick={() => navigate("/b2b/dashboard/candidates")}
          >
            {t("candidatePool.seeMore")}
          </button>
        </div>
      )}
      {showDeleteConfirm && (
        <div className="delete-confirmation-overlay">
          <div className="delete-confirmation-dialog">
            <p>{t("candidatePool.alerts.deleteConfirm")}</p>
            <div className="dialog-actions">
              <button
                type="button"
                className="cancel-button"
                onClick={() => {
                  setShowDeleteConfirm(false);
                  setCandidateToDelete(null);
                }}
              >
                {t("candidatePool.actions.cancel")}
              </button>
              <button
                type="button"
                className="delete-button"
                onClick={confirmDelete}
              >
                {t("candidatePool.actions.delete")}
              </button>
            </div>
          </div>
        </div>
      )}
      {showTrackingBoardModal && (
        <div className="modal-trackingBoard">
          <div className="modal-content">
            <div className="modal-header">
              <h3> {t("candidatePool.modal.title")}</h3>
              <button type="button" onClick={handleCloseModal}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              {/* Modal content */}
              <div className="board-selector">
                <h4>{t("candidatePool.selectBoard")}</h4>
                <select
                  value={selectedBoard?.id || ""}
                  onChange={(e) => {
                    const selectedBoardId = e.target.value;
                    fetchBoardDetails(selectedBoardId);
                  }}
                >
                  <option value="" disabled>
                    {t("candidatePool.selectBoard")}
                  </option>
                  {trackingBoards.map((board) => (
                    <option key={board.id} value={board.id}>
                      {board.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="lists">
                <h4> {t("candidatePool.List")}</h4>
                {selectedBoard && selectedBoard.KanbanLists ? (
                  selectedBoard.KanbanLists.length > 0 ? (
                    <>
                      <select
                        value={selectedList?.id || ""}
                        onChange={(e) => {
                          const selectedListId = e.target.value;
                          const list = selectedBoard.KanbanLists.find(
                            (list) => list.id === selectedListId
                          );
                          setSelectedList(list);
                        }}
                      >
                        <option value="" disabled>
                          {t("candidatePool.selectList")}
                        </option>
                        {selectedBoard.KanbanLists.map((list) => (
                          <option key={list.id} value={list.id}>
                            {list.name}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : (
                    <p> {t("candidatePool.noList")}</p>
                  )
                ) : (
                  <p> {t("candidatePool.boardList")}</p>
                )}
              </div>
              {/* Add Candidate button */}
              <div className="add-candidate-button-wrapper">
                {candidateToAdd && selectedList ? (
                  <>
                    <button
                      type="button"
                      className="add-candidate-button"
                      onClick={async () => {
                        try {
                          if (!candidateToAdd) {
                            toast.error("No candidate selected.")
                            return;
                          }
                          if (
                            !selectedBoard ||
                            !Array.isArray(selectedBoard.KanbanLists)
                          ) {
                            toast.error("Selected board is invalid or has no lists.")
                            console.error(
                              "Invalid selected board:",
                              selectedBoard
                            );
                            return;
                          }
                          // Vérifie si le candidat existe déjà
                          const exists = isCandidateOnBoard(
                            candidateToAdd.id,
                            selectedBoard.id
                          );
                          if (exists) {
                            alert(
                              "This candidate already exists on this board."
                            );
                            return;
                          }
                          // Créer une description multi-ligne
                          const description =
                            `Name: ${candidateToAdd.first_name || "N/A"} ${
                              candidateToAdd.last_name || "N/A"
                            }\n` +
                            `Email: ${candidateToAdd.email || "N/A"}\n` +
                            `Phone: ${candidateToAdd.phone || "N/A"}\n` +
                            `LinkedIn: ${candidateToAdd.linkedin_url || "#"}`;

                          // Appel API pour ajouter la card
                          const response = await fetch(
                            `${API_URL}/v1/kanban/cards`,
                            {
                              method: "POST",
                              headers: { "Content-Type": "application/json" },
                              body: JSON.stringify({
                                title: "Candidate",
                                list_id: selectedList.id,
                                description,
                                position: selectedList.KanbanCards?.length || 1,
                                candidate_id: candidateToAdd.id,
                              }),
                              credentials: "include",
                            }
                          );

                          if (response.ok) {
                            toast.success("Candidate added successfully!")
                            handleCloseModal();
                          } else {
                            const errorResponse = await response.json();
                            toast.error(errorResponse.message ||
                              "An error occurred while adding the candidate.")
                            console.error(
                              "Error response from API:",
                              errorResponse
                            );
                          }
                        } catch (error) {
                          console.error("Error adding candidate:", error);
                          toast.error("An unexpected error occurred. Please try again.")
                        }
                      }}
                    >
                      {t("candidatePool.assignCandidate")}
                    </button>
                    {/* Button for add and redirect */}
                    <button
                      type="button"
                      className="add-and-redirect-candidate-button"
                      onClick={async () => {
                        console.log("Add and Redirect button clicked");
                        try {
                          if (!candidateToAdd) {
                            toast.error("No candidate selected.")
                            return;
                          }
                          if (
                            !selectedBoard ||
                            !Array.isArray(selectedBoard.KanbanLists)
                          ) {
                            toast.error("Selected board is invalid or has no lists.")
                            console.error(
                              "Invalid selected board:",
                              selectedBoard
                            );
                            return;
                          }
                          // checking if the candidate already exists on the board
                          const exists = isCandidateOnBoard(
                            candidateToAdd.id,
                            selectedBoard.id
                          );
                          console.log("Candidate exists on board:", exists);
                          if (exists) {
                            alert(
                              "This candidate already exists on this board."
                            );
                            return;
                          }
                          console.log("Candidate to add:", candidateToAdd);
                          console.log(
                            "Candidate LinkedIn:",
                            candidateToAdd.linkedin_url
                          );
                          console.log(
                            "Candidate Last Name:",
                            candidateToAdd.last_name
                          );
                          const description =
                            `Name: ${candidateToAdd.first_name || "N/A"} ${
                              candidateToAdd.last_name || "N/A"
                            }\n` +
                            `Email: ${candidateToAdd.email || "N/A"}\n` +
                            `Phone: ${candidateToAdd.phone || "N/A"}\n` +
                            `LinkedIn: ${candidateToAdd.linkedin_url || "#"}`;
                          // Call API to add candidate to board
                          const response = await fetch(
                            `${API_URL}/v1/kanban/cards`,
                            {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                title: "Candidate",
                                list_id: selectedList.id,
                                description,
                                position: selectedList.KanbanCards?.length || 1,
                                candidate_id: candidateToAdd.id,
                              }),
                              credentials: "include",
                            }
                          );
                          if (response.ok) {
                            const addedCard = await response.json(); // Récupère
                            toast.success("Candidate added and redirected!")
                            navigate(
                              `/b2b/dashboard/tracking-boards/${selectedList.board_id}?list=${selectedList.id}&card=${addedCard.id}`
                            );
                          } else {
                            const errorResponse = await response.json();
                            console.error(
                              "Error response from API:",
                              errorResponse
                            );

                            if (errorResponse.message) {
                              toast.error(errorResponse.message)
                            } else {
                              toast.error("An error occurred while adding the candidate.")
                            }
                          }
                        } catch (error) {
                          console.error("Error adding candidate:", error);
                          toast.error( "An unexpected error occurred. Please try again.")
                        }
                      }}
                    >
                      {t("candidatePool.assignAndRedirect")}
                    </button>
                  </>
                ) : (
                  <p> {t("candidatePool.selectCandidateAndList")}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CandidatePool;