import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import parse from "html-react-parser"; // librairy to parse html content
import * as sanitizeHtml from "sanitize-html"; // librairy to sanitize html content
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import { FaEdit, FaSave, FaUserPlus } from "react-icons/fa";
import "./AssessmentForm.scss";
import { API_URL } from "../../../../shared.js";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ASSESSMENT_TYPES = {
  LANGUAGE: 'Language',
  SOFT_SKILLS: 'Soft Skills',
  HARD_SKILLS: 'Hard Skills'
};

const determineAssessmentType = (parsedData) => {
  console.log("Determining assessment type for:", parsedData);

  // If we have a direct type property, use it
  if (parsedData.type) {
    console.log("Found direct type:", parsedData.type);
    return parsedData.type.toLowerCase();
  }

  // Check if the data is nested under 'assessment'
  const assessmentData = parsedData.assessment || parsedData;

  // First, check for language assessment
  if (assessmentData.type === 'language' || 
      assessmentData.grammarVocabularyQuestions || 
      assessmentData.readingComprehension) {
    console.log("Detected language assessment");
    return 'language';
  }

  // Check for technical/hard skills content
  const hasTechnicalContent = Boolean(
    assessmentData.challenges?.length > 0 ||
    assessmentData.sector === 'technology' ||
    (assessmentData.sections && assessmentData.sections.some(section => 
      section.title?.toLowerCase().includes('technique') ||
      section.title?.toLowerCase().includes('technical') ||
      section.challenges?.length > 0
    ))
  );

  console.log("Technical content check:", {
    hasTechnicalContent,
    challenges: assessmentData.challenges,
    sector: assessmentData.sector,
    sections: assessmentData.sections
  });

  if (hasTechnicalContent) {
    console.log("Detected hard skills assessment");
    return 'hard_skills';
  }

  // If no technical content, check for soft skills
  const hasSoftSkillsContent = Boolean(
    assessmentData.type === 'soft_skills' ||
    assessmentData.sections?.some(section => 
      section.title?.toLowerCase().includes('soft') ||
      section.title?.toLowerCase().includes('behavioral') ||
      section.title?.toLowerCase().includes('personality') ||
      section.questions?.some(q => q.skill) // Check if questions have skill property
    )
  );

  console.log("Soft skills content check:", {
    hasSoftSkillsContent,
    sections: assessmentData.sections
  });

  if (hasSoftSkillsContent) {
    console.log("Detected soft skills assessment");
    return 'soft_skills';
  }

  // Default to soft skills if we have sections with questions but no other indicators
  if (assessmentData.sections?.some(section => section.questions?.length > 0)) {
    console.log("Defaulting to soft skills assessment (has questions)");
    return 'soft_skills';
  }

  console.log("Could not determine assessment type");
  return null;
};

const AssessmentForm = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isTraining, setIsTraining] = useState(false);
  const [candidateContent, setCandidateContent] = useState({});
  const [email, setEmail] = useState("");
  const [candidateId, setCandidateId] = useState(null);
  const [assessmentId, setAssessmentId] = useState(null);
  const [title, setTitle] = useState("");
  const [isStarted, setIsStarted] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const { id: tokenId } = useParams();
  const [isLanguageAssessment, setIsLanguageAssessment] = useState(false);
  const [isSoftSkillsAssessment, setIsSoftSkillsAssessment] = useState(false);
  const [isHardSkillsAssessment, setIsHardSkillsAssessment] = useState(false);
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Debug useEffect for IDs
  useEffect(() => {
    console.log("ID States Updated:", {
      candidateId,
      assessmentId,
      timestamp: new Date().toISOString()
    });
  }, [candidateId, assessmentId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching assessment data with token:", tokenId);
        const response = await fetch(`${API_URL}/assessment/${tokenId}/content`, {
          method: "GET",
          headers: { 
            "Content-Type": "application/json",
            "Authorization": `Bearer ${tokenId}`
          },
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("Raw API response:", result);

        if (result?.test_content) {
          const parsedData = JSON.parse(result.test_content);
          console.log("Parsed test content:", parsedData);
          
          setData(parsedData);
          
          const assessmentType = determineAssessmentType(parsedData);
          console.log("Final determined assessment type:", assessmentType);

          setIsLanguageAssessment(assessmentType === 'language');
          setIsSoftSkillsAssessment(assessmentType === 'soft_skills');
          setIsHardSkillsAssessment(assessmentType === 'hard_skills');
          
          if (!assessmentType) {
            console.error("Could not determine assessment type:", parsedData);
            setError(t('assessment.errors.unknownType'));
          }
          
          // Set assessmentId from the correct location in the response
          if (result.id) {
            console.log("Setting assessmentId from result.id:", result.id);
            setAssessmentId(result.id);
          } else if (result.assessmentId) {
            console.log("Setting assessmentId from result.assessmentId:", result.assessmentId);
            setAssessmentId(result.assessmentId);
          } else if (parsedData.id) {
            console.log("Setting assessmentId from parsedData.id:", parsedData.id);
            setAssessmentId(parsedData.id);
          } else {
            // If no ID is found, use the token as a fallback
            console.log("No assessment ID found in response, using token as fallback:", tokenId);
            setAssessmentId(tokenId);
          }
        } else {
          console.error("Missing test_content in response:", result);
          setError(t('assessment.errors.missingContent', 'Assessment data is missing or incomplete'));
        }
      } catch (err) {
        console.error("Error in fetchData:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (tokenId) {
      fetchData();
    } else {
      console.error("No token ID available");
      setError(t('assessment.errors.missingToken', 'Missing assessment token'));
    }
  }, [tokenId, t]);

  // Add a debug effect to monitor assessmentId changes
  useEffect(() => {
    console.log("Assessment ID updated:", assessmentId);
  }, [assessmentId]);

  const getCandidateInfo = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      console.log("Getting candidate info for email:", email);
      const response = await fetch(`${API_URL}/assessment/candidate-info`, {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenId}`
        },
        body: JSON.stringify({ 
          email,
          token: tokenId
        }),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Candidate info received:", result);

      if (result.candidateId) {
        console.log("Setting candidateId:", result.candidateId);
        setCandidateId(result.candidateId);
        setIsStarted(true);
      } else {
        console.error("No candidateId in response");
        setError("Candidate not found");
      }
    } catch (err) {
      console.error("Error checking candidate:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveCandidateContent = async (e) => {
    e.preventDefault();
    console.log("Attempting to save assessment");

    if (!candidateId || !assessmentId) {
      alert(t('assessment.alerts.provideEmail'));
      return;
    }

    if (Object.keys(candidateContent).length === 0) {
      alert(t('assessment.alerts.answerQuestions'));
      return;
    }

    try {
      console.log("Saving assessment for:", { candidateId, assessmentId });

      // Determine the correct assessment type
      let assessmentType;
      if (isLanguageAssessment) {
        assessmentType = ASSESSMENT_TYPES.LANGUAGE;
      } else if (isSoftSkillsAssessment) {
        assessmentType = ASSESSMENT_TYPES.SOFT_SKILLS;
      } else {
        assessmentType = ASSESSMENT_TYPES.HARD_SKILLS;
      }

      const payload = {
        candidateId: candidateId,
        assessmentId: assessmentId,
        type: assessmentType, // Using the correct enum value
        answers: JSON.stringify(candidateContent),
        title: data?.title || ''
      };

      console.log("Submitting payload:", payload);

      const response = await fetch(`${API_URL}/assessment/candidate-content`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
        credentials: "include",
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.log('Error response:', errorText);
        throw new Error(errorText);
      }

      const result = await response.json();
      console.log("Save result:", result);

      if (result.success) {
        setShowSuccessModal(true);
      } else {
        alert(t('assessment.alerts.saveFailed'));
        console.error("Save error:", result.message);
      }
    } catch (error) {
      console.error("Save error:", error);
      alert(t('assessment.alerts.saveFailed'));
    }
  };

  const handleAnswerChange = (questionId, answer) => {
    setCandidateContent(prev => ({
      ...prev,
      [questionId]: answer
    }));
  };

  const renderLanguageAssessment = () => {
    if (!data) {
      console.log("No data available");
      return null;
    }

    return (
      <div className="questions-container">
        {/* Grammar and Vocabulary Section */}
        {Array.isArray(data.grammarVocabularyQuestions) && (
          <section className="assessment-section">
            <h2>
              {t('assessment.sections.grammarVocabulary.title')}
            </h2>
            {data.grammarVocabularyQuestions.map((question, index) => (
              <div key={`grammar-${index}`} className="question">
                <h3>Question {index + 1}</h3>
                <div className="question-content">
                  {question.question}
                </div>
                <div className="options">
                  {Array.isArray(question.options) && question.options.map((option, optIndex) => (
                    <label key={`grammar-${index}-option-${optIndex}`} className="option">
                      <input
                        type="radio"
                        name={`grammar-question-${index}`}
                        value={option.id || optIndex}
                        onChange={() => handleAnswerChange(`grammar-${question.id}`, option.id || optIndex)}
                        checked={candidateContent[`grammar-${question.id}`] === (option.id || optIndex)}
                      />
                      {option.text}
                    </label>
                  ))}
                </div>
              </div>
            ))}
          </section>
        )}

        {/* Reading Comprehension Section */}
        {data.readingComprehension && (
          <section className="assessment-section">
            <h2>
              {t('assessment.sections.readingComprehension.title')}
            </h2>
            <div className="passage">
              {data.readingComprehension.passage}
            </div>
            {Array.isArray(data.readingComprehension.questions) && 
              data.readingComprehension.questions.map((question, index) => (
                <div key={`reading-${index}`} className="question">
                  <h3>Question {index + 1}</h3>
                  <div className="question-content">
                    {question.question}
                  </div>
                  <div className="options">
                    {Array.isArray(question.options) && question.options.map((option, optIndex) => (
                      <label key={`reading-${index}-option-${optIndex}`} className="option">
                        <input
                          type="radio"
                          name={`reading-question-${index}`}
                          value={option.id || optIndex}
                          onChange={() => handleAnswerChange(`reading-${question.id}`, option.id || optIndex)}
                          checked={candidateContent[`reading-${question.id}`] === (option.id || optIndex)}
                        />
                        {option.text}
                      </label>
                    ))}
                  </div>
                </div>
              ))}
          </section>
        )}

        {/* Article Writing Section */}
        {data.articleWriting && (
          <section className="assessment-section">
            <h2>
              {t('assessment.sections.articleWriting.title')}
            </h2>
            <div className="writing-task">
              <p>{data.articleWriting.task}</p>
              <p className="word-limit">
                {t('assessment.sections.articleWriting.wordLimit')}: {data.articleWriting.wordLimit}
              </p>
              <textarea
                className="article-textarea"
                onChange={(e) => handleAnswerChange('article', e.target.value)}
                value={candidateContent['article'] || ''}
                placeholder={t('assessment.sections.articleWriting.placeholder')}
                rows={10}
              />
            </div>
          </section>
        )}

        {/* Scenario Based Task */}
        {data.scenarioBasedTask && (
          <section className="assessment-section">
            <h2>
              {t('assessment.sections.scenarioTask.title')}
            </h2>
            <div className="scenario-task">
              <p>{data.scenarioBasedTask.scenario}</p>
              <textarea
                className="scenario-textarea"
                onChange={(e) => handleAnswerChange('scenario', e.target.value)}
                value={candidateContent['scenario'] || ''}
                placeholder={t('assessment.sections.scenarioTask.placeholder')}
                rows={10}
              />
            </div>
          </section>
        )}

        {/* Add Submit Button at the bottom */}
        <div className="submit-section">
          <button 
            type="button"
            className="submit-button"
            onClick={handleSaveCandidateContent}
            disabled={loading || !candidateId || Object.keys(candidateContent).length === 0}
          >
            {loading ? (
              <ClipLoader color="#ffffff" size={20} />
            ) : (
              <>
                <FaSave className="icon" />
                {t('assessment.submit', 'Submit Assessment')}
              </>
            )}
          </button>
        </div>
      </div>
    );
  };

  const renderSoftSkillsAssessment = () => {
    return (
      <div className="assessment-content">
        {data?.assessment && (
          <>
            {/* Introduction Section */}
            {data.assessment.introduction && (
              <section className="introduction-section">
                <h2 className="section-title">{t("Introduction")}</h2>
                <div className="introduction-content">
                  {data.assessment.introduction.purpose && (
                    <p className="purpose">
                      <strong>{t("Purpose")}:</strong> {data.assessment.introduction.purpose}
                    </p>
                  )}
                  {data.assessment.introduction.timeToComplete && (
                    <p className="time-estimate">
                      <strong>{t("Time to Complete")}:</strong> {data.assessment.introduction.timeToComplete}
                    </p>
                  )}
                  {data.assessment.introduction.skillsEvaluated && (
                    <div className="skills-list">
                      <h3>{t("Skills Evaluated")}</h3>
                      <div className="skills-tags">
                        {data.assessment.introduction.skillsEvaluated.map((skill, index) => (
                          <span key={index} className="skill-badge">
                            {typeof skill === 'string' ? skill : skill.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </section>
            )}

            {/* Questions Sections */}
            {data.assessment.sections && data.assessment.sections.map((section, sectionIndex) => (
              <section key={sectionIndex} className="questions-section">
                <h2 className="section-title">{section.title}</h2>
                {section.description && <p className="section-description">{section.description}</p>}
                
                <div className="questions-list">
                  {section.questions && section.questions.map((question, questionIndex) => (
                    <div key={questionIndex} className="question-card">
                      <div className="question-header">
                        <h3 className="question-number">Question {questionIndex + 1}</h3>
                        {question.skill && <span className="skill-tag">{question.skill}</span>}
                      </div>
                      
                      <p className="question-text">{question.question}</p>
                      
                      {question.options && (
                        <div className="options-grid">
                          {question.options.map((option, optionIndex) => (
                            <label key={optionIndex} className="option-item">
                              <input 
                                type="radio" 
                                name={`question-${sectionIndex}-${questionIndex}`}
                                value={option.id}
                                onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                                className="option-radio"
                                required
                              />
                              <span className="option-content">
                                <span className="option-text">{option.text}</span>
                              </span>
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </section>
            ))}

            {/* Closing Message */}
            {data.assessment.closingMessage && (
              <section className="closing-section">
                <p className="closing-message">{data.assessment.closingMessage}</p>
              </section>
            )}

            {/* Add submit button at the bottom */}
            <div className="submit-section">
              <button 
                type="submit" 
                className="submit-button"
                disabled={loading}
              >
                {loading ? (
                  <ClipLoader color="#ffffff" size={20} />
                ) : (
                  t('assessment.submit', 'Submit Assessment')
                )}
              </button>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderHardSkillsAssessment = () => {
    console.log("Rendering hard skills assessment with data:", data);

    if (!data?.assessment?.sections) {
      console.error("No sections found to render in hard skills assessment:", data);
      return <div className="error-message">{t('assessment.errors.noContent')}</div>;
    }

    return (
      <div className="assessment-page">
        <div className="questions-container">
          {/* Assessment Header */}
          <div className="assessment-section">
            <h2>{data.assessment.title}</h2>
            <p className="question-content">{data.assessment.purpose}</p>
            <p className="question-content">{t('assessment.timeLimit', { time: data.assessment.time })}</p>
          </div>

          {/* Render each section */}
          {data.assessment.sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="assessment-section">
              <h2 className="section-title">{section.title}</h2>

              {/* Technical Questions */}
              {section.questions && section.questions.map((question, questionIndex) => (
                <div key={questionIndex} className="question">
                  <h3>{question.question}</h3>
                  <div className="options">
                    {question.options.map((option, optionIndex) => (
                      <label key={optionIndex} className="option">
                        <input
                          type="radio"
                          name={`question-${sectionIndex}-${questionIndex}`}
                          value={option.id || optionIndex}
                          onChange={() => handleAnswerChange(question.id, option.id)}
                          checked={candidateContent[question.id] === option.id}
                        />
                        <span>{option.text}</span>
                      </label>
                    ))}
                  </div>
                </div>
              ))}

              {/* Programming Challenges */}
              {section.challenges && section.challenges.map((challenge, challengeIndex) => (
                <div key={challengeIndex} className="question">
                  <h3>{challenge.title}</h3>
                  <div className="question-content">
                    <p>{challenge.description}</p>
                    {challenge.requirements && (
                      <div className="passage">
                        <h4>{t('assessment.requirements')}</h4>
                        <ul>
                          {challenge.requirements.map((req, reqIndex) => (
                            <li key={reqIndex}>{req}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <textarea
                    className="code-textarea"
                    placeholder={t('assessment.enterSolution')}
                    value={candidateContent[challenge.id] || ''}
                    onChange={(e) => handleAnswerChange(challenge.id, e.target.value)}
                  />
                </div>
              ))}
            </div>
          ))}

          {/* Closing Message */}
          <div className="assessment-section">
            <p className="question-content">{data.assessment.closingMessage}</p>
          </div>

          {/* Submit Button */}
          <div className="submit-section">
            <button 
              className="submit-button" 
              onClick={handleSaveCandidateContent}
              type="button"
            >
              {t('assessment.submit')}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderAssessmentContent = () => {
    console.log("Rendering assessment content with types:", {
      isLanguageAssessment,
      isSoftSkillsAssessment,
      isHardSkillsAssessment,
      data: data
    });

    if (!data) {
      return <div className="error-message">{t('assessment.errors.noData')}</div>;
    }

    // Add assessment header for all types
    const renderAssessmentHeader = () => (
      <div className="assessment-header">
        <h2>{data.assessment?.title || data?.title}</h2>
        <p className="assessment-description">
          {t('assessment.description', {
            type: isLanguageAssessment 
              ? t('assessment.types.language')
              : isSoftSkillsAssessment 
              ? t('assessment.types.softSkills')
              : t('assessment.types.hardSkills'),
            position: data.assessment?.position || data?.position,
            level: data.assessment?.level || data?.level
          })}
        </p>
        <p className="time-limit">
          {t('assessment.timeLimit', { 
            time: data.assessment?.timeLimit || data?.timeLimit || 90 
          })}
        </p>
      </div>
    );

    return (
      <div className="assessment-content">
        {renderAssessmentHeader()}
        {isLanguageAssessment && renderLanguageAssessment()}
        {isHardSkillsAssessment && renderHardSkillsAssessment()}
        {isSoftSkillsAssessment && renderSoftSkillsAssessment()}
      </div>
    );
  };

  useEffect(() => {
    console.log("State updated:", {
      isStarted,
      candidateId,
      assessmentId,
      candidateContent
    });
  }, [isStarted, candidateId, assessmentId, candidateContent]);

  // Add success modal component
  const SuccessModal = () => (
    <div className="success-modal">
      <div className="modal-content">
        <h2>{t('assessment.success.title')}</h2>
        <p>{t('assessment.success.message')}</p>
        <button 
          className="close-button"
          onClick={() => window.close()}
        >
          {t('assessment.success.close')}
        </button>
      </div>
    </div>
  );

  return (
    <div className="assessment-page">
      {loading ? (
        <div className="loading-spinner">
          <ClipLoader color="#6920b0" size={60} />
        </div>
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : !isStarted ? (
        <div className="start-section">
          <h1>{data?.assessment?.title || data?.title || t('assessment.title')}</h1>
          <p className="email-instruction">
            {t('assessment.emailInstruction')}
          </p>
          <form onSubmit={getCandidateInfo} className="email-form">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('assessment.enterEmail')}
              required
            />
            <button type="submit" className="start-button">
              {t('assessment.startButton')}
            </button>
          </form>
        </div>
      ) : (
        <>
          <form onSubmit={handleSaveCandidateContent}>
            {renderAssessmentContent()}
          </form>
          {showSuccessModal && <SuccessModal />}
        </>
      )}
    </div>
  );
};

export default AssessmentForm;
