import { API_URL } from '../shared';

export const getAllJobOffers = async () => {
  const response = await fetch(`${API_URL}/job-offers`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch job offers');
  }
  return response.json();
};

export const getJobBoard = async () => {
  try {
    const response = await fetch(`${API_URL}/job-board`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch job board');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching job board:', error);
    throw error;
  }
};

export const getSearchJobs = async (params) => {
  try {
    // Convert params URLSearchParams object to string and ensure proper encoding
    const searchParams = new URLSearchParams();
    
    if (params.get('query')) {
      searchParams.append('query', params.get('query'));
    }
    if (params.get('contractType')) {
      searchParams.append('contractType', params.get('contractType'));
    }
    if (params.get('city')) {
      searchParams.append('city', params.get('city'));
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/api/search-jobs?${searchParams.toString()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to search job offers');
    }

    return await response.json();
  } catch (error) {
    console.error('Error searching jobs:', error);
    throw error;
  }
};

export const getJobOfferDetails = async (id) => {
  try {
    const response = await fetch(`${API_URL}/job-offers-p/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch job details');
    }
    console.log('response dans jobOfferservice', response);  
    return await response.json();
  } catch (error) {
    console.error('Error fetching job details:', error);
    throw error;
  }
};


export const updateJobDetails = async (jobData) => {
  if (!jobData || !jobData.id) {
    throw new Error("Les données du job doivent contenir un 'id'.");
  }

  try {
    const response = await fetch(`${API_URL}/job-offers/${jobData.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(jobData)
    });

    if (!response.ok) {
      throw new Error('Failed to update job details');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating job details:', error);
    throw error;
  }
};
