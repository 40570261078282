import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from '../../common/Spinner/Spinner';
import './JobOpeningAIGenerator.scss';
import { API_URL } from '../../../shared';
import { FaLanguage } from 'react-icons/fa';

const LANGUAGES = [
  { code: 'en', name: 'English' },
  { code: 'fr', name: 'Français' },
  { code: 'es', name: 'Español' },
  { code: 'it', name: 'Italiano' },
  { code: 'de', name: 'Deutsch' },
  { code: 'pt', name: 'Português' }
];

const JobOpeningAIGenerator = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [hiringRequests, setHiringRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [generatedJobOffer, setGeneratedJobOffer] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language.split('-')[0] || 'en');

  useEffect(() => {
    fetchHiringRequests();
  }, []);

  const fetchHiringRequests = async () => {
    try {
      const response = await fetch(`${API_URL}/hiring-requests`, {
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Failed to fetch hiring requests');
      const data = await response.json();
      console.log('Fetched hiring requests:', data);
      setHiringRequests(data || []);
    } catch (error) {
      console.error('Error fetching hiring requests:', error);
      setError(error.message);
      setHiringRequests([]);
    }
  };

  const handleRequestSelect = (request) => {
    console.log('Selected request:', request);
    setSelectedRequest(request);
  };

  const handleGenerateJobOffer = async () => {
    if (!selectedRequest) return;
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}/generate-job-offer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ hiringRequestId: selectedRequest.id })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to generate job offer');
      }
      
      const generatedOffer = await response.json();
      console.log('Generated offer from AI:', generatedOffer);
      setGeneratedJobOffer(generatedOffer);
      setIsEditing(true);
    } catch (error) {
      console.error('Error in handleGenerateJobOffer:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveJobOffer = async () => {
    try {
      // Helper function to convert various "yes" translations to boolean
      const convertToBoolean = (value) => {
        if (!value) return false;
        const positiveResponses = ['yes', 'oui', 'si', 'sim', 'ja', 'sì'];
        return positiveResponses.includes(value.toLowerCase().trim());
      };

      // Format the data before sending
      const formattedJobOffer = {
        ...generatedJobOffer,
        is_ai_generated: true,
        source: 'JobOpeningAIGenerator',
        // Ensure these fields are properly formatted
        min_salary: Number(generatedJobOffer.min_salary) || 0,
        max_salary: Number(generatedJobOffer.max_salary) || 0,
        // Convert work type options to boolean using the helper function
        is_remote: convertToBoolean(generatedJobOffer.is_remote),
        is_hybrid: convertToBoolean(generatedJobOffer.is_hybrid),
        onsite: convertToBoolean(generatedJobOffer.onsite),
        // Ensure required fields are present
        status: 'draft',
        tenant_id: localStorage.getItem('tenant_id'),
        user_id: localStorage.getItem('user_id'),
        created_at: new Date(),
        updated_at: new Date()
      };

      const response = await fetch(`${API_URL}/job-offers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(formattedJobOffer)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to save job offer');
      }

      // Navigate to job openings page after successful save
      navigate('/b2b/dashboard/job-openings');
    } catch (error) {
      console.error('Error saving job offer:', error);
      setError(error.message);
    }
  };

  const handleEditField = (field, value) => {
    setGeneratedJobOffer(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const formatSkills = (skills) => {
    if (!skills) return [];
    if (Array.isArray(skills)) return skills;
    try {
      return JSON.parse(skills);
    } catch (e) {
      return typeof skills === 'string' ? [skills] : [];
    }
  };

  const renderEditForm = () => {
    if (!generatedJobOffer) return null;

    return (
      <div className="edit-form">
        <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.role')}</label>
          <input
            type="text"
            value={generatedJobOffer.role || ''}
            onChange={(e) => handleEditField('role', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.companyName')}</label>
          <input
            type="text"
            value={generatedJobOffer.company_name || ''}
            onChange={(e) => handleEditField('company_name', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.companyDescription')}</label>
          <textarea
            value={generatedJobOffer.company_description || ''}
            onChange={(e) => handleEditField('company_description', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.costCenter')}</label>
          <input
            type="text"
            value={generatedJobOffer.cost_center || ''}
            onChange={(e) => handleEditField('cost_center', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.contractType')}</label>
          <input
            type="text"
            value={generatedJobOffer.contract_type || ''}
            onChange={(e) => handleEditField('contract_type', e.target.value)}
            placeholder={t('jobOpeningGenerator.fields.contractTypePlaceholder')}
          />
        </div>

        <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.employmentType')}</label>
          <input
            type="text"
            value={generatedJobOffer.employment_type || ''}
            onChange={(e) => handleEditField('employment_type', e.target.value)}
            placeholder={t('jobOpeningGenerator.fields.employmentTypePlaceholder')}
          />
        </div>

        <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.mission')}</label>
          <textarea
            value={generatedJobOffer.mission || ''}
            onChange={(e) => handleEditField('mission', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.responsibilities')}</label>
          <textarea
            value={generatedJobOffer.responsibilities || ''}
            onChange={(e) => handleEditField('responsibilities', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.requirements')}</label>
          <textarea
            value={generatedJobOffer.requirements || ''}
            onChange={(e) => handleEditField('requirements', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.minYearExperience')}</label>
          <input
            type="number"
            value={generatedJobOffer.min_year_experience || ''}
            onChange={(e) => handleEditField('min_year_experience', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.roleLevel')}</label>
          <input
            type="text"
            value={generatedJobOffer.role_level || ''}
            onChange={(e) => handleEditField('role_level', e.target.value)}
            placeholder={t('jobOpeningGenerator.fields.roleLevelPlaceholder')}
          />
        </div>

        <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.skills')}</label>
          <div className="skills-display">
            {generatedJobOffer.skills?.map((skill, index) => (
              <span key={index} className="skill-tag">
                {skill}
                <button 
                  onClick={() => {
                    const newSkills = generatedJobOffer.skills.filter((_, i) => i !== index);
                    handleEditField('skills', newSkills);
                  }}
                >
                  ×
                </button>
              </span>
            ))}
          </div>
          <input
            type="text"
            placeholder={t('jobOpeningGenerator.fields.addSkill')}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                const newSkill = e.target.value.trim();
                if (newSkill) {
                  handleEditField('skills', [...(generatedJobOffer.skills || []), newSkill]);
                  e.target.value = '';
                }
              }
            }}
          />
        </div>

        <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.city')}</label>
          <input
            type="text"
            value={generatedJobOffer.city || ''}
            onChange={(e) => handleEditField('city', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.country')}</label>
          <input
            type="text"
            value={generatedJobOffer.country || ''}
            onChange={(e) => handleEditField('country', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.workType')}</label>
          <div className="work-type-inputs">
            <div className="work-type-item">
              <span>{t('jobOpeningGenerator.fields.remote')}:</span>
              <input
                type="text"
                value={generatedJobOffer.is_remote || ''}
                onChange={(e) => handleEditField('is_remote', e.target.value)}
                placeholder={t('jobOpeningGenerator.fields.yesNoPlaceholder')}
              />
            </div>
            <div className="work-type-item">
              <span>{t('jobOpeningGenerator.fields.hybrid')}:</span>
              <input
                type="text"
                value={generatedJobOffer.is_hybrid || ''}
                onChange={(e) => handleEditField('is_hybrid', e.target.value)}
                placeholder={t('jobOpeningGenerator.fields.yesNoPlaceholder')}
              />
            </div>
            <div className="work-type-item">
              <span>{t('jobOpeningGenerator.fields.onsite')}:</span>
              <input
                type="text"
                value={generatedJobOffer.onsite || ''}
                onChange={(e) => handleEditField('onsite', e.target.value)}
                placeholder={t('jobOpeningGenerator.fields.yesNoPlaceholder')}
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.salary')}</label>
          <div className="salary-inputs">
            <input
              type="number"
              value={generatedJobOffer.min_salary || ''}
              onChange={(e) => handleEditField('min_salary', e.target.value)}
              placeholder={t('jobOpeningGenerator.fields.minSalary')}
            />
            <input
              type="number"
              value={generatedJobOffer.max_salary || ''}
              onChange={(e) => handleEditField('max_salary', e.target.value)}
              placeholder={t('jobOpeningGenerator.fields.maxSalary')}
            />
          </div>
        </div>

        <div className="form-group hiring-process">
          <label>{t('jobOpeningGenerator.fields.hiringProcess')}</label>
          <textarea
            value={generatedJobOffer.hiring_process || ''}
            onChange={(e) => handleEditField('hiring_process', e.target.value)}
          />
        </div>

        {/* <div className="form-group">
          <label>{t('jobOpeningGenerator.fields.tone')}</label>
          <select
            value={generatedJobOffer.tone || ''}
            onChange={(e) => handleEditField('tone', e.target.value)}
          >
            <option value="">{t('jobOpeningGenerator.fields.selectOption')}</option>
            <option value="professional">{t('jobOpeningGenerator.fields.professional')}</option>
            <option value="casual">{t('jobOpeningGenerator.fields.casual')}</option>
            <option value="friendly">{t('jobOpeningGenerator.fields.friendly')}</option>
          </select>
        </div> */}
      </div>
    );
  };

  const handleTranslate = async (targetLanguage) => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await fetch(`${API_URL}/translate-generated-job-offer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        credentials: 'include',
        body: JSON.stringify({
          jobOffer: generatedJobOffer,
          targetLanguage
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to translate job offer');
      }

      const translatedOffer = await response.json();
      setGeneratedJobOffer(translatedOffer);
      setShowLanguageSelector(false);
    } catch (err) {
      console.error('Translation error:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="job-opening-ai-generator">
      {isLoading && <Spinner />}
      
      <h2>{t('jobOpeningGenerator.title')}</h2>
      
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      {!isEditing ? (
        <div className="hiring-requests-list">
          <h3>{t('jobOpeningGenerator.selectRequest')}</h3>
          {hiringRequests.map((request) => (
            <div
              key={request.id}
              className={`hiring-request ${selectedRequest?.id === request.id ? 'selected' : ''}`}
              onClick={() => handleRequestSelect(request)}
            >
              <h4>{request.role || request.jobtitle || t('jobOpeningGenerator.untitledJob')}</h4>
              <p>{request.department || t('jobOpeningGenerator.noDepartment')}</p>
              <p>{t('jobOpeningGenerator.requestedBy')}: {request.first_name} {request.last_name}</p>
            </div>
          ))}
          
          <div className="actions">
            <button
              className="job-opening-ai-generator-button"
              onClick={handleGenerateJobOffer}
              disabled={!selectedRequest || isLoading}
            >
              {t('jobOpeningGenerator.generateButton')}
            </button>
          </div>
        </div>
      ) : (
        <div className="generated-job-offer">
          <div className="preview-header">
            <h2>{t("jobOpeningGenerator.previewOffer.title")}</h2>
            <div className="language-controls">
              <button
                className="language-button"
                onClick={() => setShowLanguageSelector(!showLanguageSelector)}
              >
                <FaLanguage /> {LANGUAGES.find(lang => lang.code === selectedLanguage)?.name}
              </button>
              {showLanguageSelector && (
                <div className="language-dropdown">
                  {LANGUAGES.map((language) => (
                    <button
                      key={language.code}
                      className={`language-option ${selectedLanguage === language.code ? 'active' : ''}`}
                      onClick={() => {
                        setSelectedLanguage(language.code);
                        handleTranslate(language.code);
                      }}
                    >
                      {language.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
          {renderEditForm()}
          <div className="action-buttons">
            <button 
              className="back-button" 
              onClick={() => setIsEditing(false)}
              disabled={isLoading}
            >
              {t('jobOpeningGenerator.back')}
            </button>
            <button 
              className="save-button" 
              onClick={handleSaveJobOffer}
              disabled={isLoading}
            >
              {t('jobOpeningGenerator.save')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobOpeningAIGenerator; 