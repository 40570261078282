import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import parse from "html-react-parser";
import { FaEdit, FaSave, FaUserPlus } from "react-icons/fa";
import "./AssesmentDetailPageLanguageSkills.scss";
import { API_URL } from "../../../../shared.js";
import SendAssessmentModal from "./composants/SendAssessmentModal.jsx";
import AnalysisLanguage from "./composants/AnalysisLanguage.jsx";


const AssesmentDetailPageLanguageSkills = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isInvitation, setIsInvitation] = useState(false);

  const sectionTitles = {
    grammarVocab: t('assessment.grammarVocab', 'Grammar & Vocabulary'),
    articleWriting: t('assessment.articleWriting', 'Article Writing'),
    readingComp: t('assessment.readingComp', 'Reading Comprehension'),
    scenarioTask: t('assessment.scenarioTask', 'Scenario-Based Task'),
    task: t('assessment.task', 'Task'),
    wordLimit: t('assessment.wordLimit', 'Word Limit'),
    yourResponse: t('assessment.yourResponse', 'Your Response'),
    scenario: t('assessment.scenario', 'Scenario'),
    writeArticle: t('assessment.writeArticle', 'Write your article here...'),
    writeResponse: t('assessment.writeResponse', 'Write your response here...'),
    languageSkillsAssessment: t('assessment.title', 'Language Skills Assessment'),
    invite: t('assessment.invite', 'Invite')
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${API_URL}/assessment/${id}/language-skills`;
        console.log("Fetching from URL:", url);
        
        const response = await fetch(url, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });

        if (!response.ok) {
          console.error("Response not OK:", {
            status: response.status,
            statusText: response.statusText
          });
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("API Response:", result);

        if (result.success && result.data) {
          console.log("Setting data:", result.data);
          setData(result.data);
        } else {
          console.error("Invalid response structure:", result);
          setError(result.message || "Assessment content is missing or incomplete");
        }
      } catch (error) {
        console.error("Error fetching assessment:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const handleInviteModalOpen = () => {
    setIsInvitation(true);
  };

  const handleInviteModalClose = () => {
    setIsInvitation(false);
  };

  return (
    <>
      {isInvitation && (
        <div className="modal-overlay" onClick={handleInviteModalClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {<SendAssessmentModal onClose={handleInviteModalClose} />}
          </div>
        </div>
      )}

      <div className="assessment-page">
        <div className="assessment-header">
          <div className="header-top">
            <h1>{sectionTitles.languageSkillsAssessment}</h1>
            <button
              type="button"
              className="invite-btn"
              onClick={handleInviteModalOpen}
            >
              <FaUserPlus className="icon" /> {sectionTitles.invite}
            </button>
          </div>
        </div>

        <div className="content-section">
          {loading && (
            <div className="loading-spinner">
              <ClipLoader color="#6920b0" size={60} />
            </div>
          )}
          {error && <p className="error">Error: {error}</p>}

          {data && (
            <div className="section">
              <div className="question-block">
                <h2>{sectionTitles.grammarVocab}</h2>
                {data.grammarVocabularyQuestions?.map((q, index) => (
                  <div key={index} className="answers">
                    <h3>{q.question}</h3>
                    {q.options?.map((opt, optIndex) => (
                      <div key={optIndex} className="answer">
                        <input 
                          type="radio" 
                          id={`q${index}-${optIndex}`}
                          name={`q${index}`} 
                          value={opt.id} 
                        />
                        <label htmlFor={`q${index}-${optIndex}`}>
                          {String.fromCharCode(97 + optIndex)}) {opt.text}
                        </label>
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              {data.articleWriting && (
                <div className="question-block">
                  <h2>{sectionTitles.articleWriting}</h2>
                  <div className="challenge-block">
                    <h3>{sectionTitles.task}</h3>
                    <p className="challenge-text">{data.articleWriting.task}</p>
                    <p className="challenge-text">{sectionTitles.wordLimit}: {data.articleWriting.wordLimit}</p>
                    <div className="code-editor">
                      <div className="code-header">
                        <span>{sectionTitles.yourResponse}</span>
                      </div>
                      <textarea 
                        className="code-textarea"
                        placeholder={sectionTitles.writeArticle}
                        rows="8"
                      />
                    </div>
                  </div>
                </div>
              )}

              {data.readingComprehension && (
                <div className="question-block">
                  <h2>{sectionTitles.readingComp}</h2>
                  <div className="challenge-text">
                    {data.readingComprehension.passage}
                  </div>
                  {data.readingComprehension.questions?.map((q, index) => (
                    <div key={index} className="answers">
                      <h3>{q.question}</h3>
                      {q.options?.map((opt, optIndex) => (
                        <div key={optIndex} className="answer">
                          <input 
                            type="radio" 
                            id={`rc${index}-${optIndex}`}
                            name={`rc${index}`} 
                            value={opt.id} 
                          />
                          <label htmlFor={`rc${index}-${optIndex}`}>
                            {String.fromCharCode(97 + optIndex)}) {opt.text}
                          </label>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}

              {data.scenarioBasedTask && (
                <div className="question-block">
                  <h2>{sectionTitles.scenarioTask}</h2>
                  <div className="challenge-block">
                    <h3>{sectionTitles.scenario}</h3>
                    <p className="challenge-text">{data.scenarioBasedTask.scenario}</p>
                    <div className="code-editor">
                      <div className="code-header">
                        <span>{sectionTitles.yourResponse}</span>
                      </div>
                      <textarea 
                        className="code-textarea"
                        placeholder={sectionTitles.writeResponse}
                        rows="8"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AssesmentDetailPageLanguageSkills;
