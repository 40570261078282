import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../shared";
import { MdAddCircle, MdCancel, MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";


const AdminDashboardCompaniesListing = () => {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [id, setId] = useState(null);
  const [newCompany, setNewCompany] = useState({
    companyName: "",
    description: "",
    industry: "",
    website: "",
    ownerType: "",
  });

   const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setNewCompany({
      companyName: "",
      description: "",
      industry: "",
      website: "",
      ownerType: "",
    });
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCompany({ ...newCompany, [name]: value });
  };

    const handleSubmit = async (e) => {
      e.preventDefault();
      let path = isEditing ? `${API_URL}/admin/company/${id}` : `${API_URL}/admin/company`;    
      try {
            const response = await fetch(path, {
              method: isEditing ? "PUT" : "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newCompany),
              credentials: "include",
            });
      
            if (!response.ok) {
              const errorData = await response.json();
              toast.error( errorData.message || "Error Registering Company")
              return;
            
            }
      
            const data = await response.json();
      
            if (data.success) {
              const message = isEditing ? "Company updated successfully." : "Company created successfully.";
              toast.success(message)
            } else {
              toast.error(data.message)
            }
          } catch (error) {
            console.log("Error creating company:", error);
            console.log(error.message);
            toast.error("Error creating company")
          }
          fetchCompanies();
          handleModalClose();  
    };

  const fetchCompanies = async () => {
    try {
      const response = await fetch(`${API_URL}/admin/company`, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setCompanies(data);
      } else {
        throw new Error("Failed to fetch Companies");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);


  const handleDelete = async (id) => {
    try {
         const response = await fetch(`${API_URL}/admin/company/${id}`, {
           method: "DELETE",
           credentials: "include",
           headers: {
             "Content-Type": "application/json",
           }
         });
      
         if (!response.ok) {
           toast.error("Failed to delete company.")
           throw new Error(`Failed to delete company: ${response.status}`);
         }
      
         const data = await response.json();
         if (data.success) {
           toast.success("Company deleted successfully.")
           fetchUsers(); 
         }
      
       } catch (error) {
         console.error("Delete error:", error);
       }
  }
  

  const handleEdit = (company) => {
    setId(company.id);
    setNewCompany({
      companyName: company.name,
      description: company.description,
      industry: company.industry,
      website: company.website,
      ownerType: company.owner_type,
    });
    setIsEditing(true);
    setIsModalOpen(true);
  };
  return (
    <>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="modal-close p-3 m-5" onClick={handleModalClose}>
              <MdCancel />
            </button>
            <div className="modal-form">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Company Name</label>
                  <input
                    type="text"
                    id="name"
                    name="companyName"
                    value={newCompany.companyName}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <input
                    type="description"
                    id="description"
                    name="description"
                    value={newCompany.description}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="ownerType">Owner Type</label>
                  <select
                    id="ownerType"
                    name="ownerType"
                    value={newCompany.ownerType}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Company Type</option>
                    <option value="hrnovai">HrNovai</option>
                    <option value="reseller">Reseller</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="industry">Industry</label>
                  <input
                    type="text"
                    id="industry"
                    name="industry"
                    value={newCompany.industry}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="website">Website</label>
                  <input
                    type="text"
                    id="website"
                    name="website"
                    value={newCompany.website}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <button type="submit">
                  {isEditing ? "Update Company" : "Add Company"}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Company Listing */}
      <div className="list">
        <div className="top-header">
          <h2>Companies</h2>
          <button onClick={handleModalOpen} style={{ marginLeft: "5px" }}>
            Add Company <MdAddCircle className="add-icon-padding" />
          </button>
        </div>
        <table style={{ marginTop: "10px" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Company Name</th>
              <th>Description</th>
              <th>Industry</th>
              <th>Website</th>
              <th>Owner Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((company, index) => (
              <tr key={company.id}>
                <td>{index + 1}</td>
                <td>{company.name}</td>
                <td>{company.description}</td>
                <td>{company.industry}</td>
                <td>{company.website}</td>
                <td>{company.owner_type}</td>
                <td>
                  <div>
                    <button
                      className="trash-icon"
                      onClick={() => {
                        if (
                          window.confirm(
                            t("Are you sure you want to delete this company?")
                          )
                        ) {
                          handleDelete(company.id);
                        }
                      }}
                    >
                      <MdDelete className="trash-icon" />
                    </button>

                    <button
                      onClick={() => handleEdit(company)}
                      className="edit-icon"
                    >
                      <FaEdit className="edit-icon" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AdminDashboardCompaniesListing;
