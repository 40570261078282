import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./HiringRequestList.scss";
import { MdAddCircle, MdAutoAwesome } from "react-icons/md";
import { API_URL } from "../../../shared";
import { toast } from "react-toastify";

const HiringRequestList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hiringRequests, setHiringRequests] = useState([]);
  const [isInvitation, setisInvitation] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    fetchHiringRequests();
  }, []);

  const handleInviteModalOpen = () => {
    setisInvitation(true);
  };

  const handleInviteModalClose = () => {
    setisInvitation(false);
    setEmail(""); // Reset email input on modal close
  };

  const fetchHiringRequests = async () => {
    try {
      const response = await fetch(`${API_URL}/hiring-requests`, { credentials: "include" });
      if (response.ok) {
        const data = await response.json();
        setHiringRequests(data);
      } else {
        throw new Error("Failed to fetch hiring requests");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleInviteSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${API_URL}/hiring-request-form-invitation`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.success) {
        toast.success("Invitation sent successfully.");
        setisInvitation(false);
        setEmail("");
      } else {
        toast.error("Error sending inviation:", result.message);
      }
    } catch (error) {
      console.error("Error sending inviation:", error);
      toast.error("Error sending inviation:", error);

    }
  };

  return (
    <>
      {isInvitation && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button
              className="modal-close p-3 m-5"
              onClick={handleInviteModalClose}>
              ×
            </button>
            <div className="create-board-form">
              <form onSubmit={handleInviteSubmit}>
                <label htmlFor="email">{t('hiringRequests.invite.label')}</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t('hiringRequests.invite.placeholder')}
                  required
                />
                <button type="submit">{t('hiringRequests.invite.submit')}</button>
              </form>
            </div>
          </div>
        </div>
      )}

      <div className="hiring-request-list">
        <div className="request-title-btn">
          <h2>{t('hiringRequests.title')}</h2>
          <div className="button-group">
            <button 
              className="generate-button"
              onClick={() => navigate('/b2b/dashboard/generate-job-opening')}
            >
              {t('hiringRequests.generate')} <MdAutoAwesome />
            </button>
            <button onClick={handleInviteModalOpen}>
              {t('hiringRequests.invite.button')} <MdAddCircle />
            </button>
          </div>
        </div>
        <table style={{ marginTop:"10px"}}>
          <thead>
            <tr>
              <th>{t('hiringRequests.table.jobTitle')}</th>
              <th>{t('hiringRequests.table.role')}</th>
              <th>{t('hiringRequests.table.requester')}</th>
              <th>{t('hiringRequests.table.department')}</th>
              <th>{t('hiringRequests.table.submissionDate')}</th>
              <th>{t('hiringRequests.table.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {hiringRequests.map((request) => (
              <tr key={request.id}>
                <td>{request.jobtitle}</td>
                <td>{request.role}</td>
                <td>{`${request.first_name} ${request.last_name}`}</td>
                <td>{request.department}</td>
                <td>{new Date(request.createdAt).toLocaleDateString()}</td>
                <td>
                  <Link to={`/b2b/dashboard/hiring-requests/${request.id}`}>
                    {t('hiringRequests.viewDetails')}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HiringRequestList;
