import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Spinner from '../../../common/Spinner/Spinner';
import { API_URL } from "../../../../shared.js";
import "./CreateSoftSkillsAssessment.scss";



const CreateSoftSkillsAssessment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [jobOpenings, setJobOpenings] = useState([]);
  const [selectedJob, setSelectedJob] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // use effect to fetch job openings
  useEffect(() => {
    const fetchJobOpenings = async () => {
      try {
        const response = await fetch(`${API_URL}/job-offers`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Job Openings:", data);
        setJobOpenings(data);
      } catch (err) {
        console.error("Error fetching job openings:", err);
      }
    };

    fetchJobOpenings(); // Appeler la fonction
  }, []); // Tableau de dépendances vide : exécution unique au montage

  // Mock data for job openings
  // const jobOpenings = [
  //   { id: 1, title: "Senior Software Engineer" },
  //   { id: 2, title: "Product Manager" },
  // ];

  const languages = ["English", "French", "German", "Spanish", "Italian", "Portuguese"];
  const levels = ["junior", "intermediate", "senior"];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const selectedJobOpening = jobOpenings.find(
      (job) => job.id === selectedJob
    );

    if (selectedJobOpening) {
      try {
        const title = encodeURIComponent(selectedJobOpening.role);
        const type = 'complete'; // or whatever default type you want to use
        const level = encodeURIComponent(selectedLevel);
        const language = encodeURIComponent(selectedLanguage);

        const response = await fetch(
          `${API_URL}/softskillsassessmentgenerated/${title}/${type}/${level}/${language}`,
          {
            method: "GET",
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        navigate(`/b2b/dashboard/assessments/soft-skills/${result.analysisId}`, {
          state: { data: result },
        });
      } catch (error) {
        console.error("Error generating assessment:", error);
        setError("Failed to generate assessment");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="create-assessment">
       {loading && (
        <>
          <div>
            <Spinner
            />
          </div>
          <div className="waiting-message">{t("assessments.generationTime")}</div>
        </>
      )}
      <div className="create-assessment-header">
        <h2>{t("assessments.createSoftSkills")}</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="jobOpening">{t("assessments.selectJob")}</label>
          <select
            id="jobOpening"
            value={selectedJob}
            onChange={(e) => setSelectedJob(e.target.value)}
            required
          >
            <option value="" disabled>
              {t("assessments.selectJobOption")}
            </option>
            {jobOpenings.map((job) => (
              <option key={job.id} value={job.id}>
                {job.role}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="language">{t("assessments.selectLanguage")}</label>
          <select
            id="language"
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
            required
          >
            <option value="" disabled>
              {t("assessments.selectLanguageOption")}
            </option>
            {languages.map((lang) => (
              <option key={lang} value={lang}>
                {t(`assessments.languages.${lang}`)}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="level">{t("assessments.selectLevel")}</label>
          <select
            id="level"
            value={selectedLevel}
            onChange={(e) => setSelectedLevel(e.target.value)}
            required
          >
            <option value="" disabled>
              {t("assessments.selectLevelOption")}
            </option>
            {levels.map((level) => (
              <option key={level} value={level}>
                {t(`assessments.levels.${level}`)}
              </option>
            ))}
          </select>
        </div>
        <div className="form-actions">
          <button type="submit" className="submit-button">
            {t("assessments.generateSoftSkills")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateSoftSkillsAssessment;
