import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './StructuredInterviewDetailsPage.scss';
import { API_URL } from '../../../shared';

const StructuredInterviewDetailsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [interview, setInterview] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchInterviewDetails();
  }, [id]);

  const fetchInterviewDetails = async () => {
    try {
      const response = await fetch(`${API_URL}/interview-questionnaires/${id}`, {
        credentials: 'include',
        headers: {
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Server error:', errorData);
        throw new Error(errorData.message || 'Failed to fetch interview details');
      }

      const { success, data } = await response.json();
      
      if (!success) {
        throw new Error('Failed to fetch interview details');
      }

      // Debug logs to see the structure of the data
      console.log('Full interview data:', data);
      console.log('Job Opening data:', data.JobOfferQuestionnaire);
      console.log('Job role:', data.JobOfferQuestionnaire?.role);

      setInterview(data);
    } catch (error) {
      console.error('Error:', error);
      toast.error(t('structuredInterview.errors.fetchDetailsFailed'));
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="loading">{t('common.loading')}</div>;
  }

  if (!interview) {
    return <div className="error">{t('structuredInterview.errors.notFound')}</div>;
  }

  return (
    <div className="structured-interview-details">
      <div className="details-header">
        <button className="back-button" onClick={() => navigate(-1)}>
          ← {t('common.back')}
        </button>
        <h1>{t('structuredInterview.details.title')}</h1>
      </div>

      <div className="details-content">
        <div className="info-section">
          <div className="info-card job-info">
            <h2>{t('structuredInterview.details.jobInfo')}</h2>
            <p className="label">{t('structuredInterview.details.role')}</p>
            <p className="value">
              {interview.JobOfferQuestionnaire?.role || t('common.notAvailable')}
            </p>
          </div>

          <div className="info-card candidate-info">
            <h2>{t('structuredInterview.details.candidateInfo')}</h2>
            <p className="label">{t('structuredInterview.details.candidateName')}</p>
            <p className="value">
              {interview.Candidate 
                ? `${interview.Candidate.first_name} ${interview.Candidate.last_name}`
                : t('common.notAvailable')
              }
            </p>
          </div>
        </div>

        <div className="questionnaire-section">
          <h2>{t('structuredInterview.details.questionnaire')}</h2>
          
          {/* Opening Questions */}
          {interview.questions.openingQuestions && (
            <div className="question-section">
              <h3>{t('structuredInterview.details.openingQuestions')}</h3>
              <div className="questions-list">
                {interview.questions.openingQuestions.map((question, index) => (
                  <div key={`opening-${index}`} className="question-card">
                    <h4>Question {index + 1}</h4>
                    <p className="question-text">{question.question}</p>
                    <p className="question-purpose">
                      <strong>{t('structuredInterview.details.purpose')}:</strong> {question.purpose}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Main Questions */}
          {interview.questions.mainQuestions && (
            <div className="question-section">
              <h3>{t('structuredInterview.details.mainQuestions')}</h3>
              <div className="questions-list">
                {interview.questions.mainQuestions.map((question, index) => (
                  <div key={`main-${index}`} className="question-card">
                    <h4>Question {index + 1}</h4>
                    <p className="question-text">{question.question}</p>
                    <p className="question-type">
                      <strong>{t('structuredInterview.details.type')}:</strong> {question.type}
                    </p>
                    <p className="question-importance">
                      <strong>{t('structuredInterview.details.importance')}:</strong> {question.importance}/5
                    </p>
                    {question.criteria && (
                      <div className="criteria-list">
                        <strong>{t('structuredInterview.details.criteria')}:</strong>
                        <ul>
                          {question.criteria.map((criterion, idx) => (
                            <li key={idx}>{criterion}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Closing Questions */}
          {interview.questions.closingQuestions && (
            <div className="question-section">
              <h3>{t('structuredInterview.details.closingQuestions')}</h3>
              <div className="questions-list">
                {interview.questions.closingQuestions.map((question, index) => (
                  <div key={`closing-${index}`} className="question-card">
                    <h4>Question {index + 1}</h4>
                    <p className="question-text">{question.question}</p>
                    <p className="question-purpose">
                      <strong>{t('structuredInterview.details.purpose')}:</strong> {question.purpose}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StructuredInterviewDetailsPage; 