import React, { useState, useEffect } from "react";
import "./AIPhoneInterviewForm.scss";
import { useTranslation } from "react-i18next";
import { API_URL } from '../../../../shared.js';
import { useNavigate } from "react-router-dom";

const AIPhoneInterviewForm = () => {
  const [formName, setFormName] = useState("");
  const [questions, setQuestions] = useState(Array(6).fill(""));
  const [selectedJob, setSelectedJob] = useState("");
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${API_URL}/job-offers`, {
          method: "GET",
          credentials: "include",
          headers: { 
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch jobs');
        }
        const data = await response.json();
        setJobs(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error(t("aiPhoneInterview.form.errorFetchingJobs"), error);
        setJobs([]);
      }
    };

    fetchJobs();
  }, [t]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage("");

    if (!selectedJob) {
      setMessage(t("aiPhoneInterview.form.validation.jobRequired"));
      setIsLoading(false);
      return;
    }

    const filteredQuestions = questions.filter((q) => q.trim() !== "");
    if (filteredQuestions.length === 0) {
      setMessage(t("aiPhoneInterview.form.validation.questionsRequired"));
      setIsLoading(false);
      return;
    }

    const payload = {
      formName,
      questions: filteredQuestions,
      jobOfferId: selectedJob,
    };

    try {
      const response = await fetch(`${API_URL}/ai-phone-interviews`, {
        method: "POST",
        credentials: "include",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();

      if (response.ok) {
        setMessage(t("aiPhoneInterview.form.success"));
        setMessage(t("aiPhoneInterview.form.success"));
        setFormName("");
        setQuestions(Array(6).fill(""));
        setSelectedJob("");
      } else {
        setMessage(data.message || t("aiPhoneInterview.form.error"));
        setMessage(data.message || t("aiPhoneInterview.form.error"));
      }
    } catch (error) {
      console.error(t("aiPhoneInterview.form.error"), error);
      setMessage(t("aiPhoneInterview.form.error"));
      console.error(t("aiPhoneInterview.form.error"), error);
      setMessage(t("aiPhoneInterview.form.error"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="ai-phone-interview-form">
      <div className="form-header">
        <div className="header-content">
          <h2>{t("aiPhoneInterview.form.title")}</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="formName">
            {t("aiPhoneInterview.form.formName.label")}
          </label>
          <label htmlFor="formName">
            {t("aiPhoneInterview.form.formName.label")}
          </label>
          <input
            type="text"
            id="formName"
            value={formName}
            onChange={(e) => setFormName(e.target.value)}
            placeholder={t("aiPhoneInterview.form.formName.placeholder")}
            required
          />
        </div>
        {questions.map((question, index) => (
          <div className="form-group" key={index}>
            <label htmlFor={`question${index + 1}`}>
              {t("aiPhoneInterview.form.questions.label", {
                number: index + 1,
              })}
            </label>
            <label htmlFor={`question${index + 1}`}>
              {t("aiPhoneInterview.form.questions.label", {
                number: index + 1,
              })}
            </label>
            <input
              type="text"
              id={`question${index + 1}`}
              value={question}
              onChange={(e) => {
                const newQuestions = [...questions];
                newQuestions[index] = e.target.value;
                setQuestions(newQuestions);
              }}
              placeholder={t("aiPhoneInterview.form.questions.placeholder", {
                number: index + 1,
              })}
            />
          </div>
        ))}
        <div className="form-group">
          <label htmlFor="job">
            {t("aiPhoneInterview.form.jobOpening.label")}
          </label>
          <label htmlFor="job">
            {t("aiPhoneInterview.form.jobOpening.label")}
          </label>
          <select
            id="job"
            value={selectedJob}
            onChange={(e) => setSelectedJob(e.target.value)}
            required
          >
            <option value="">
              {t("aiPhoneInterview.form.jobOpening.placeholder")}
            </option>
            {Array.isArray(jobs) && jobs.map((job) => (
              <option key={job.id} value={job.id}>
                {job.role}
              </option>
            ))}
          </select>
        </div>
        <div className="form-actions">
          <button
            type="submit"
            className="submit-button"
            disabled={isLoading}
          >
            {isLoading
              ? t("aiPhoneInterview.form.actions.saving")
              : t("aiPhoneInterview.form.actions.save")}
          </button>
        </div>
        {message && <div className="form-message">{message}</div>}
      </form>
      <div className="bottom-navigation">
        <button 
          type="button"
          className="back-button"
          onClick={() => navigate('/b2b/dashboard/ai-phone-interviews')}
        >
          {t("aiPhoneInterview.backToList")}
        </button>
      </div>
    </div>
  );
};

export default AIPhoneInterviewForm;
