import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaSave } from "react-icons/fa";
import "./AIPhoneInterviewDetails.scss";
import { API_URL } from '../../../../shared.js';

const AIPhoneInterviewDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [interview, setInterview] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchInterviewDetails = async () => {
      try {
        const response = await fetch(`${API_URL}/ai-phone-interviews/${id}`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          setInterview(data);
          setFormData(data); // Pré-remplir le formulaire pour l'édition
        }
      } catch (error) {
        console.error("Error fetching interview details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInterviewDetails();
  }, [id]);

  const handleBackClick = () => {
    navigate("/b2b/dashboard/ai-phone-interviews");
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`${API_URL}/ai-phone-interviews/${id}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({
          formName: formData.form_name,
          questions: formData.questions,
          jobOfferId: interview?.job_offer_id,
        }),
      });

      const data = await response.json();

      setMessage("Interview updated successfully");
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update interview", error);
      setMessage("Failed to update interview");
    }
  };

  if (isLoading) {
    return (
      <div className="loading">{t("aiPhoneInterview.details.loading")}</div>
    );
  }

  if (!interview) {
    return (
      <div className="not-found">{t("aiPhoneInterview.details.notFound")}</div>
    );
  }

  return (
    <div className="ai-phone-interview-details">
      <div className="interview-details-header">
        <button onClick={handleBackClick} className="back-button">
          <FaArrowLeft /> {t("aiPhoneInterview.details.backToList")}
        </button>
        <h2>{t("aiPhoneInterview.title")}</h2>
        <button onClick={() => setIsEditing(!isEditing)}>
          {isEditing
            ? t("aiPhoneInterview.form.actions.cancel")
            : t("aiPhoneInterview.edit")}
        </button>
      </div>

      <div className="interview-content">
        <div className="info-group">
          <label>{t("aiPhoneInterview.form.formName.label")}</label>
          {isEditing ? (
            <input
              type="text"
              value={formData.form_name || ""}
              onChange={(e) =>
                setFormData({ ...formData, form_name: e.target.value })
              }
              placeholder={t("aiPhoneInterview.form.formName.placeholder")}
            />
          ) : (
            <p>
              {interview.form_name ||
                t("aiPhoneInterview.form.validation.formNameRequired")}
              {interview.form_name ||
                t("aiPhoneInterview.form.validation.formNameRequired")}
            </p>
          )}
        </div>

        <div className="questions-section">
          <h3>{t("aiPhoneInterview.details.questions")}</h3>
          <div className="questions-list">
            {isEditing
              ? formData.questions.map((question, index) => (
                  <input
                    key={index}
                    value={question}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        questions: formData.questions.map((q, i) =>
                          i === index ? e.target.value : q
                        ),
                      })
                    }
                    placeholder={t(
                      "aiPhoneInterview.form.questions.placeholder",
                      {
                        number: index + 1,
                      }
                    )}
                  />
                ))
              : interview.questions.map((question, index) => (
                  <div key={index} className="question-item">
                    <span className="question-number">Q{index + 1}.</span>
                    <p className="question-text">{question}</p>
                  </div>
                ))}
          </div>
        </div>
      </div>
      {isEditing && (
        <button onClick={handleSave} className="aiPhone-save-button">
          <FaSave /> {t("aiPhoneInterview.form.actions.save")}
        </button>
      )}
    </div>
  );
};

export default AIPhoneInterviewDetails;
