import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./AssessmentsList.scss";
import { API_URL } from "../../../../shared.js";

const fetchAiConversation = async () => {
  try {
    const response = await fetch(`${API_URL}/all-assessments`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    // Filter out assessments without test_content or invalid data
    const validAssessments = result.filter(assessment => {
      try {
        return assessment.test_content && JSON.parse(assessment.test_content);
      } catch (e) {
        console.warn(`Invalid test_content for assessment ${assessment.id}`);
        return false;
      }
    });
    
    return validAssessments;
  } catch (error) {
    console.error("Error fetching assessments:", error);
    return [];
  }
};

const ASSESSMENT_TYPES = {
  LANGUAGE: 'Language',
  SOFT_SKILLS: 'Soft Skills',
  HARD_SKILLS: 'Hard Skills'
};

const AssessmentsList = ({ userAddOns = {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedAssessmentType, setSelectedAssessmentType] = useState("");
  const [aiConversation, setAiConversation] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Match the addOnKey pattern from the header
  const hasSkillsAssessment = userAddOns?.['add_on_skills_assessment'] ?? true;
  const hasLanguageAssessment = userAddOns?.['add_on_language_assessment'] ?? true;

  console.log('Add-ons status:', {
    skills: hasSkillsAssessment,
    language: hasLanguageAssessment,
    fullUserAddOns: userAddOns
  });

  const handleAssessmentTypeChange = (e) => {
    const type = e.target.value;
    setSelectedAssessmentType(type);

    // Redirect to the appropriate creation page based on type
    switch (type) {
      case "soft":
        if (hasSkillsAssessment) {
          navigate("/b2b/dashboard/assessments/create/soft-skills");
        } else {
          alert(t("assessments.noAccess"));
        }
        break;
      case "hard":
        if (hasSkillsAssessment) {
          navigate("/b2b/dashboard/assessments/create/hard-skills");
        } else {
          alert(t("assessments.noAccess"));
        }
        break;
      case "language":
        if (hasLanguageAssessment) {
          navigate("/b2b/dashboard/assessments/create/language-skills");
        } else {
          alert(t("assessments.noAccess"));
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await fetchAiConversation();
        if (isMounted) {
          // Make sure we're setting an array
          setAiConversation(result);
          setError(null);
        }
      } catch (err) {
        if (isMounted) {
          console.error("Error in fetchData:", err);
          setError("Failed to load assessments");
          setAiConversation([]);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleActionSelect = (event, assessment) => {
    const action = event.target.value;
    switch (action) {
      case "view":
        try {
          // Verify test_content exists and is valid JSON before navigating
          if (!assessment.test_content || !JSON.parse(assessment.test_content)) {
            alert(t("assessments.invalidContent"));
            return;
          }

          // Normalize the type by removing spaces and converting to uppercase
          const normalizedType = assessment.type.replace(/\s+/g, '_').toUpperCase();
          console.log('Normalized type:', normalizedType);
          
          // Compare with normalized types
          if (normalizedType === 'LANGUAGE') {
            navigate(`/b2b/dashboard/assessments/language-skills/${assessment.id}`);
          } else if (normalizedType === 'SOFT_SKILLS') {
            navigate(`/b2b/dashboard/assessments/soft-skills/${assessment.id}`);
          } else if (normalizedType === 'HARD_SKILLS') {
            navigate(`/b2b/dashboard/assessments/hard-skills/${assessment.id}`);
          } else {
            console.error("Unknown assessment type:", assessment.type);
          }
        } catch (error) {
          console.error("Invalid test content:", error);
          alert(t("assessments.invalidContent"));
        }
        break;
      case "delete":
        handleDeleteAssessment(assessment.id);
        break;
      default:
        break;
    }
  };

  const handleDeleteAssessment = async (assessmentId) => {
    try {
      const response = await fetch(`${API_URL}/assessment/${assessmentId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      const data = await response.json();

      if (response.ok) {
        setAiConversation(prevConversations => 
          prevConversations.filter(assessment => assessment.id !== assessmentId)
        );
      } else if (data.hasActiveInvitations) {
        // Show error message for active invitations
        alert(t("assessments.cannotDeleteActiveInvitation")); // Or use your preferred notification system
      } else {
        console.error("Failed to delete assessment");
      }
    } catch (error) {
      console.error("Error deleting assessment:", error);
    }
  };

  const formatAssessmentTitle = (assessment) => {
    // Just return the title as is, without any type information
    return assessment.title;
  };

  const handleViewAnalysis = (assessmentId) => {
    navigate(`/b2b/dashboard/assessments/${assessmentId}/analysis`);
  };

  return (
    <div className="assessments-list">
      <div className="assessments-header">
        <h2>{t("assessments.title")}</h2>
        <div className="assessments-actions">
          <select
            value={selectedAssessmentType}
            onChange={handleAssessmentTypeChange}
            className="assessment-type-select"
          >
            <option value="" disabled>
              {t("assessments.createNew")}
            </option>
            {hasLanguageAssessment && (
              <option value="language">{t("assessments.createLanguage")}</option>
            )}
            {hasSkillsAssessment && (
              <>
                <option value="soft">{t("assessments.createSoftSkills")}</option>
                <option value="hard">{t("assessments.createHardSkills")}</option>
              </>
            )}
          </select>
          <button
            className="view-results-button"
            onClick={() => navigate("/b2b/dashboard/assessments/results")}
          >
            {t("assessments.viewResults")}
          </button>
        </div>
      </div>

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      {isLoading ? (
        <div className="loading-message">
          Loading assessments...
        </div>
      ) : (
        <table className="assessments-table">
          <thead>
            <tr>
              <th>{t("assessments.createdAt")}</th>
              <th>{t("assessments.role")}</th>
              <th>{t("assessments.type")}</th>
              <th>{t("jobOpenings.jobCard.actions.title", "Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {!aiConversation || aiConversation.length === 0 ? (
              <tr>
                <td colSpan="4" className="no-assessments">
                  {t("assessments.noAssessments", "No assessments found")}
                </td>
              </tr>
            ) : (
              aiConversation.map((assessment) => {
                // Skip rendering if missing required data
                if (!assessment.id || !assessment.created_at || !assessment.title || !assessment.type) {
                  return null;
                }

                return (
                  <tr key={assessment.id}>
                    <td>{new Date(assessment.created_at).toLocaleDateString()}</td>
                    <td>{formatAssessmentTitle(assessment)}</td>
                    <td>{ASSESSMENT_TYPES[assessment.type.toUpperCase()] || assessment.type}</td>
                    <td className="actions-column">
                      <select
                        className="action-select"
                        onChange={(e) => handleActionSelect(e, assessment)}
                        defaultValue="default"
                      >
                        <option value="default" disabled>
                          {t("common.selectAction")}
                        </option>
                        {assessment.test_content && <option value="view">{t("common.view")}</option>}
                        <option value="delete">{t("common.delete")}</option>
                      </select>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AssessmentsList;
