import "./UpdateSubscriptionModal.scss";
import React, { useState } from "react";
import { API_URL } from "../../../../shared.js";
import { useTranslation } from "react-i18next";


const getDefaultAddOns = (subscription) => {
  const defaultAddons = [];
  if (subscription.add_on_analysis) defaultAddons.push("add_on_analysis");
  if (subscription.add_on_skills_assessment)
    defaultAddons.push("add_on_skills_assessment");
  if (subscription.add_on_language_assessment)
    defaultAddons.push("add_on_language_assessment");
  if (subscription.add_on_white_label) defaultAddons.push("add_on_white_label");
  if (subscription.add_on_ai_phone_call)
    defaultAddons.push("add_on_ai_phone_call");

  return defaultAddons;
};

const UpdateSubscriptionModal = ({ subscription, closeModal, addons }) => {
  const { t } = useTranslation();
  const [newAddOns, setNewAddOns] = useState(() => getDefaultAddOns(subscription));
  const [additionalQuantity, setAdditionalQuantity] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleAddOnChange = (addonKey, isCore) => {
    const currentKey = isCore ? addonKey : `add_on_${addonKey}`;
    setNewAddOns((prev) =>
      prev.includes(currentKey)
        ? prev.filter((key) => key !== currentKey)
        : [...prev, currentKey]
    );
  };

  const updateQuantity = (change) => {
    setAdditionalQuantity((prevQuantity) =>
      Math.max(prevQuantity + change, 0)
    );
  };

  const calculateAdditionalCost = () => {
    const addonPrices = addons.reduce((acc, addon) => {
      const currentKey = addon.isCore ? addon.key : `add_on_${addon.key}`;
      if (newAddOns.includes(currentKey)) {
        return acc + addon.price;
      }
      return acc;
    }, 0);

    const baseCost = addonPrices + additionalQuantity * 97;
    return subscription.payment_term === "Yearly"
      ? baseCost * 12
      : baseCost;
  };

  const handleConfirmUpdate = async () => {
    try {
      const response = await fetch(
        `${API_URL}/subscriptions/${subscription.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stripe_id: subscription.stripe_id,
            additionalQuantity,
            newAddOns,
          }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        toast.error("Failed to update subscription");
      }

      const data = await response.json();
      toast.success("Updated subscription:", data)
      closeModal();
    } catch (err) {
      console.error("Error updating subscription:", err.message);
      toast.error("Error updating subscription:", err.message);
    }
  };

  const toggleConfirmationModal = () => {
    if (
      // condition if no changes are made
      newAddOns.length === getDefaultAddOns(subscription).length &&
      newAddOns.every((addon) => getDefaultAddOns(subscription).includes(addon)) &&
      additionalQuantity === 0
    ) {
      alert(t("Talexa.subscriptionDashBoardPage.noChange"));
      return;
    } 

    setShowConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };

  const isDisabled = newAddOns.length === getDefaultAddOns(subscription).length &&
  newAddOns.every((addon) => getDefaultAddOns(subscription).includes(addon)) &&
  additionalQuantity === 0;

  return (
    <div className="updateSubscriptionModal" onClick={closeModal}>
      <div
        className="updateSubscriptionModal-container"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="updateSubscriptionModal-container__title">
        {t("Talexa.subscriptionDashBoardPage.updateSubscription")}
        </h3>
        <div className="updateSubscriptionModal-container__details">
          <p className="updateSubscriptionModal-container__details__quantity">
            <strong className="updateSubscriptionModal-container__details__quantity-text">
            {t("Talexa.subscriptionDashBoardPage.subscription.quantity")}:
            </strong>
            <button
              className="updateSubscriptionModal-container__details__quantity-button"
              onClick={() => updateQuantity(-1)}
              disabled={additionalQuantity === 0}
            >
              -
            </button>
            <span className="updateSubscriptionModal-container__details__quantity-number">
              {subscription.quantity + additionalQuantity}
            </span>
            <button
              className="updateSubscriptionModal-container__details__quantity-button"
              onClick={() => updateQuantity(1)}
            >
              +
            </button>
          </p>
          <h4 className="updateSubscriptionModal-container__details-title">
          {t("Talexa.subscriptionDashBoardPage.subtitle")}
          </h4>
          <div className="updateSubscriptionModal-container__details-addOn-container">
            {addons.map((addon) => {
              const currentKey = addon.isCore
                ? addon.key
                : `add_on_${addon.key}`;
              const isInitiallyActive = subscription[currentKey];
              const isCurrentlySelected = newAddOns.includes(currentKey);

              return (
                <label
                  className="updateSubscriptionModal-container__details-addOn-container__item"
                  key={addon.key}
                >
                  <input
                    className="updateSubscriptionModal-container__details-addOn-container__item-input"
                    type="checkbox"
                    checked={isCurrentlySelected || isInitiallyActive}
                    onChange={() => handleAddOnChange(addon.key, addon.isCore)}
                    disabled={isInitiallyActive}
                  />
                  <span className="updateSubscriptionModal-container__details-addOn-container__item-text">
                    {addon.name}
                  </span>
                </label>
              );
            })}
          </div>
        </div>
        <div className="updateSubscriptionModal-container__buttons-container">
          <button
            className="updateSubscriptionModal-container__buttons-container-button--cancel"
            onClick={closeModal}
          >
            {t("candidateApplications.details.cancel")}
          </button>
          <button
            className={`updateSubscriptionModal-container__buttons-container-button--confirm ${isDisabled ? 'button-disabled' : ''}`}
            onClick={toggleConfirmationModal}
            disabled={isDisabled}
          >
            {t("jobApplication.jobApplicationDetails.modal.refuse.actions.confirm")}
          </button>
        </div>
      </div>
      {showConfirmation && (
        <div className="updateSubscriptionModal-confirmationModal"
          onClick={(e) => e.stopPropagation()} >
          <div className="updateSubscriptionModal-confirmationModal-container">
            <h4 className="updateSubscriptionModal-confirmationModal-container-title">{t( "Talexa.subscriptionDashBoardPage.confirmation" )}</h4>
            <p className="updateSubscriptionModal-confirmationModal-container-text">
              {t("Talexa.subscriptionDashBoardPage.confirmationMessage", {addOnsNumber: newAddOns.length, additionalQuantity: additionalQuantity})}
            </p>
            <p className="updateSubscriptionModal-confirmationModal-container-text">
            {t("Talexa.subscriptionDashBoardPage.confirmationMessagePrice", 
              {
                additionalPrice: calculateAdditionalCost().toFixed(2), 
                paymentTerm: subscription.payment_term === "Yearly" ? "per year" : "per month",
              }
            )}
            </p>
            <p className="updateSubscriptionModal-confirmationModal-container-text">
              {t("Talexa.subscriptionDashBoardPage.confirmationMessageEnd")}
            </p>
            <div className="updateSubscriptionModal-confirmationModal-container-buttons">
              <button className="updateSubscriptionModal-confirmationModal-container-buttons-cancel" onClick={closeConfirmationModal}>{t("candidateApplications.details.cancel")}</button>
              <button className="updateSubscriptionModal-confirmationModal-container-buttons-confirm" onClick={handleConfirmUpdate}>  {t("jobApplication.jobApplicationDetails.modal.refuse.actions.confirm")}</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateSubscriptionModal;
