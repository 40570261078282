import React, { useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserProfile } from "../../../services/userService";

// Import des composants spécifiques au dashboard B2B
import B2BMyProfile from "./B2BMyProfile";
import Subscription from "./Subscription";
import Billing from "./Billing";
import CreateJobOpening from "./CreateJobOpening";
import HiringRequestList from "./HiringRequestList";
import HiringRequestDetail from "./HiringRequestDetail";
import JobOpenings from "./JobOpenings";
import JobOfferDetails from "./JobOfferDetails";
import EditJobOpening from "./EditJobOpening";
import CandidatePool from "./CandidatePool";
import CandidateDetailsPool from "./CandidateDetailsPool";
import CandidatePipeline from "./CandidatePipeline";
import AddCandidate from "./AddCandidate";
import ScheduledInterviews from "./ScheduledInterviews";
import TrackingBoardListing from "./TrackingBoardListing";
import TrackingBoardItemListing from "./TrackingBoardItemListing";
import TalexaB2B from "./TalexaChatB2B";
import SupportTicketList from "./SupportTicketList";
import SupportTicketCreate from "./SupportTicketCreate";
import SupportTicketDetails from "./SupportTicketDetails";
import CandidateSharedPool from "./CandidateSharedPool";
import Dashboard404Page from "../../Dashboard404Page";
import CandidatePipelineDetails from "./CandidatePipelineDetails";
import AIPhoneInterviewList from "./AIPhoneItw/AIPhoneInterviewList";
import ELearningVideo from "../../ELearningVideos";
import AIPhoneInterviewForm from "./AIPhoneItw/AIPhoneInterviewForm";
import AssessmentsList from "./Assessments/AssessmentsList";
import AllEmailsTemplates from "./AllEmailsTemplates";
import CandidateEmailsTemplates from "./CandidateEmailsTemplates";
import CreateSoftSkillsAssessment from "./Assessments/CreateSoftSkillsAssessment";
import CreateHardSkillsAssessment from "./Assessments/CreateHardSkillsAssessment";
import CreateLanguageAssessment from "./Assessments/CreateLanguageAssessment";
import AIPhoneInterviewDetails from "./AIPhoneItw/AIPhoneInterviewDetails";
import AIHiringMarketTrends from "./Analytics/AIHiringMarketTrends";
import AssesmentDetailPageHardSkill from "./Assessments/AssesmentDetailPageHardSkills";
import AssesmentDetailPageSoftSkill from "./Assessments/AssesmentDetailPageSoftSkills";
import AssessmentDetailPageLanguageSkill from "./Assessments/AssesmentDetailPageLanguageSkills";
import SharedCandidatePool from "./CandidateSharedPool";
import HiringRequestForm from "./HiringRequestForm";
import ReceivedApplications from "./ReceivedApplications";
import GenerateEmailTemplate from "./GenerateEmailTemplate";
import JobBoard from "../../JobBoard";
import AIPhoneInterviewReturn from "./AIPhoneItw/AIPhoneInterviewReturn";
import JobBoardDetails from "../../JobBoardDetails";
import JobApplication from "../../JobApplication";
import ApplicationThankYouPage from "../../ApplicationThankYouPage";
import { ThemeProvider } from "./ThemeContext";

import Settings from "./Settings";

import { ThemeContext } from "./ThemeContext";
import AICompanyHiringTrends from "./Analytics/AICompanyHiringTrends";

import JobOpeningAIGenerator from './JobOpeningAIGenerator';
import JobOpeningFullAIGen from './JobOpeningFullAIGen';
import StructuredInterviewAIQuestionnaire from './StructuredInterviewAIQuestionnaire';
import StructuredInterviewsList from './StructuredInterviewsList';
import StructuredInterviewDetailsPage from './StructuredInterviewDetailsPage';
import AssessmentsResults from "./Assessments/AssessmentsResults";
import AnalysisLanguage from './Assessments/composants/AnalysisLanguage';
import AssesmentDetailPageSoftSkills from "./Assessments/AssesmentDetailPageSoftSkills";
import AssesmentDetailPageHardSkills from "./Assessments/AssesmentDetailPageHardSkills";
import ScreenAppOnboarding from "./ScreenApp/ScreenAppOnboarding";

const B2BDashboardRoutes = ({
  dashboardData,
  isLoading,
  error,
  userAddOns,
}) => {
  console.log('B2BDashboardRoutes userAddOns:', userAddOns);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const setLanguage = async () => {
      try {
        const browserLang = navigator.language.split("-")[0];
        console.log("Browser language detected:", browserLang);

        const userProfile = await getUserProfile();
        console.log(
          "User profile language preference:",
          userProfile?.language_preference
        );

        // Mise à jour du mappage des langues pour inclure toutes les langues supportées
        const languageMap = {
          english: "en",
          french: "fr",
          spanish: "es",
          german: "de",
          italian: "it",
          portuguese: "pt",
          // Ajout des versions en minuscules pour plus de robustesse
          en: "en",
          fr: "fr",
          es: "es",
          de: "de",
          it: "it",
          pt: "pt",
        };

        let targetLanguage;

        if (userProfile?.language_preference) {
          // Vérifier si la préférence existe dans notre mappage
          targetLanguage =
            languageMap[userProfile.language_preference.toLowerCase()];
          console.log("Language from user preference:", targetLanguage);
        }

        // Si pas de préférence utilisateur valide, utiliser la langue du navigateur
        if (
          !targetLanguage &&
          ["en", "fr", "es", "de", "it", "pt"].includes(browserLang)
        ) {
          targetLanguage = browserLang;
          console.log("Using browser language:", targetLanguage);
        }

        // Par défaut en anglais si aucune correspondance
        if (!targetLanguage) {
          targetLanguage = "en";
          console.log("Defaulting to English");
        }

        console.log("Setting language to:", targetLanguage);

        if (i18n.language !== targetLanguage) {
          await i18n.changeLanguage(targetLanguage);
          console.log("Language changed successfully to:", i18n.language);
          // Mettre à jour l'attribut lang du document
          document.documentElement.lang = targetLanguage;
        }
      } catch (error) {
        console.error("Error setting language:", error);
        // En cas d'erreur, définir l'anglais comme langue par défaut
        await i18n.changeLanguage("en");
      }
    };

    setLanguage();
  }, [i18n]);

  const { addOnWhiteLabel, clientConsole } = useContext(ThemeContext);
  if (addOnWhiteLabel === undefined || clientConsole === undefined) {
    return <div>{t("common.loading")}</div>;
  }

  return (
    <Routes>
      {addOnWhiteLabel && <Route path="settings" element={<Settings />} />}
      {/* Path for profile */}
      <Route path="my-profile" element={<B2BMyProfile />} />
      <Route path="subscription" element={<Subscription />} />
      <Route path="billing" element={<Billing />} />

      {/* Path for candidate */}
      <Route
        path="candidates"
        element={<CandidatePool count={dashboardData?.candidatesCount} />}
      />
      <Route path="candidates/pipeline" element={<CandidatePipeline />} />
      <Route
        path="candidates/pipeline/:id"
        element={<CandidatePipelineDetails />}
      />
      <Route path="create-candidate" element={<AddCandidate />} />
      <Route
        path="candidates/:id"
        element={<CandidateDetailsPool readOnly={true} />}
      />
      <Route
        path="candidates/:id/edit"
        element={<CandidateDetailsPool readOnly={false} />}
      />
      <Route path="candidates/shared" element={<CandidateSharedPool />} />

      <Route path="applications" element={<ReceivedApplications />} />

      {/* Path for jobs */}
      <Route
        path="job-openings"
        element={<JobOpenings count={dashboardData?.jobOpeningsCount} />}
      />
      <Route path="job-openings/create" element={<CreateJobOpening />} />
      <Route path="job-openings/:id" element={<JobOfferDetails />} />
      <Route path="job-openings/:id/edit" element={<EditJobOpening />} />

      {/* Path for hiring request */}
      <Route path="hiring-requests" element={<HiringRequestList />} />
      <Route path="hiring-requests/:id" element={<HiringRequestDetail />} />

      {/* Path for interview */}
      <Route path="scheduled-interviews" element={<ScheduledInterviews />} />

      {/* Path for tracking board*/}
      <Route
        path="tracking-boards"
        element={
          <TrackingBoardListing boards={dashboardData?.trackingBoards} />
        }
      />
      <Route
        path="tracking-boards/:id"
        element={
          <TrackingBoardItemListing boards={dashboardData?.trackingBoards} />
        }
      />

      {/* Path for talexa  */}
      <Route path="talexa" element={<TalexaB2B user={userAddOns} />} />
      <Route path="ai-phone-interviews" element={<AIPhoneInterviewList />} />
      <Route 
        path="assessments" 
        element={<AssessmentsList userAddOns={userAddOns || {}} />} 
      />
      <Route
        path="ai-phone-interviews/create"
        element={<AIPhoneInterviewForm />}
      />
      <Route
        path="ai-phone-interviews/:id"
        element={<AIPhoneInterviewDetails />}
      />
      <Route
        path="ai-phone-interviews/:id/AIPhoneInterviewReturn"
        element={<AIPhoneInterviewReturn />}
      />
      <Route
        path="candidates"
        element={<SharedCandidatePool userAddOns={userAddOns} />}
      />
      <Route path="hiring-requests/new" element={<HiringRequestForm />} />
      <Route
        path="assessments/create/soft-skills"
        element={<CreateSoftSkillsAssessment />}
      />
      <Route
        path="assessments/create/hard-skills"
        element={<CreateHardSkillsAssessment />}
      />
      <Route
        path="assessments/create/language-skills"
        element={<CreateLanguageAssessment />}
      />
      <Route
        path="assessments/hard-skills/:id"
        element={<AssesmentDetailPageHardSkills />}
      />
      <Route
        path="assessments/soft-skills/:id"
        element={<AssesmentDetailPageSoftSkills />}
      />
      <Route
        path="assessments/language-skills/:id"
        element={<AssessmentDetailPageLanguageSkill />}
      />
      <Route path="e-learning" element={<ELearningVideo />} />
      <Route path="support-tickets" element={<SupportTicketList />} />
      <Route path="support-tickets/create" element={<SupportTicketCreate />} />
      <Route
        path="support-tickets/:ticketId"
        element={<SupportTicketDetails />}
      />
      <Route path="email-templates" element={<AllEmailsTemplates />} />
      <Route
        path="/candidate-emails/:candidateId"
        element={<CandidateEmailsTemplates />}
      />
      <Route path="/job-board" element={<JobBoard />} />
      <Route path="/job-board/:id" element={<JobBoardDetails />} />
      <Route path="/job-board/:id/apply" element={<JobApplication />} />
      <Route
        path="job-board/thank-you/:id"
        element={<ApplicationThankYouPage />}
      />

      <Route path="hiring-market-trends" element={<AIHiringMarketTrends />} />

      <Route
        path="email-templates/generate"
        element={<GenerateEmailTemplate />}
      />

      <Route path="company-hiring-trends" element={<AICompanyHiringTrends />} />

      <Route path="generate-job-opening" element={<JobOpeningAIGenerator />} />

      <Route path="job-openings/full-ai-generate" element={<JobOpeningFullAIGen />} />

      <Route 
        path="structured-interview-questionnaire" 
        element={<StructuredInterviewAIQuestionnaire />} 
      />

      <Route 
        path="structured-interviews" 
        element={<StructuredInterviewsList />} 
      />

      <Route 
        path="structured-interviews/:id" 
        element={<StructuredInterviewDetailsPage />} 
      />

      <Route 
        path="assessments/results" 
        element={<AssessmentsResults userAddOns={userAddOns} />} 
      />

      <Route 
        path="assessments/:id/analysis" 
        element={<AnalysisLanguage />} 
      />

      <Route 
        path="screen-app-onboarding" 
        element={<ScreenAppOnboarding />} 
      />

      {/*  404 */}
      <Route path="*" element={<Dashboard404Page userType="company" />} />
    </Routes>
  );
};

export default B2BDashboardRoutes;
