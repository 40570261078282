import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Spinner from "../../common/Spinner/Spinner";
import { API_URL } from "../../../shared";
import "./JobOpeningFullAIGen.scss";
import { FaTrash, FaLanguage } from "react-icons/fa";

const ROLE_LEVELS = ['junior', 'intermediate', 'senior', 'lead', 'manager'];
const CONTRACT_TYPES = ['full-time', 'part-time', 'contract', 'internship'];
const EMPLOYMENT_TYPES = ['permanent', 'temporary', 'fixed-term', 'freelance'];

const LANGUAGES = [
  { code: 'en', name: 'English' },
  { code: 'fr', name: 'Français' },
  { code: 'es', name: 'Español' },
  { code: 'it', name: 'Italiano' },
  { code: 'de', name: 'Deutsch' },
  { code: 'pt', name: 'Português' }
];

const JobOpeningFullAIGen = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [generatedJobOffer, setGeneratedJobOffer] = useState(null);
  const [editableOffer, setEditableOffer] = useState(null);
  const [formData, setFormData] = useState({
    role: "",
    yearsOfExperience: "",
    skills: [""],
    department: "",
    customDepartment: "",
  });
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language.split('-')[0] || 'en');

  console.log('Current language:', i18n.language);
  console.log('Translation test:', t('jobOpeningFullAI.departments.customerService'));

  const handleSkillChange = (index, value) => {
    const newSkills = [...formData.skills];
    newSkills[index] = value;
    setFormData({ ...formData, skills: newSkills });
  };

  const addSkill = () => {
    if (formData.skills.length < 10) {
      setFormData({ ...formData, skills: [...formData.skills, ""] });
    }
  };

  const removeSkill = (index) => {
    const newSkills = formData.skills.filter((_, i) => i !== index);
    setFormData({ ...formData, skills: newSkills });
  };

  const handleGenerateJobOffer = async () => {
    try {
      setIsLoading(true);
      setError(null);
      console.log("Sending request with data:", formData);

      const response = await fetch(`${API_URL}/generate-full-job-offer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        credentials: "include",
        body: JSON.stringify({
          role: formData.role,
          yearsOfExperience: formData.yearsOfExperience,
          skills: formData.skills.filter((skill) => skill.trim() !== ""),
          department:
            formData.department === "Other"
              ? formData.customDepartment
              : formData.department,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Server response:", errorText);
        const errorData = errorText.startsWith("{")
          ? JSON.parse(errorText)
          : { error: "Server error" };
        throw new Error(errorData.error || "Failed to generate job offer");
      }

      const data = await response.json();
      console.log("Generated job offer:", data);
      setGeneratedJobOffer(data);
      setEditableOffer(data);
    } catch (err) {
      console.error("Error generating job offer:", err);
      setError(err.message || "Failed to generate job offer");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (generatedJobOffer) {
      setEditableOffer({
        title: generatedJobOffer.title || "",
        company_name: generatedJobOffer.company_name || "",
        company_description: generatedJobOffer.company_description || "",
        cost_center: generatedJobOffer.cost_center || "",
        contract_type: generatedJobOffer.contract_type || "",
        employment_type: generatedJobOffer.employment_type || "",
        mission: generatedJobOffer.mission || "",
        description: generatedJobOffer.mission || "",
        responsibilities: generatedJobOffer.responsibilities || "",
        requirements: generatedJobOffer.requirements || "",
        min_year_experience: generatedJobOffer.min_year_experience || 0,
        role_level: generatedJobOffer.role_level || "",
        skills: generatedJobOffer.skills || "",
        start_hour: generatedJobOffer.start_hour || "",
        end_hour: generatedJobOffer.end_hour || "",
        city: generatedJobOffer.city || "",
        location: generatedJobOffer.city || "",
        is_hybrid: generatedJobOffer.is_hybrid || false,
        is_remote: generatedJobOffer.is_remote || false,
        is_onsite: generatedJobOffer.is_onsite || false,
        offer_content: generatedJobOffer.offer_content || "",
        perks: generatedJobOffer.perks || "",
        benefits: generatedJobOffer.perks || "",
        min_salary: generatedJobOffer.min_salary || 0,
        max_salary: generatedJobOffer.max_salary || 0,
        salary_min: generatedJobOffer.min_salary || 0,
        salary_max: generatedJobOffer.max_salary || 0,
        hiring_process: generatedJobOffer.hiring_process || "",
        remote: generatedJobOffer.remote || false,
        hybrid: generatedJobOffer.hybrid || false,
        onsite: generatedJobOffer.onsite || false,
        tone: generatedJobOffer.tone || "professional",
        department: generatedJobOffer.department || "",
        languages: generatedJobOffer.languages || "",
      });
    }
  }, [generatedJobOffer]);

  const handleInputChange = (field, value) => {
    setEditableOffer((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleCheckboxChange = (field) => {
    setEditableOffer((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Format the data to match EXACTLY the JobOffer model
      const formattedData = {
        ...editableOffer,
        // Convert string fields that should be arrays
        skills: editableOffer.skills
          ? editableOffer.skills
              .split(",")
              .map((s) => s.trim())
              .filter(Boolean)
          : [],
        languages: editableOffer.languages
          ? editableOffer.languages
              .split(",")
              .map((l) => l.trim())
              .filter(Boolean)
          : [],
        // Ensure other fields are properly typed
        role: editableOffer.title,
        min_salary: parseInt(editableOffer.min_salary) || 0,
        max_salary: parseInt(editableOffer.max_salary) || 0,
        min_year_experience: parseInt(editableOffer.min_year_experience) || 0,
        // Ensure boolean fields are actual booleans
        is_remote: Boolean(editableOffer.is_remote),
        is_hybrid: Boolean(editableOffer.is_hybrid),
        is_onsite: Boolean(editableOffer.is_onsite),
        remote: Boolean(editableOffer.is_remote),
        hybrid: Boolean(editableOffer.is_hybrid),
        onsite: Boolean(editableOffer.is_onsite),
        is_public: false,
        is_ai_generated: true,
        inactive: false,
        status: "draft",
      };

      // Remove any undefined or null values
      Object.keys(formattedData).forEach((key) => {
        if (formattedData[key] === undefined || formattedData[key] === null) {
          delete formattedData[key];
        }
      });

      console.log("Submitting formatted data:", formattedData);

      const response = await fetch(`${API_URL}/job-offers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        credentials: "include",
        body: JSON.stringify(formattedData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to save job offer");
      }

      const savedOffer = await response.json();
      console.log("Job offer saved:", savedOffer);

      // Update the navigation path to the job openings list
      navigate("/b2b/dashboard/job-openings");
    } catch (error) {
      console.error("Error submitting job offer:", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTranslate = async (targetLanguage) => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await fetch(`${API_URL}/translate-job-offer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        credentials: 'include',
        body: JSON.stringify({
          jobOffer: editableOffer,
          targetLanguage
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to translate job offer');
      }

      const translatedOffer = await response.json();
      setEditableOffer(translatedOffer);
      setShowLanguageSelector(false);
    } catch (err) {
      console.error('Translation error:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const departments = [
    { id: "executiveManagement", label: t('jobOpeningFullAI.preview.departments.executiveManagement') },
    { id: "humanResources", label: t('jobOpeningFullAI.preview.departments.humanResources') },
    { id: "finance", label: t('jobOpeningFullAI.preview.departments.finance') },
    { id: "marketing", label: t('jobOpeningFullAI.preview.departments.marketing') },
    { id: "sales", label: t('jobOpeningFullAI.preview.departments.sales') },
    { id: "informationTechnology", label: t('jobOpeningFullAI.preview.departments.informationTechnology') },
    { id: "researchAndDevelopment", label: t('jobOpeningFullAI.preview.departments.researchAndDevelopment') },
    { id: "operations", label: t('jobOpeningFullAI.preview.departments.operations') },
    { id: "customerService", label: t('jobOpeningFullAI.preview.departments.customerService') },
    { id: "legal", label: t('jobOpeningFullAI.preview.departments.legal') },
    { id: "productManagement", label: t('jobOpeningFullAI.preview.departments.productManagement') },
    { id: "engineering", label: t('jobOpeningFullAI.preview.departments.engineering') },
    { id: "qualityAssurance", label: t('jobOpeningFullAI.preview.departments.qualityAssurance') },
    { id: "businessDevelopment", label: t('jobOpeningFullAI.preview.departments.businessDevelopment') },
    { id: "administration", label: t('jobOpeningFullAI.preview.departments.administration') },
    { id: "other", label: t('jobOpeningFullAI.preview.departments.other') }
  ];

  return (
    <div className="job-opening-full-ai-gen">
      <h2>{t("jobOpeningFullAI.title")}</h2>
      {error && <div className="error-message">{error}</div>}

      {isLoading ? (
        <Spinner />
      ) : !generatedJobOffer ? (
        <div className="initial-form">
          <div className="form-group">
            <label>{t("jobOpeningFullAI.role")}</label>
            <input
              type="text"
              value={formData.role}
              onChange={(e) =>
                setFormData({ ...formData, role: e.target.value })
              }
              placeholder={t("jobOpeningFullAI.rolePlaceholder")}
            />
          </div>

          <div className="form-group">
            <label>{t("jobOpeningFullAI.yearsOfExperience")}</label>
            <input
              type="number"
              value={formData.yearsOfExperience}
              onChange={(e) =>
                setFormData({ ...formData, yearsOfExperience: e.target.value })
              }
              min="0"
            />
          </div>

          <div className="form-group">
            <label>{t("jobOpeningFullAI.department")}</label>
            <select
              value={formData.department}
              onChange={(e) =>
                setFormData({ ...formData, department: e.target.value })
              }
            >
              <option value="">{t("jobOpeningFullAI.selectDepartment")}</option>
              {departments.map((dept) => (

                <option key={dept.id} value={dept.id}>
                  {dept.label}
                </option>
              ))}
            </select>
          </div>

          {formData.department === "Other" && (
            <div className="form-group">
              <label>{t("jobOpeningFullAI.customDepartment")}</label>
              <input
                type="text"
                value={formData.customDepartment}
                onChange={(e) =>
                  setFormData({ ...formData, customDepartment: e.target.value })
                }
              />
            </div>
          )}

          <div className="form-group skills-section">
            <div className="skills-header">
              <label>{t("jobOpeningFullAI.skills")}</label>
              <button type="button" className="add-skill" onClick={addSkill}>
                {t("jobOpeningFullAI.addSkill")}
              </button>
            </div>

            {formData.skills.map((skill, index) => (
              <div key={index} className="skill-input">
                <input
                  type="text"
                  value={skill}
                  onChange={(e) => handleSkillChange(index, e.target.value)}
                  placeholder={t("jobOpeningFullAI.skillPlaceholder")}
                />
                {index > 0 && (
                  <button
                    type="button"
                    className="remove-skill"
                    onClick={() => removeSkill(index)}
                  >
                    <FaTrash />
                  </button>
                )}
              </div>
            ))}
          </div>

          <button
            className="generate-button"
            onClick={handleGenerateJobOffer}
            disabled={!formData.role || !formData.department}
          >
            {t("jobOpeningFullAI.generateButton")}
          </button>
        </div>
      ) : (
        <div className="preview-form">
          <div className="preview-header">
            <h2>{t("jobOpeningFullAI.preview.title")}</h2>
            <div className="language-controls">
              <button
                className="language-button"
                onClick={() => setShowLanguageSelector(!showLanguageSelector)}
              >
                <FaLanguage /> {LANGUAGES.find(lang => lang.code === selectedLanguage)?.name}
              </button>
              {showLanguageSelector && (
                <div className="language-dropdown">
                  {LANGUAGES.map((language) => (
                    <button
                      key={language.code}
                      className={`language-option ${selectedLanguage === language.code ? 'active' : ''}`}
                      onClick={() => {
                        setSelectedLanguage(language.code);
                        handleTranslate(language.code);
                      }}
                    >
                      {language.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="preview-content">
            <div className="form-group">

              <label>{t('jobOpeningFullAI.preview.jobTitle')}</label>
              <input 
                type="text" 
                value={editableOffer.title} 
                onChange={(e) => handleInputChange('title', e.target.value)}
              />
            </div>

            <div className="form-group">

              <label>{t('jobOpeningFullAI.preview.companyName')}</label>
              <input 
                type="text" 
                value={editableOffer.company_name}
                onChange={(e) =>
                  handleInputChange("company_name", e.target.value)
                }
              />
            </div>

            <div className="form-group">

              <label>{t('jobOpeningFullAI.preview.companyDescription')}</label>
              <textarea 
                value={editableOffer.company_description}
                onChange={(e) =>
                  handleInputChange("company_description", e.target.value)
                }
              />
            </div>

            <div className="form-group">

              <label>{t('jobOpeningFullAI.preview.mission')}</label>
              <textarea 
                value={editableOffer.mission}
                onChange={(e) => handleInputChange("mission", e.target.value)}
              />
            </div>

            <div className="form-group">

              <label>{t('jobOpeningFullAI.preview.requirements')}</label>
              <textarea 
                value={editableOffer.requirements}
                onChange={(e) =>
                  handleInputChange("requirements", e.target.value)
                }
              />
            </div>

            <div className="form-group">

              <label>{t('jobOpeningFullAI.preview.responsibilities')}</label>
              <textarea 
                value={editableOffer.responsibilities}
                onChange={(e) =>
                  handleInputChange("responsibilities", e.target.value)
                }
              />
            </div>

            <div className="form-group">

              <label>{t('jobOpeningFullAI.preview.perks')}</label>
              <textarea 
                value={editableOffer.perks}
                onChange={(e) => handleInputChange("perks", e.target.value)}
              />
            </div>

            <div className="form-row">
              <div className="form-group">

                <label>{t('jobOpeningFullAI.preview.minSalary')}</label>
                <input 
                  type="number" 
                  value={editableOffer.min_salary}
                  onChange={(e) =>
                    handleInputChange("min_salary", e.target.value)
                  }
                />
              </div>
              <div className="form-group">

                <label>{t('jobOpeningFullAI.preview.maxSalary')}</label>
                <input 
                  type="number" 
                  value={editableOffer.max_salary}
                  onChange={(e) =>
                    handleInputChange("max_salary", e.target.value)
                  }
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">

                <label>{t('jobOpeningFullAI.preview.startHour')}</label>
                <input 
                  type="time" 
                  value={editableOffer.start_hour}
                  onChange={(e) =>
                    handleInputChange("start_hour", e.target.value)
                  }
                />
              </div>
              <div className="form-group">

                <label>{t('jobOpeningFullAI.preview.endHour')}</label>
                <input 
                  type="time" 
                  value={editableOffer.end_hour}
                  onChange={(e) =>
                    handleInputChange("end_hour", e.target.value)
                  }
                />
              </div>
            </div>

            <div className="form-group">
              <label>{t('jobOpeningFullAI.preview.city')}</label>
              <input
                type="text"
                value={editableOffer.city}
                onChange={(e) => handleInputChange('city', e.target.value)}
                placeholder={t('jobOpeningFullAI.preview.cityPlaceholder')}
              />
            </div>

            <div className="form-group">
              <label>{t('jobOpeningFullAI.preview.country')}</label>
              <input
                type="text"
                value={editableOffer.country}
                onChange={(e) => handleInputChange('country', e.target.value)}
                placeholder={t('jobOpeningFullAI.preview.countryPlaceholder')}
              />
            </div>

            <div className="form-row checkbox-group">
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={editableOffer.is_remote}
                    onChange={() => handleCheckboxChange("is_remote")}
                  />
                  {t('jobOpeningFullAI.preview.workType.remote')}
                </label>
              </div>
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={editableOffer.is_hybrid}
                    onChange={() => handleCheckboxChange("is_hybrid")}
                  />
                  {t('jobOpeningFullAI.preview.workType.hybrid')}
                </label>
              </div>
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={editableOffer.is_onsite}
                    onChange={() => handleCheckboxChange("is_onsite")}
                  />
                  {t('jobOpeningFullAI.preview.workType.onsite')}
                </label>
              </div>
            </div>

            <div className="form-group">

              <label>{t('jobOpeningFullAI.preview.skills')}</label>
              <textarea 
                value={editableOffer.skills}
                onChange={(e) => handleInputChange('skills', e.target.value)}
                placeholder={t('jobOpeningFullAI.preview.skillsPlaceholder')}
              />
            </div>

            <div className="form-group">

              <label>{t('jobOpeningFullAI.preview.languages')}</label>
              <textarea 
                value={editableOffer.languages}
                onChange={(e) => handleInputChange('languages', e.target.value)}
                placeholder={t('jobOpeningFullAI.preview.languagesPlaceholder')}
              />
            </div>

            <div className="form-group">

              <label>{t('jobOpeningFullAI.preview.hiringProcess')}</label>
              <textarea 
                value={editableOffer.hiring_process}
                onChange={(e) =>
                  handleInputChange("hiring_process", e.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label>{t('jobOpeningFullAI.preview.roleLevel.label')}</label>
              <input
                type="text"
                value={editableOffer.role_level}
                onChange={(e) => handleInputChange("role_level", e.target.value)}
                placeholder={t('jobOpeningFullAI.preview.roleLevel.placeholder')}
              />
            </div>

            <div className="form-group">
              <label>{t('jobOpeningFullAI.preview.contractType.label')}</label>
              <input
                type="text"
                value={editableOffer.contract_type}
                onChange={(e) => handleInputChange("contract_type", e.target.value)}
                placeholder={t('jobOpeningFullAI.preview.contractType.placeholder')}
              />
            </div>

            <div className="form-group">
              <label>{t('jobOpeningFullAI.preview.employmentType.label')}</label>
              <input
                type="text"
                value={editableOffer.employment_type}
                onChange={(e) => handleInputChange("employment_type", e.target.value)}
                placeholder={t('jobOpeningFullAI.preview.employmentType.placeholder')}
              />
            </div>
          </div>

          <div className="action-buttons">
            <button
              type="button"
              className="back-button"
              onClick={() => {
                setEditableOffer(null);
                setGeneratedJobOffer(null);
              }}
            >
              {t("jobOpeningFullAI.back")}
            </button>
            <button
              type="button"
              className="save-button"
              onClick={handleSubmit}
            >
              {t("jobOpeningFullAI.save")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobOpeningFullAIGen;
