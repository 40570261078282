import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../../shared.js";
import AnalysisLanguage from "./composants/AnalysisLanguage";
import AnalysisSoftSkills from "./composants/AnalysisSoftSkills.jsx";
import AnalysisHardSkills from "./composants/AnalysisHardSkills";
import "./AssessmentsResults.scss";
import { useNavigate, useLocation } from 'react-router-dom';

const AssessmentsResults = ({ userAddOns = {} }) => {
  const { t } = useTranslation();
  const [assessments, setAssessments] = useState({
    LANGUAGE: [],
    SOFT_SKILLS: [],
    HARD_SKILLS: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState(null);

  console.log('userAddOns received:', userAddOns);

  const hasLanguageAssessment = userAddOns?.add_on_language_assessment !== false;
  const hasSoftSkillsAssessment = userAddOns?.add_on_soft_skills_assessment !== false;
  const hasHardSkillsAssessment = userAddOns?.add_on_hard_skills_assessment !== false;

  useEffect(() => {
    fetchAssessments();
  }, []);

  const fetchAssessments = async () => {
    try {
      console.log('Fetching assessments...');
      const response = await fetch(`${API_URL}/assessments-analysis`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Received data:', data);
      
      // Vérifier les add-ons et les données
      console.log('User add-ons:', {
        language: hasLanguageAssessment,
        softSkills: hasSoftSkillsAssessment,
        hardSkills: hasHardSkillsAssessment
      });

      setAssessments(data);
    } catch (error) {
      console.error('Fetch error:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewAnalysis = (assessmentId) => {
    setSelectedAssessmentId(assessmentId);
    setShowAnalysis(true);
    // Update the URL without full navigation
    navigate(`/b2b/dashboard/assessments/${assessmentId}/analysis`, { replace: true });
  };

  const handleCloseAnalysis = () => {
    setShowAnalysis(false);
    setSelectedAssessmentId(null);
    // Navigate back to results page
    navigate('/b2b/dashboard/assessments/results', { replace: true });
  };

  const renderAssessmentSection = (type, assessmentsList) => {
    console.log(`Rendering ${type} section:`, assessmentsList);
    
    if (!assessmentsList || assessmentsList.length === 0) {
      return (
        <div className="no-assessments-message">
          {t("assessments.noAssessmentsFound")}
        </div>
      );
    }

    return (
      <div className="results-table-container">
        <table>
          <thead>
            <tr>
              <th>{t("common.candidate")}</th>
              <th>{t("common.email")}</th>
              <th>{t("common.score")}</th>
              <th>{t("common.date")}</th>
              <th>{t("common.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {assessmentsList.map((assessment) => (
              <tr key={assessment.id}>
                <td>
                  {`${assessment.candidate.firstName} ${assessment.candidate.lastName}`}
                </td>
                <td>{assessment.candidate.email}</td>
                <td>{assessment.candidateScore}%</td>
                <td>{new Date(assessment.updatedAt).toLocaleDateString()}</td>
                <td>
                  <button
                    onClick={() => handleViewAnalysis(assessment.id)}
                    className="view-button"
                  >
                    {t("assessments.viewAnalysis")}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderAnalysisModal = () => {
    if (!selectedAssessment) return null;

    switch (selectedType) {
      case "LANGUAGE":
        return (
          <AnalysisLanguage
            assessmentId={selectedAssessment}
            onClose={handleCloseAnalysis}
          />
        );
      case "SOFT_SKILLS":
        return (
          <AnalysisSoftSkills
            assessmentId={selectedAssessment}
            onClose={() => setSelectedAssessment(null)}
          />
        );
      case "HARD_SKILLS":
        return (
          <AnalysisHardSkills
            assessmentId={selectedAssessment}
            onClose={() => setSelectedAssessment(null)}
          />
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return <div className="loading-message">{t("common.loading")}</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="assessments-results">
      <h2>{t("assessments.results.title")}</h2>
      
      {console.log('Current assessments state:', assessments)}
      {console.log('Add-ons state:', { hasLanguageAssessment, hasSoftSkillsAssessment, hasHardSkillsAssessment })}

      <section className="assessment-section">
        <h3>{t("assessments.types.language")}</h3>
        {renderAssessmentSection("LANGUAGE", assessments.LANGUAGE)}
      </section>

      <section className="assessment-section">
        <h3>{t("assessments.types.softSkills")}</h3>
        {renderAssessmentSection("SOFT_SKILLS", assessments.SOFT_SKILLS)}
      </section>

      <section className="assessment-section">
        <h3>{t("assessments.types.hardSkills")}</h3>
        {renderAssessmentSection("HARD_SKILLS", assessments.HARD_SKILLS)}
      </section>

      {showAnalysis && selectedAssessmentId && renderAnalysisModal()}
    </div>
  );
};

export default AssessmentsResults; 