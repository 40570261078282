import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FaChartLine, FaBuilding, FaUserTie } from 'react-icons/fa';
import './AIHiringMarketTrends.scss';
import getCountries from './countriesList';
import { API_URL } from '../../../../shared.js';
import { API_URL_3000 } from '../../../../shared3000.js';

const AIHiringMarketTrends = () => {
  const { t, i18n } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [marketData, setMarketData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setCountries(getCountries());
  }, [i18n.language]);

  const fetchMarketTrends = async (country) => {
    setLoading(true);
    setError(null);
    try {
      const userLanguage = i18n.language;
      const response = await fetch(`${API_URL_3000}/hiring-market-trends/${country}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json', 
          'Accept-Language': userLanguage,
          'X-User-Language': userLanguage,
        },
        credentials: 'include'
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Market trends response:', data);
      
      if (data.success && data.data) {
        setMarketData(data.data);
      } else {
        throw new Error('Invalid data structure received');
      }
    } catch (err) {
      console.error('Error fetching market trends:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    if (selectedOption) {
      fetchMarketTrends(selectedOption.label);
    }
  };

  const formatText = (text) => {
    if (!text) return '';
    
    text = text.replace(/(\d+\.\s[^\n]+)/g, '<li>$1</li>');
    
    text = text.replace(/[-•]\s([^\n]+)/g, '<li>$1</li>');
    
    if (text.includes('<li>')) {
      text = `<ul>${text}</ul>`;
    }

    text = text.replace(/(\d+(?:\.\d+)?%?)/g, '<strong>$1</strong>');

    return text;
  };

  const renderFormattedText = (text) => {
    return <div dangerouslySetInnerHTML={{ __html: formatText(text) }} />;
  };

  return (
    <div className="hiring-market-trends">
      <div className="trends-header">
        <h2>{t('hiringMarket.title')}</h2>
        <p>{t('hiringMarket.subtitle')}</p>
      </div>

      <div className="country-selector">
        <label>{t('hiringMarket.selectCountry')}</label>
        <Select
          className="country-select"
          options={countries}
          value={selectedCountry}
          onChange={handleCountryChange}
          placeholder={t('hiringMarket.countryPlaceholder')}
          isSearchable
        />
      </div>

      {loading && (
        <div className="loading-state">
          <div className="loader"></div>
          <p>{t('hiringMarket.loading')}</p>
        </div>
      )}

      {error && (
        <div className="error-state">
          <p>Error: {error}</p>
        </div>
      )}

      {marketData && !loading && !error && (
        <div className="trends-content">
          <div className="trends-section">
            <div className="section-header">
              <FaChartLine />
              <h3>{t('hiringMarket.sections.marketOverview.title')}</h3>
            </div>
            <div className="content-box market-overview">
              {renderFormattedText(marketData.marketOverview)}
            </div>
          </div>

          <div className="trends-section">
            <div className="section-header">
              <FaBuilding />
              <h3>{t('hiringMarket.sections.topIndustries.title')}</h3>
            </div>
            <div className="content-box top-industries">
              {renderFormattedText(marketData.topIndustries)}
            </div>
          </div>

          <div className="trends-section">
            <div className="section-header">
              <FaUserTie />
              <h3>{t('hiringMarket.sections.demandedSkills.title')}</h3>
            </div>
            <div className="content-box demanded-skills">
              {renderFormattedText(marketData.demandedSkills)}
            </div>
          </div>

          <div className="trends-section">
            <div className="section-header">
              <FaChartLine />
              <h3>{t('hiringMarket.sections.marketTrendsSummary.title')}</h3>
            </div>
            <div className="content-box trends-summary">
              {marketData.salaryTrends && (
                <div className="summary-section">
                  <h4>{t('hiringMarket.sections.salaryTrends.title')}</h4>
                  {renderFormattedText(marketData.salaryTrends)}
                </div>
              )}
              {marketData.remoteWork && (
                <div className="summary-section">
                  <h4>{t('hiringMarket.sections.remoteWork.title')}</h4>
                  {renderFormattedText(marketData.remoteWork)}
                </div>
              )}
              {marketData.employmentTypes && (
                <div className="summary-section">
                  <h4>{t('hiringMarket.sections.employmentTypes.title')}</h4>
                  {renderFormattedText(marketData.employmentTypes)}
                </div>
              )}
              {marketData.experienceLevels && (
                <div className="summary-section">
                  <h4>{t('hiringMarket.sections.experienceLevels.title')}</h4>
                  {renderFormattedText(marketData.experienceLevels)}
                </div>
              )}
              {marketData.regionalHotspots && (
                <div className="summary-section">
                  <h4>{t('hiringMarket.sections.regionalHotspots.title')}</h4>
                  {renderFormattedText(marketData.regionalHotspots)}
                </div>
              )}
              {marketData.futureOutlook && (
                <div className="summary-section">
                  <h4>{t('hiringMarket.sections.futureOutlook.title')}</h4>
                  {renderFormattedText(marketData.futureOutlook)}
                </div>
              )}
              {marketData.hiringChallenges && (
                <div className="summary-section">
                  <h4>{t('hiringMarket.sections.hiringChallenges.title')}</h4>
                  {renderFormattedText(marketData.hiringChallenges)}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AIHiringMarketTrends; 