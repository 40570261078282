import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { checkAuthStatus } from "./services/authService";
import { API_URL } from "./shared";
import { getUserProfile } from "./services/userService";

const SubscriptionRoute = ({
  children,
  redirectPath = "/b2b/subscription",
}) => {
  const [loading, setLoading] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [paymentBlocked, setPaymentBlocked] = useState(false);

  useEffect(() => {
    const verifySubscription = async () => {
      try {
        const result = await checkAuthStatus();
        if (result.isAuthenticated && result.subscription) {
          const response = await fetch(`${API_URL}/check-failed-payment`, {
            method: "POST",
            credentials: "include",
          });

          const data = await response.json();
          console.log("dataPayments", data);

          // Si l'utilisateur est bloqué
          if (data.success === false) {
            setPaymentBlocked(true);
          } else {
            setHasSubscription(true);
          }
        }
        const userProfile = await getUserProfile();
            if(userProfile.role === "admin") {
              setPaymentBlocked(false);
              setHasSubscription(true);
            }
      } catch (error) {
        console.error("Error verifying subscription:", error);
      } finally {
        setLoading(false);
      }
    };
    verifySubscription();
    
  }, []);

  if (loading) return <div>Loading...</div>;
  if (!hasSubscription) return <Navigate to={redirectPath} replace />;
  if (paymentBlocked) return <Navigate to="/b2b/payment-blocked" replace />;

  return children;
};

export default SubscriptionRoute;
