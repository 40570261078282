import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../components/Variables.scss";
import { logError } from "../../services/errorLogging";
import "./TalexaSubscription.scss";
import SubscriptionModal from "./Modals/SubscriptionModal";
import { API_URL } from '../../shared.js';
import { toast } from "react-toastify";

const TalexaSubscriptionForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Core features et Add-ons pour l'abonnement
  const coreFeatures = [
    "jobOfferGenerator",
    "hiringRequest",
    "resumeAnalysis",
    "personalizedEmails",
    "intervieuwTrackingDashboard",
    "struturedInterviewsScheduling",
    "interviewReports",
    "jobBoardAccess",
    "customerSupport",
  ];

  const addons = [
    "add_on_analysis",
    "add_on_ai_phone_call",
    "add_on_skills_assessment",
    "add_on_language_assessment",
    "add_on_white_label",
  ];

  // Obtenir les dates actuelles pour start_date et end_date
  const getCurrentDate = (yearsToAdd = 0) => {
    const today = new Date();
    today.setFullYear(today.getFullYear() + yearsToAdd);
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    const hh = String(today.getHours()).padStart(2, "0");
    const min = String(today.getMinutes()).padStart(2, "0");
    const ss = String(today.getSeconds()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userData, setUserData] = useState({});
  const [formData, setFormData] = useState({
    tenant_id: "",
    user_id: "",
    type: "",
    quantity: 1,
    price: 97.0,
    vat: 20.0,
    payment_type: "",
    payment_term: "Monthly",
    start_date: getCurrentDate(),
    end_date: getCurrentDate(1),
    status: "Deployment",
    talexa_subscription: true,
    add_on_analysis: false,
    add_on_skills_assessment: false,
    add_on_language_assessment: false,
    add_on_white_label: false,
    add_on_ai_phone_call: false,
    reseller_id: userData.id_reseller,
  });

  // Vérification de l'utilisateur authentifié via sessionStorage
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Charger le profil utilisateur à partir du service
  useEffect(() => {
    const user = sessionStorage.getItem("user");

    if (user && user !== "null") {
      try {
        const parsedUser = JSON.parse(user);
        setUserData(parsedUser);
        setFormData((prev) => ({
          ...prev,
          tenant_id: parsedUser.tenant_id || "",
          user_id: parsedUser.id || "",
          type: parsedUser.role || "",
          reseller_id: parsedUser.id_reseller || null,
        }));
        setIsAuthenticated(true);
      } catch (error) {
        //console.error("Error parsing user data from sessionStorage:", error);
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  // Gestion des modifications des champs du formulaire
  const handleInputChange = (e, action = null) => {
    const { name, value, type: inputType, checked } = e.target;

    setFormData((prevFormData) => {
      // Pour les boutons "+" et "-"
      if (action === "increment") {
        return {
          ...prevFormData,
          quantity: prevFormData.quantity + 1,
        };
      }
      if (action === "decrement") {
        return {
          ...prevFormData,
          quantity: Math.max(1, prevFormData.quantity - 1), // Empêche d'aller en dessous de 1
        };
      }

      // Pour les autres types d'inputs
      return {
        ...prevFormData,
        [name]:
          inputType === "checkbox"
            ? checked
            : name === "quantity"
            ? parseInt(value, 10) || 1
            : value,
      };
    });
  };

  // Gestion des add-ons
  const updateAddons = (selectedAddons) => {
    const updatedFormData = { ...formData };
    addons.forEach((addOn) => {
      updatedFormData[addOn] = selectedAddons.includes(addOn);
    });
    setFormData(updatedFormData);
  };

  // Calcul du prix total basé sur les add-ons, la quantité et le terme de paiement
  const totalPrice = useMemo(() => {
    const addonsPrice = addons.reduce((total, addOn) => {
      if (formData[addOn]) {
        const price = parseFloat(t(`Talexa.addons.${addOn}.price`)) || 0;
        return total + price;
      }
      return total;
    }, 0);

    const basePrice = formData.price + addonsPrice;
    const quantity = parseInt(formData.quantity, 10) || 1;

    const total = basePrice * quantity;

    return formData.payment_term === "Yearly" ? total * 12 : total;
  }, [formData, addons, t]);

  // Soumettre le formulaire et sauvegarder dans la session
  const submitSubscription = async (e) => {
    e.preventDefault();

    // Vérifie si l'utilisateur est authentifié
    if (!isAuthenticated) {
      setIsModalVisible(true); // Ouvre la modal pour inciter à se connecter
      return;
    }

    // verify if the user have already an active subscription
    try {
      const response = await fetch(`${API_URL}/subscriptions/user/current`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to get user subscription data.");
      }

      const data = await response.json();

      if (data) {
        toast.warning("You already have an active subscription.")
        return;
      }
    } catch (error) {
      console.error("Error getting user subscription data:", error);
    }




    const updatedFormData = {
      ...formData,
      price: totalPrice,
    };

    try {
      const response = await fetch(`${API_URL}/subscription/session`, {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ formData: updatedFormData }),
      });

      if (!response.ok) {
        throw new Error("Failed to save subscription data in session.");
      }
      navigate("/b2b/subscription/confirmation");
    } catch (error) {
      //console.error("Error saving subscription data:", error);
    }
  };

  // gestion redirection des données pour expérience utilisateur
  // const saveFormDataToLocal = () => {
  //   const updatedFormData = {
  //     price: totalPrice, // S'assure que le montant total est inclus
  //   };
  //   localStorage.setItem("subscriptionFormData", JSON.stringify(updatedFormData));
  // };
  
  // useEffect(() => {
  //   const savedFormData = localStorage.getItem("subscriptionFormData");
  
  //   if (savedFormData) {
  //     setFormData(JSON.parse(savedFormData));
  //     localStorage.removeItem("subscriptionFormData");
  //   }
  // }, []);


  return (
    <div className="subscription-B2B">
      <header className="hero">
        <h1 className="subscription-B2B-header">{t('Talexa.subscriptionPage.titleHeader')}</h1>
      </header>
      <section className="subscription-B2B-form-container">
        <form onSubmit={submitSubscription} className="subscription-B2B-form">
          <div className="subscription-B2B-form-group-container">
            <div className="subscription-B2B-group-title">
              <h2>{t('Talexa.subscriptionPage.title')}</h2>
            </div>

            <div className="subscription-B2B-group-coreFeatures">
              <h3 className="subscription-B2B-group-coreFeatures-title">
              {t(`Talexa.titleCoreFeatures`)}
              </h3>
              <ul className="features-list">
                {coreFeatures.map((featureKey) => (
                  <li key={featureKey} className="feature-item">
                    <h3>{t(`Talexa.coreFeatures.${featureKey}.title`)}</h3>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="subscription-B2B-group-payment_modality">
            <div className="subscription-B2B-group-quantity">
              {/* <label>Quantity:</label> */}
              <div className="quantity-controls">
                <button
                  type="button"
                  className="quantity-btn"
                  onClick={(e) => handleInputChange(e, "decrement")}
                >
                  -
                </button>
                <input
                  id="quantity"
                  type="number"
                  name="quantity"
                  min="1"
                  value={formData.quantity}
                  onChange={handleInputChange}
                  readOnly
                />
                <button
                  type="button"
                  className="quantity-btn"
                  onClick={(e) => handleInputChange(e, "increment")}
                >
                  +
                </button>
              </div>
            </div>

            <div className="subscription-B2B-group-payment_term">
              <label>{t('Talexa.subscriptionPage.paymentTermTitle')}</label>
              <select
                name="payment_term"
                value={formData.payment_term}
                onChange={handleInputChange}
                required
              >
                <option value="Monthly">{t('Talexa.subscriptionPage.paymentTermMonthly')}</option>
                <option value="Yearly">{t('Talexa.subscriptionPage.paymentTermYearly')}</option>
              </select>
            </div>
          </div>

          <div className="subscription-B2B-addon-block">
            <div className="subscription-B2B-addon-block_container">
              <div className="subscription-B2B-addon-block-title">
                <h3>{t('Talexa.titleAddOnsSubscription')}</h3>
              </div>
              <div className="subscription-B2B-addon-container">
                {addons.map((addOn) => (
                  <div
                    key={addOn}
                    className={`subscription-B2B-addon-container-item ${
                      formData[addOn] ? "selected" : ""
                    }`}
                    onClick={() =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        [addOn]: !prevFormData[addOn], // Bascule l'état de l'add-on
                      }))
                    }
                  >
                    <input
                      type="checkbox"
                      name={addOn}
                      checked={formData[addOn] || false}
                      onChange={(e) => e.stopPropagation()} // Empêche le clic de se propager au conteneur
                    />
                    <label>{t(`Talexa.addons.${addOn}.title`)}</label>
                    <div className="price-container">
                      <p className="price">
                        €{t(`Talexa.addons.${addOn}.price`)}
                      </p>
                      <p>/{t(`Talexa.addons.priceMonth`)}</p>
                      {t(`Talexa.addons.${addOn}.credit`) && (
                        <p className="credit">
                          {t(`Talexa.addons.${addOn}.credit`)} {t('Talexa.addons.priceCredit')}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="subscription-B2B-group-amount-group">
            {formData.payment_term === "Yearly" ? (
              <p className="subscription-B2B-group-amount-group-text_container">
                <span>{t(`Talexa.subscriptionPage.paymentTermTotalYear`)}</span>
                <span className="subscription-B2B-group-amount-group-final_amount">
                  {totalPrice} €
                </span>
              </p>
            ) : (
              <p className="subscription-B2B-group-amount-group-text_container">
                <span>{t(`Talexa.subscriptionPage.paymentTermTotalMonth`)}</span>
                <span className="subscription-B2B-group-amount-group-final_amount">
                  {totalPrice} €
                </span>
              </p>
            )}
          </div>

          <div className="subscription-B2B-button-container">
            <button type="submit" className="submit-button button">
            {t(`Talexa.subscriptionPage.addSubscriptionButton`)}
            </button>
          </div>
        </form>
      </section>

      {isModalVisible && (
        <SubscriptionModal
          onClose={() => setIsModalVisible(false)}
          actions={[
            {
              label: "Log In",
              onClick: () => {
                // saveFormDataToLocal();
                setIsModalVisible(false);
                navigate("/login", {
                  state: { redirectTo: "/b2b/subscription" },
                });
              },
            },
            {
              label: "Register",
              onClick: () => {
                // saveFormDataToLocal();
                setIsModalVisible(false);
                navigate("/signup", {
                  state: { redirectTo: "/b2b/subscription" },
                });
              },
            },
          ]}
        />
      )}
    </div>
  );
};

export default TalexaSubscriptionForm;
