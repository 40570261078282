import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './JobOfferDetails.scss';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../../../shared.js';

const JobOfferDetails = () => {
  const { id } = useParams();
  const [jobOffer, setJobOffer] = useState(null);
  const { t } = useTranslation();

  
        const [isEditing, setIsEditing] = useState(false);
        useEffect(() => {
            setIsEditing(window.location.pathname.includes("/edit"));
        
    fetchJobOfferDetails();
  }, [id]);

  const fetchJobOfferDetails = async () => {
    try {
      const response = await fetch(`${API_URL}/job-offers/${id}`, {
        credentials: 'include',
      }
      );
      if (response.ok) {
        const data = await response.json();
        console.log('Fetched job offer details:', data);
        setJobOffer(data);

        console.log('data', data); 
        console.log('JobOffer', jobOffer);




      } else {
        console.error('Failed to fetch job offer details');
      }
    } catch (error) {
      console.error('Error fetching job offer details:', error);
    }
  };

  if (!jobOffer) {
    return <div className="job-offer-details">{t('jobOfferDetails.loading')}</div>;
  }

  return (
    <div className="job-offer-details">
      <h2>{jobOffer.role} at {jobOffer.company_name}</h2>
      <div className="job-offer-section">
        <h3>{t('jobOfferDetails.sections.companyInfo.title')}</h3>
        <p>
          <strong>{t('jobOfferDetails.sections.companyInfo.companyName')}:</strong> 
          {jobOffer.company_name}
        </p>
        <p><strong>{t('jobOfferDetails.sections.companyInfo.companyDescription')}:</strong> {jobOffer.company_description}</p>
        <p><strong>{t('jobOfferDetails.sections.companyInfo.costCenter')}:</strong> {jobOffer.cost_center}</p>
      </div>
      <div className="job-offer-section">
        <h3>{t('jobOfferDetails.sections.jobDetails.title')}</h3>
        <p>
          <strong>{t('jobOfferDetails.sections.jobDetails.contractType')}:</strong>{' '}
          {jobOffer.contract_type?.toLowerCase().startsWith('jobopenings') 
            ? t(jobOffer.contract_type?.toLowerCase())
            : jobOffer.contract_type}
        </p>
        <p>
          <strong>{t('jobOfferDetails.sections.jobDetails.employmentType')}:</strong>{' '}
          {jobOffer.employment_type?.toLowerCase().startsWith('jobopenings') 
            ? t(jobOffer.employment_type?.toLowerCase())
            : jobOffer.employment_type}
        </p>
        <p><strong>{t('createJobOpening.placeholders.roleLevel')}</strong> {jobOffer.role_level}</p>
        <p><strong>{t('jobOfferDetails.sections.jobDetails.experience')}:</strong> {jobOffer.min_year_experience}</p>
        <p><strong>{t('jobOfferDetails.sections.jobDetails.workHours')}:</strong> {jobOffer.start_hour} - {jobOffer.end_hour}</p>
        <p><strong>{t('jobOfferDetails.sections.jobDetails.city')}:</strong> {jobOffer.city}</p>
        <p><strong>{t('jobOfferDetails.sections.jobDetails.country')}:</strong> {jobOffer.country}</p>
        <p><strong>{t('jobOfferDetails.sections.jobDetails.remote')}:</strong> {jobOffer.is_remote ? t('jobOfferDetails.yes') : t('jobOfferDetails.no')}</p>
        <p><strong>{t('jobOfferDetails.sections.jobDetails.hybrid')}:</strong> {jobOffer.is_hybrid ? t('jobOfferDetails.yes') : t('jobOfferDetails.no')}</p>
        <p><strong>{t('jobOfferDetails.sections.jobDetails.onsite')}:</strong> {jobOffer.onsite ? t('jobOfferDetails.yes') : t('jobOfferDetails.no')}</p>
      </div>
      <div className="job-offer-section">
        <h3>{t('jobOfferDetails.sections.jobDescription.title')}</h3>
        <p><strong>{t('jobOfferDetails.sections.jobDescription.mission')}:</strong> {jobOffer.mission}</p>
        <p><strong>{t('jobOfferDetails.sections.jobDescription.responsibilities')}:</strong> {jobOffer.responsibilities}</p>
        <p><strong>{t('jobOfferDetails.sections.jobDescription.requirements')}:</strong> {jobOffer.requirements}</p>
      </div>
      <div className="job-offer-section">
        <h3>{t('jobOfferDetails.sections.skills.title')}</h3>
        <ul>
          {jobOffer.skills.map((skill, index) => (
            <li key={index}>{skill}</li>
          ))}
        </ul>
      </div>
      <div className="job-offer-section">
        <h3>{t('jobOfferDetails.sections.compensation.title')}</h3>
        <p><strong>{t('jobOfferDetails.sections.compensation.salaryRange')}:</strong> ${jobOffer.min_salary} - ${jobOffer.max_salary}</p>
        <p><strong>{t('jobOfferDetails.sections.compensation.perks')}:</strong> {jobOffer.perks}</p>
      </div>
      <div className="job-offer-section">
        <h3>{t('jobOfferDetails.sections.additional.title')}</h3>
        <p><strong>{t('jobOfferDetails.sections.additional.hiringProcess')}:</strong> {jobOffer.hiring_process}</p>
        <p><strong>{t('jobOfferDetails.sections.additional.offerContent')}:</strong> {jobOffer.offer_content}</p>
        <p><strong>{t('jobOfferDetails.sections.additional.tone')}:</strong> {jobOffer.tone}</p>
      </div>
      <div className="job-offer-section">
        <h3>{t('jobOfferDetails.sections.metadata.title')}</h3>
        <p><strong>{t('jobOfferDetails.sections.metadata.createdAt')}:</strong> {new Date(jobOffer.created_at).toLocaleString()}</p>
        <p><strong>{t('jobOfferDetails.sections.metadata.updatedAt')}:</strong> {new Date(jobOffer.updated_at).toLocaleString()}</p>
      </div>
      <Link to="/b2b/dashboard/job-openings" className="back-button">
        {t('jobOfferDetails.backButton')}
      </Link>
    </div>
  );
};

export default JobOfferDetails;

