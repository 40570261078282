import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaUserPlus } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import "./AssesmentDetailPageHardSkills.scss";
import { API_URL } from "../../../../shared.js";
import SendAssessmentModal from "./composants/SendAssessmentModal.jsx";
import AnalysisLanguage from "./composants/AnalysisLanguage.jsx";
import { toast } from "react-hot-toast";
import AnalysisHardSkills from './composants/AnalysisHardSkills';

const AssesmentDetailPageHardSkills = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { id } = useParams();
  const [assessment, setAssessment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [answers, setAnswers] = useState({});
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [challengeAnswers, setChallengeAnswers] = useState({});

  // Safely access location.state
  const title = location.state?.title || '';
  const type = location.state?.type || 'Hard Skills Assessment';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_URL}/assessment/${id}/hard-skills`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
          }
        );
        const result = await response.json();
        if (result.success) {
          setAssessment(result.data.assessment);
        }
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const handleAnswerChange = (questionId, value) => {
    setAnswers({ ...answers, [questionId]: value });
  };

  const handleAnswerSelect = (questionId, value) => {
    setSelectedAnswers({ ...selectedAnswers, [questionId]: value });
  };

  const handleChallengeAnswer = (challengeId, value) => {
    setChallengeAnswers({ ...challengeAnswers, [challengeId]: value });
  };

  if (loading) {
    return (
      <div className="loading">
        <ClipLoader color="#123abc" loading={loading} size={50} />
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!assessment) {
    return <div>No assessment data available</div>;
  }

  return (
    <div className="assessment-page">
      <div className="assessment-header">
        <div className="header-top">
          <h1>{t('assessment.title', { title: assessment?.title })}</h1>
          <button 
            className="invite-btn"
            onClick={() => setShowModal(true)}
          >
            <span className="icon">+</span>
            {t('assessment.inviteCandidate')}
          </button>
        </div>
      </div>

      {showModal && (
        <div className="modal-overlay" onClick={() => setShowModal(false)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <SendAssessmentModal 
              onClose={() => setShowModal(false)}
              assessmentId={id}
            />
          </div>
        </div>
      )}

      <div className="assessment-details">
        <p className="purpose">{t('assessment.purpose', { purpose: assessment?.purpose })}</p>
        <div className="meta-info">
          <span>{t('assessment.sector')}: {assessment?.sector}</span>
          <span>{t('assessment.time')}: {assessment?.time}</span>
        </div>
      </div>

      {assessment?.sections?.map((section, sectionIndex) => (
        <div key={`section-${sectionIndex}`} className="section">
          <h2>{t('assessment.section', { title: section.title })}</h2>
          
          {section.challenges && section.challenges.length > 0 ? (
            // Challenges Section
            <div className="challenges-container">
              {section.challenges.map((challenge, challengeIndex) => (
                <div key={`challenge-${challengeIndex}`} className="challenge-block">
                  <h3>{challenge.title}</h3>
                  <p className="challenge-description">{challenge.description}</p>
                  
                  {/* Requirements */}
                  {challenge.requirements && (
                    <div className="requirements">
                      <h4>{t('assessment.challenge.requirements')}:</h4>
                      <ul>
                        {challenge.requirements.map((req, reqIndex) => (
                          <li key={`req-${reqIndex}`}>{req}</li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {/* Examples */}
                  {(challenge.sampleInput || challenge.sampleOutput) && (
                    <div className="examples">
                      {challenge.sampleInput && (
                        <div className="example-block">
                          <h4>{t('assessment.challenge.sampleInput')}:</h4>
                          <pre className="sample-data">{challenge.sampleInput}</pre>
                        </div>
                      )}
                      {challenge.sampleOutput && (
                        <div className="example-block">
                          <h4>{t('assessment.challenge.sampleOutput')}:</h4>
                          <pre className="sample-data">{challenge.sampleOutput}</pre>
                        </div>
                      )}
                    </div>
                  )}

                  {/* Time Limit */}
                  {challenge.timeLimit && (
                    <div className="time-limit">
                      <span>⏱️ {t('assessment.challenge.timeLimit')}: {challenge.timeLimit}</span>
                    </div>
                  )}

                  {/* Code Editor */}
                  <div className="code-editor">
                    <h4>{t('assessment.challenge.solution')}:</h4>
                    <textarea
                      className="code-textarea"
                      placeholder={t('assessment.challenge.writeSolution')}
                      value={challengeAnswers[`challenge-${challengeIndex}`] || ''}
                      onChange={(e) => handleChallengeAnswer(`challenge-${challengeIndex}`, e.target.value)}
                      spellCheck="false"
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            // Regular Questions Section
            <div className="questions-container">
              {section.questions?.map((question, questionIndex) => (
                <div key={`question-${questionIndex}`} className="question-block">
                  <h3>{t('assessment.question.number', { number: questionIndex + 1 })}</h3>
                  <p className="question-text">
                    {t('assessment.question.text', { text: question.question })}
                  </p>
                  <div className="options">
                    {question.options?.map((option) => (
                      <div key={`option-${option.id}`} className="option">
                        <input
                          type="radio"
                          id={`${question.id}-${option.id}`}
                          name={`question-${question.id}`}
                          value={option.id}
                          checked={selectedAnswers[question.id] === option.id}
                          onChange={() => handleAnswerSelect(question.id, option.id)}
                        />
                        <label htmlFor={`${question.id}-${option.id}`}>
                          {option.text}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      {assessment?.closingMessage && (
        <div className="closing-message">
          {t('assessment.closingMessage', { message: assessment.closingMessage })}
        </div>
      )}
    </div>
  );
};

export default AssesmentDetailPageHardSkills;
