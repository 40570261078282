import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import './i18n';
import './styles/reset.scss';
import './styles/index.scss';

const rootElement = document.getElementById('root');

// These are test logs
// console.log('Test log');
// console.debug('Test debug');
// console.info('Test info');
// console.warn('Test warning');
// console.error('Test error');

if (process.env.NODE_ENV !== "development") {
  console.log = function() {};
  console.debug =function() {};
  console.info = function() {};
  console.warn = function() {};
 console.error = function() {};
}

// S'il y a déjà du HTML dans #root (cas de prérendu), on "hydrate".
// Sinon, on fait un render classique.
if (rootElement.hasChildNodes()) {
  hydrateRoot(
    rootElement,
    <HelmetProvider>
      <App />
    </HelmetProvider>
  );
} else {
  createRoot(rootElement).render(
    <HelmetProvider>
      <App />
    </HelmetProvider>
  );
}
