import React from 'react';
import { useTranslation } from 'react-i18next';
import './ScreenAppOnboarding.scss';

const ScreenAppOnboarding = () => {
  const { t } = useTranslation();

  const handleActivateAccess = () => {
    window.open('https://screenapp.io/app/#/dashboard?joinCode=xRGPTra23t', '_blank');
  };

  return (
    <div className="screen-app-onboarding">
      <section className="partnership-section">
        <h2>{t('screenApp.partnershipTitle')}</h2>
        <p>{t('screenApp.partnershipDescription')}</p>
        <p>{t('screenApp.meetingUse')}</p>
        <p>{t('screenApp.otherMeetingUse')}</p>
        
        <h2>{t('screenApp.activationTitle')}</h2>
        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/BChLDzAFAWk"
            title="Screen App Activation Tutorial"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>

        <button 
          className="activate-button"
          onClick={handleActivateAccess}
        >
          {t('screenApp.activateAccess')}
        </button>
      </section>

      <section className="setup-section">
        <h2>{t('screenApp.setupTitle')}</h2>
        
        <div className="provider-subsection">
          <h3>{t('screenApp.googleMeet')}</h3>
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/VIdKDNTIcik"
              title="Google Meet Integration Tutorial"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>

        <div className="provider-subsection">
          <h3>{t('screenApp.otherProviders')}</h3>
          <p>{t('screenApp.otherProvidersDescription')}</p>
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/er80T2K-p08"
              title="Other Providers Integration Tutorial"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </section>

      <section className="analysis-section">
        <h2>{t('screenApp.analysisTitle')}</h2>
        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/pzUfJwx2zT4"
            title="Analysis Report Tutorial"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </section>
    </div>
  );
};

export default ScreenAppOnboarding; 