import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import "./CreateHardSkillsAssessment.scss";
import { API_URL } from "../../../../shared.js";
import { useTranslation } from "react-i18next";

const CreateHardSkillsAssessment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [jobOpenings, setJobOpenings] = useState([]);
  const [selectedJob, setSelectedJob] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("intermediate");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("english");
  const [loading, setLoading] = useState(false);
  const [focusSkills, setFocusSkills] = useState({
    skill1: '',
    skill2: '',
    skill3: ''
  });

  const levels = [
    { value: "entry", label: t("assessments.levels.entry") },
    { value: "intermediate", label: t("assessments.levels.intermediate") },
    { value: "senior", label: t("assessments.levels.senior") },
    { value: "expert", label: t("assessments.levels.expert") }
  ];

  const sectors = [
    { value: "technology", label: t("assessments.sectors.technology") },
    { value: "finance", label: t("assessments.sectors.finance") },
    { value: "healthcare", label: t("assessments.sectors.healthcare") },
    { value: "manufacturing", label: t("assessments.sectors.manufacturing") },
    { value: "retail", label: t("assessments.sectors.retail") },
    { value: "education", label: t("assessments.sectors.education") }
  ];

  const languages = [
    { value: "english", label: t("assessments.languages.English") },
    { value: "french", label: t("assessments.languages.French") },
    { value: "spanish", label: t("assessments.languages.Spanish") },
    { value: "german", label: t("assessments.languages.German") },
    { value: "portuguese", label: t("assessments.languages.Portuguese") }
  ];

  useEffect(() => {
    const fetchJobOpenings = async () => {
      try {
        const response = await fetch(`${API_URL}/job-offers`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setJobOpenings(data);
      } catch (err) {
        console.error("Error fetching job openings:", err);
      }
    };

    fetchJobOpenings();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const selectedJobOpening = jobOpenings.find(
      (job) => job.id === selectedJob
    );

    if (selectedJobOpening) {
      try {
        const queryParams = new URLSearchParams({
          title: selectedJobOpening.role,
          level: selectedLevel,
          sector: selectedSector,
          language: selectedLanguage,
          focusSkills: Object.values(focusSkills).filter(skill => skill.trim()).join(',')
        });

        const response = await fetch(
          `${API_URL}/hardskillsassessmentgenerated?${queryParams}`,
          {
            method: "GET",
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        navigate(`/b2b/dashboard/assessments/hard-skills/${result.analysisId}`);
      } catch (error) {
        console.error("Error generating assessment:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="create-hard-skills">
      <div className="create-hard-skills-header">
        <h2>{t("assessments.createHardSkills")}</h2>
      </div>
      
      <div className="create-hard-skills-content">
        <form onSubmit={handleSubmit} className="create-hard-skills-form">
          <div className="form-group">
            <label htmlFor="jobOpening">{t("assessments.selectJob")}</label>
            <select
              id="jobOpening"
              value={selectedJob}
              onChange={(e) => setSelectedJob(e.target.value)}
              required
            >
              <option value="" disabled>
                {t("assessments.selectJobOption")}
              </option>
              {jobOpenings.map((job) => (
                <option key={job.id} value={job.id}>
                  {job.role}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="level">{t("assessments.selectLevel")}</label>
            <select
              id="level"
              value={selectedLevel}
              onChange={(e) => setSelectedLevel(e.target.value)}
              required
            >
              {levels.map((level) => (
                <option key={level.value} value={level.value}>
                  {level.label}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="sector">{t("assessments.selectSector")}</label>
            <select
              id="sector"
              value={selectedSector}
              onChange={(e) => setSelectedSector(e.target.value)}
              required
            >
              <option value="" disabled>
                {t("assessments.selectSectorOption")}
              </option>
              {sectors.map((sector) => (
                <option key={sector.value} value={sector.value}>
                  {sector.label}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="language">{t("assessments.selectLanguage")}</label>
            <select
              id="language"
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
              required
            >
              {languages.map((language) => (
                <option key={language.value} value={language.value}>
                  {language.label}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group focus-skills">
            <label>{t("assessment.focusSkills.title")}</label>
            <p className="skills-description">{t("assessment.focusSkills.description")}</p>
            <div className="skills-inputs">
              {['skill1', 'skill2', 'skill3'].map((skillKey, index) => (
                <input
                  key={skillKey}
                  type="text"
                  value={focusSkills[skillKey]}
                  onChange={(e) => setFocusSkills(prev => ({
                    ...prev,
                    [skillKey]: e.target.value
                  }))}
                  placeholder={t(`assessment.focusSkills.${skillKey}`)}
                  required
                />
              ))}
            </div>
          </div>

          <div className="form-actions">
            <button type="submit" className="submit-button" disabled={loading}>
              {loading ? (
                <>
                  <ClipLoader color="#ffffff" size={20} />
                  <span>{t("assessment.generating")}</span>
                </>
              ) : (
                t("assessments.generateHardSkills")
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateHardSkillsAssessment;
