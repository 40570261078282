import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../components/common/Spinner/Spinner';
import './StructuredInterviewAIQuestionnaire.scss';
import { API_URL } from '../../../shared';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

const LANGUAGES = [
  { code: 'en', label: 'languages.en' },
  { code: 'fr', label: 'languages.fr' },
  { code: 'es', label: 'languages.es' },
  { code: 'de', label: 'languages.de' },
  { code: 'it', label: 'languages.it' },
  { code: 'pt', label: 'languages.pt' }
];

const StructuredInterviewAIQuestionnaire = () => {
  const { t } = useTranslation();
  const [jobOpenings, setJobOpenings] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [questionnaire, setQuestionnaire] = useState(null);
  
  const [formData, setFormData] = useState({
    jobOfferId: '',
    candidateId: '',
    duration: 30,
    language: 'en'
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [jobsResponse, candidatesResponse] = await Promise.all([
          fetch(`${API_URL}/job-offers`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            credentials: 'include'
          }),
          fetch(`${API_URL}/candidates`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            credentials: 'include'
          })
        ]);

        if (!jobsResponse.ok || !candidatesResponse.ok) {
          throw new Error('Failed to fetch data');
        }

        const jobsData = await jobsResponse.json();
        const candidatesData = await candidatesResponse.json();

        setJobOpenings(jobsData);
        setCandidates(candidatesData);
      } catch (err) {
        console.error('Fetch error:', err);
        setError(t('structuredInterview.errors.fetchFailed'));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [t]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    try {
      const response = await fetch(`${API_URL}/interview-questionnaires/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include',
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to generate questionnaire');
      }

      const data = await response.json();
      console.log('Received questionnaire:', data);
      
      if (data.success && data.data && data.data.questions) {
        const parsedQuestions = typeof data.data.questions === 'string' 
          ? JSON.parse(data.data.questions) 
          : data.data.questions;

        setQuestionnaire(parsedQuestions);
      } else {
        throw new Error('Invalid questionnaire data received');
      }
    } catch (err) {
      console.error('Generation error:', err);
      setError(t('structuredInterview.errors.generateFailed'));
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`${API_URL}/interview-questionnaires/save`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          questions: questionnaire,
          jobOfferId: formData.jobOfferId,
          candidateId: formData.candidateId,
          duration: formData.duration,
          language: formData.language
        })
      });

      if (!response.ok) {
        throw new Error('Failed to save questionnaire');
      }

      const result = await response.json();
      toast.success(t('structuredInterview.saveSuccess'));
      navigate('/b2b/dashboard/structured-interviews');
      
    } catch (err) {
      console.error('Save error:', err);
      toast.error(t('structuredInterview.errors.saveFailed'));
    }
  };

  return (
    <div className="body-ai-interview">
      <div className="structured-interview-questionnaire">
        <h2>{t('structuredInterview.title')}</h2>
        
        {error && <div className="error-message">{error}</div>}
        
        {loading ? (
          <Spinner />
        ) : (
          <form onSubmit={handleSubmit} className="ai-interview-questionnaire-form">
            <div className="ai-interview-form-group">
              <label>{t('structuredInterview.jobOpening')}</label>
              <select
                name="jobOfferId"
                value={formData.jobOfferId}
                onChange={handleInputChange}
                required
                className="select-job-option-ai-interview"
              >
                <option value="">{t('structuredInterview.selectJobOpeningPlaceholder')}</option>
                {jobOpenings.map(job => (
                  <option key={job.id} value={job.id}>{job.role}</option>
                ))}
              </select>
            </div>

            <div className="ai-interview-form-group">
              <label>{t('structuredInterview.candidate')}</label>
              <select
                name="candidateId"
                value={formData.candidateId}
                onChange={handleInputChange}
                required
                className="select-job-option-ai-interview"
              >
                <option value="">{t('structuredInterview.selectCandidatePlaceholder')}</option>
                {candidates.map(candidate => (
                  <option key={candidate.id} value={candidate.id}>
                    {`${candidate.first_name} ${candidate.last_name}`}
                  </option>
                ))}
              </select>
            </div>

            <div className="ai-interview-form-group">
              <label>{t('structuredInterview.duration')}</label>
              <input
                type="number"
                name="duration"
                min="15"
                max="120"
                step="15"
                value={formData.duration}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="ai-interview-form-group">
              <label>{t('structuredInterview.language')}</label>
              <select
                name="language"
                value={formData.language}
                onChange={handleInputChange}
                required
                className="select-job-option-ai-interview"
              >
                {LANGUAGES.map(lang => (
                  <option key={lang.code} value={lang.code}>
                    {t(lang.label)}
                  </option>
                ))}
              </select>
            </div>

            <button 
              type="submit" 
              className="generate-button"
              disabled={loading}
            >
              {loading ? t('common.generating') : t('structuredInterview.generate')}
            </button>
          </form>
        )}

        {questionnaire && (
          <div className="questionnaire-result">
            <h3>{t('structuredInterview.questions')}</h3>
            
            <div className="questions-section">
              <h4>{t('structuredInterview.opening')}</h4>
              {questionnaire.openingQuestions?.map((question, index) => (
                <div key={index} className="question-card">
                  <p className="question-text">{question.question}</p>
                  <div className="question-purpose">
                    <em>{question.purpose}</em>
                  </div>
                </div>
              ))}
            </div>

            <div className="questions-section">
              <h4>{t('structuredInterview.main')}</h4>
              {questionnaire.mainQuestions?.map((question, index) => (
                <div key={index} className="question-card">
                  <div className="importance-badge">
                    {t('structuredInterview.importance')}: {question.importance}/5
                  </div>
                  <p className="question-text">{question.question}</p>
                  <p className="question-type">
                    {t(`structuredInterview.type.${question.type}`)}
                  </p>
                  <div className="evaluation-criteria">
                    <h5>{t('structuredInterview.evaluationCriteria')}</h5>
                    <ul>
                      {question.criteria?.map((criterion, idx) => (
                        <li key={idx}>{criterion}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>

            <div className="questions-section">
              <h4>{t('structuredInterview.closing')}</h4>
              {questionnaire.closingQuestions?.map((question, index) => (
                <div key={index} className="question-card">
                  <p className="question-text">{question.question}</p>
                  <div className="question-purpose">
                    <em>{question.purpose}</em>
                  </div>
                </div>
              ))}
            </div>

            <div className="save-questionnaire-container">
              <button 
                className="save-button"
                onClick={handleSave}
                disabled={!questionnaire}
              >
                {t('structuredInterview.save')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StructuredInterviewAIQuestionnaire; 