import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import "./AnalysisHardSkills.scss";
import { API_URL } from "../../../../../shared.js";

const AnalysisHardSkills = ({ assessmentId, onClose }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      console.log("Fetching hard skills analysis for assessmentId:", assessmentId);
      try {
        const response = await fetch(`${API_URL}/assessment/hard-skills/${assessmentId}`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const result = await response.json();
        console.log("Result analysis:", result);

        const parsedAnalysis = JSON.parse(result.analysis_content);
        setData(parsedAnalysis);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [assessmentId]);

  return (
    <div className="assessment-page-analysis">
      <div className="header">
        <h1>Hard-Skills Assessment Analysis</h1>
        <button className="close-btn" onClick={onClose}>
          <FaTimes />
        </button>
      </div>
    
      {loading ? (
        <p className="loading-message">Loading analysis...</p>
      ) : error ? (
        <p className="error-message">Error: {error}</p>
      ) : (
        <div className="analysis-report">
          <h2>Evaluation Report</h2>
    
          <section className="performance-summary">
            <h3>Overall Performance Summary</h3>
            <div className="summary-cards">
              <div className="card">
                <h4>Score</h4>
                <p>{data.totalScore}</p>
              </div>
              <div className="card">
                <h4>Total Questions</h4>
                <p>{data.totalQuestions}</p>
              </div>
              <div className="card">
                <h4>Percentage</h4>
                <p>{data.percentage}</p>
              </div>
            </div>
          </section>
    
          <section className="global-analysis">
            <h3>Global Analysis</h3>
            <div className="analysis-item">
              <h4>Strengths</h4>
              <p>{data.globalAnalysis.strengths}</p>
            </div>
            <div className="analysis-item">
              <h4>Weaknesses</h4>
              <p>{data.globalAnalysis.weaknesses}</p>
            </div>
            <div className="analysis-item">
              <h4>Recommendations</h4>
              <p>{data.globalAnalysis.recommendations}</p>
            </div>
          </section>
    
          <section className="detailed-evaluation">
            <h3>Detailed Evaluation</h3>
            <table className="evaluation-table">
              <thead>
                <tr>
                  <th>Question</th>
                  <th>Your Answer</th>
                  <th>Correct Answer</th>
                  <th>Score</th>
                  <th>Explanation</th>
                </tr>
              </thead>
              <tbody>
                {data.results.map((item, index) => (
                  <tr key={index} className={item.score === 1 ? "correct" : "incorrect"}>
                    <td>{item.question}</td>
                    <td>{item.candidateAnswer}</td>
                    <td>{item.correctAnswer}</td>
                    <td>{item.score}/1</td>
                    <td>{item.explanation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </div>
      )}
    </div>
  );
};

export default AnalysisHardSkills; 