import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { API_URL } from '../../../shared';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const BulkUploadUsers = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [error, setError] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isFileUpload, setIsFileUploaded] = useState(false);

    const styles = {
        container: {
            width: '100%', 
            maxWidth: '1000px',
            margin: '0 auto',
            padding: '24px'
        },
        uploadSection: {
            border: '2px dashed #ccc',
            borderRadius: '8px', 
            padding: '32px',
            textAlign: 'center'
        },
        button: {
            padding: '8px 16px',
            backgroundColor: '#2563eb',
            color: 'white',
            border: 'none',
            borderRadius: '6px',
            cursor: 'pointer',
            fontSize: '14px'
        },
        saveButton: {
            padding: '10px 20px',
            backgroundColor: '#10B981',
            color: 'white',
            border: 'none',
            borderRadius: '6px',
            cursor: 'pointer',
            fontSize: '14px',
            marginTop: '16px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
        },
        disabledButton: {
            opacity: 0.6,
            cursor: 'not-allowed'
        },
        helpText: {
            marginTop: '15px',
            fontSize: '14px',
            color: '#666'
        },
        error: {
            backgroundColor: '#fef2f2',
            border: '1px solid #fee2e2', 
            color: '#dc2626',
            padding: '12px 16px',
            borderRadius: '6px',
            marginTop: '16px'
        },
        tableContainer: {
            overflowX: 'auto',
            marginTop: '24px'
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            backgroundColor: 'white',
            border: '1px solid #e5e7eb'
        },
        th: {
            padding: '12px 16px',
            textAlign: 'left',
            fontSize: '12px',
            fontWeight: '600',
            color: '#6b7280',
            backgroundColor: '#f9fafb',
            borderBottom: '1px solid #e5e7eb',
            textTransform: 'uppercase'
        },
        td: {
            padding: '12px 16px',
            fontSize: '14px',
            color: '#111827',
            borderBottom: '1px solid #e5e7eb',
            whiteSpace: 'nowrap'
        },
        evenRow: {
            backgroundColor: '#ffffff'
        },
        oddRow: {
            backgroundColor: '#f9fafb'
        }
    };

    const processExcel = async (file) => {
        try {
            const arrayBuffer = await file.arrayBuffer();
            const workbook = XLSX.read(arrayBuffer, {
                type: 'array',
                cellDates: true,
                cellNF: false,
                cellText: false
            });

            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(firstSheet, {
                header: 1,
                defval: '',
                blankrows: false
            });

            if (jsonData.length < 2) {
                throw new Error('File appears to be empty or invalid');
            }

            const headers = jsonData[0];
            const rows = jsonData.slice(1);

            setColumns(headers);
            setData(rows);
            setIsFileUploaded(true);
            setError('');
        } catch (err) {
            setError('Error processing file: ' + err.message);
            setData([]);
            setColumns([]);
        }
    };

    const processCSV = (file) => {
        Papa.parse(file, {
            complete: (results) => {
                if (results.data.length < 2) {
                    setError('File appears to be empty or invalid');
                    return;
                }
                setColumns(results.data[0]);
                setData(results.data.slice(1).filter(row => row.some(cell => cell !== '')));
                setIsFileUploaded(true);
                setError('');
            },
            header: false,
            skipEmptyLines: true,
            error: (err) => {
                setError('Error processing CSV: ' + err.message);
                setData([]);
                setColumns([]);
            }
        });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const fileExt = file.name.split('.').pop().toLowerCase();
        if (!['xlsx', 'xls', 'csv'].includes(fileExt)) {
            setError('Please upload only Excel (.xlsx, .xls) or CSV files');
            return;
        }

        if (fileExt === 'csv') {
            processCSV(file);
        } else {
        processExcel(file);
        }
    };

    const handleSave = async () => {
        if (!data.length) return;
        
        try {
            setIsSaving(true);
            const response = await fetch(`${API_URL}/user/bulk-upload`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                headers: columns,
                data: data
            }),
            credentials: 'include',
            });

            if (!response.ok) throw new Error('Failed to save data');
            toast.success('Data saved successfully!')

            navigate("/admin/dashboard/users");
        } catch (err) {
            setError('Failed to save: ' + err.message);
        } finally {
            setIsSaving(false);
        }
    };

    return (
    <div style={styles.container}>
        <div style={styles.uploadSection}>
        <input
            type="file"
            accept=".xlsx,.xls,.csv"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="file-upload"
        />
        <label htmlFor="file-upload" style={styles.button}>
            Choose File
        </label>
        <p style={styles.helpText}>
            Supports .xlsx, .xls and .csv files
        </p>
        </div>
        <button 
            onClick={handleSave}
            disabled={!isFileUpload}
            style={{
                ...styles.saveButton,
                ...(!isFileUpload ? styles.disabledButton : {})
            }}
            >
            {isSaving ? 'Saving...' : 'Save Data'}
        </button>

        {error && (
        <div style={styles.error}>
            {error}
        </div>
        )}

        {data.length > 0 && (
        <>
            <div style={styles.tableContainer}>
            <table style={styles.table}>
                <thead>
                <tr>
                    {columns.map((header, index) => (
                    <th key={index} style={styles.th}>
                        {header}
                    </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex} style={rowIndex % 2 === 0 ? styles.evenRow : styles.oddRow}>
                    {row.map((cell, cellIndex) => (
                        <td key={cellIndex} style={styles.td}>
                        {cell?.toString() || ''}
                        </td>
                    ))}
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
        </>
        )}
    </div>
    );
};

export default BulkUploadUsers;