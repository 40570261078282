import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import "./TalexaChatB2B.scss";

import plusIcon from "../../../assets/plusBlue.png";

import { logError } from "../../../services/errorLogging";

import ReactMarkdown from "react-markdown";

import remarkGfm from "remark-gfm";

import { API_URL } from "../../../shared";

import { addCandidateToPipeline } from '../../../api/candidateApi';

import { jsPDF } from 'jspdf';

// Add the detectLanguage helper function here, before the component definition
const detectLanguage = (text) => {
  const languagePatterns = {
    en: /\b(Role|Company Name|Skills)\b/i,
    fr: /\b(Rôle|Nom de l'entreprise|Compétences)\b/i,
    es: /\b(Rol|Nombre de la empresa|Habilidades)\b/i,
    it: /\b(Ruolo|Nome dell'azienda|Competenze)\b/i,
    de: /\b(Rolle|Firmenname|Fähigkeiten)\b/i,
    pt: /\b(Cargo|Nome da empresa|Habilidades)\b/i
  };

  for (const [lang, pattern] of Object.entries(languagePatterns)) {
    if (pattern.test(text)) {
      return lang;
    }
  }
  return 'unknown';
};

// Utility function to shorten LinkedIn URL
const shortenLinkedInUrl = (url) => {
  if (!url) return 'No LinkedIn profile';

  // Extract the profile ID part
  const match = url.match(/\/in\/([^/?]+)/);

  if (match && match[1]) {
    return `https://www.linkedin.com/in/${match[1]}/`;
  }

  // Fallback to original URL if no match
  return url;
};

const TalexaB2B = ({ user, navigate }) => {
  const { t, i18n } = useTranslation();

  const [messages, setMessages] = useState([]);

  const [inputMessage, setInputMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [conversations, setConversations] = useState([]);

  const [currentConversationId, setCurrentConversationId] = useState(null);

  const [hiringRequests, setHiringRequests] = useState([]);

  const [regenerating, setRegenerating] = useState(false);

  const [isComparingResumes, setIsComparingResumes] = useState(false);
  const [selectedJobForResumes, setSelectedJobForResumes] = useState(null);

  const [isInResumeComparisonFlow, setIsInResumeComparisonFlow] = useState(false);

  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);

  const [showActionButtons, setShowActionButtons] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [manualQuestionField, setManualQuestionField] = useState(null);

  const [jobOfferData, setJobOfferData] = useState({});

  const [jobOpenings, setJobOpenings] = useState([]);

  const [isSearchingCandidates, setIsSearchingCandidates] = useState(false);

  const [candidateResults, setCandidateResults] = useState([]);

  const [selectedJob, setSelectedJob] = useState(null);

  const AIURL = process.env.REACT_APP_AI_URL || "http://localhost:5001";

  // Add new state for candidate selection modal
  const [showCandidateSelectionModal, setShowCandidateSelectionModal] = useState(false);
  const [selectedJobOpening, setSelectedJobOpening] = useState(null);
  const [candidateSelectionMethod, setCandidateSelectionMethod] = useState(null);

  // Add new state for tracking job selection in resume comparison
  const [selectedCompareJob, setSelectedCompareJob] = useState(null);

  // Add new state for My Pool candidates
  const [myPoolCandidates, setMyPoolCandidates] = useState([]);

  const [chatMessages, setChatMessages] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const [fetchedUser, setFetchedUser] = useState(null);

  // Add these state variables at the top with other state declarations
  const [currentSearchQuery, setCurrentSearchQuery] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Fetch user profile to get user ID and tenant ID
        const profileResponse = await fetch('/api/profile', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          credentials: 'include',
        });

        if (!profileResponse.ok) {
          throw new Error('Failed to fetch user profile');
        }

        const profileData = await profileResponse.json();
        const userId = profileData.id;
        const tenantId = profileData.tenant_id;

        console.log('User ID:', userId);
        console.log('Tenant ID:', tenantId);

        setFetchedUser({ userId, tenantId });
      } catch (error) {
        console.error('Error in fetchUserData:', error);
        // Handle error...
      }
    };

    fetchUserData();
  }, [user, setMessages]); // Add setMessages to dependencies

  // Add a debug effect to monitor fetchedUser changes
  useEffect(() => {
    console.log('fetchedUser updated:', fetchedUser);
  }, [fetchedUser]);

  console.log('Fetched User:', fetchedUser);
  // Function to save job offer data

  const saveJobOfferData = async (data) => {

    try {

      // Prepare AI-specific metadata

      const aiJobOfferData = {

        ...data,

        is_ai_generated: true,

        source: "TalexaChatB2B", // Adding source metadata for identification

        timestamp: new Date().toISOString(),

      };



      console.log("Prepared AI job offer data:", aiJobOfferData);



      const response = await fetch(`${API_URL}/save-job-offer`, {

        method: "POST",

        headers: {

          "Content-Type": "application/json",

        },

        credentials: "include",

        body: JSON.stringify({

          jobOfferData: aiJobOfferData,

        }),

      });



      if (!response.ok) {

        console.error("API request failed with status:", response.status);

        throw new Error("Failed to process job offer data");

      }



      const result = await response.json();

      console.log("API response:", result);



      // Store the data with the AI metadata in localStorage

      localStorage.setItem(

        result.storageKey,

        JSON.stringify(result.dataToSave)

      );

      console.log("Stored data in localStorage with key:", result.storageKey);



      // Also store the pre-filled data for further use

      localStorage.setItem(

        "preFilledJobOfferData",

        JSON.stringify(result.dataToSave)

      );

      console.log("Stored pre-filled job offer data in localStorage");



      // Redirect to the next page after successful data save

      window.location.href = "/b2b/dashboard/job-openings/create";

      console.log("Redirecting to job creation page...");

    } catch (error) {

      console.error("Error saving job offer data:", error);

    }

  };


  // Language detection and i18n configuration
  useEffect(() => {
    const browserLang = navigator.language.split("-")[0];
    const supportedLanguages = ['en', 'fr', 'es', 'it', 'de', 'pt'];

    if (browserLang && supportedLanguages.includes(browserLang)) {
      i18n.changeLanguage(browserLang);
    }
  }, []);

  // Message initial dans la langue détectée

  useEffect(() => {

    setMessages([

      {

        role: "assistant",

        content: t("talexaB2B.initialPrompt"),

      },

    ]);

  }, [t, i18n.language]); // Dépendance à t et à la langue



  // Scroll to bottom of messages

  const scrollToBottom = () => {

    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

  };



  useEffect(() => {

    scrollToBottom();

  }, [messages]);



  // Load conversations on mount

  useEffect(() => {

    const loadConversations = async () => {

      try {

        const token = localStorage.getItem("token");

        const response = await fetch("/api/conversations", {

          headers: {

            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",

          },

        });



        if (!response.ok) {

          throw new Error(`HTTP error! status: ${response.status}`);

        }



        const data = await response.json();

        setConversations(data);

      } catch (error) {

        console.error("Error loading conversations:", error);

        logError(error);

      }

    };



    if (user?.id) {

      loadConversations();

    }

  }, [user]);


  // Fetch job openings

  useEffect(() => {

    const fetchJobOpenings = async () => {

      try {

        const response = await fetch('/api/job-offers', {

          headers: {

            Authorization: `Bearer ${localStorage.getItem("token")}`,

          },

        });

        if (response.ok) {

          const data = await response.json();

          setJobOpenings(data);

        }

      } catch (error) {

        console.error("Error fetching job openings:", error);

      }

    };



    fetchJobOpenings();

  }, []);



  // Handle search candidates action
  const handleSearchCandidates = () => {
    setIsSearchingCandidates(true);

    const jobOptions = jobOpenings.map((job, index) => ({
      id: `job-${index}`,
      label: job.role,
      description: job.is_ai_generated ? '(AI Generated)' : '',
      type: "select"
    }));

    setMessages((prev) => [
      ...prev,
      {
        role: "assistant",
        content: t("talexaB2B.manualQuestions.selectJob"),
        component: "jobSelection",
        jobs: jobOpenings,
        onSelect: handleJobSelection
      },
    ]);
  };

  // Fetch hiring requests

  useEffect(() => {

    const fetchHiringRequests = async () => {

      try {

        const response = await fetch("/api/hiring-requests");

        if (response.ok) {

          const data = await response.json();

          setHiringRequests(data);

        } else {

          throw new Error("Failed to fetch hiring requests");

        }

      } catch (error) {

        console.error("Error fetching hiring requests:", error);

        logError(error);

      }

    };



    fetchHiringRequests();

  }, []);

  // Move this before any useEffect that uses it
  const handleAddToPipeline = useCallback(async (candidate, message) => {
    try {
      console.log('handleAddToPipeline called with:', { candidate, message }); // Debug log
      const [firstName, lastName] = (candidate.Name || '').split(' ');

      const candidateData = {
        firstName,
        lastName,
        currentTitle: candidate["Most Recent Experience"]?.split('|')[0]?.trim() || '',
        currentCompany: '-',
        sourcedFor: message?.jobData?.role || "General Pool",
        jobOfferId: message?.jobData?.id, // Make sure this is being passed
        sourcedFrom: "LinkedIn",
        skills: Array.isArray(candidate.Skills) ? candidate.Skills : [],
        headline: candidate.Headline || '',
        linkedinUrl: candidate["LinkedIn Profile Link"] || ''
      };

      await addCandidateToPipeline(candidateData);

      // Show success message
      setMessages(prev => [
        ...prev,
        {
          role: "assistant",
          content: t("talexaB2B.candidateAddedToPipeline", {
            name: candidate.Name || 'Candidate'
          })
        }
      ]);
    } catch (error) {
      console.error("Error adding candidate to pipeline:", error);
      setMessages(prev => [
        ...prev,
        {
          role: "assistant",
          content: `${t("talexaB2B.errorAddingCandidate")} (${error.message})`
        }
      ]);
    }
  }, [t]);

  const handleNewChat = async () => {

    try {

      setLoading(true);

      const token = localStorage.getItem("token");



      const response = await fetch("/api/conversations", {

        method: "POST",

        headers: {

          "Content-Type": "application/json",

          Authorization: `Bearer ${token}`,

        },

        body: JSON.stringify({

          tenantId: user?.tenantId,

        }),

      });



      if (response.ok) {

        const newConversation = await response.json();

        setCurrentConversationId(newConversation.id);

        setMessages([

          {

            role: "assistant",

            content: t("talexaB2B.welcomeMessage"),

          },

        ]);

        setConversations([newConversation, ...conversations]);

      }

    } catch (error) {

      logError(error);

    } finally {

      setLoading(false);

      setInputMessage("");

    }

  };

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (!inputMessage.trim() || loading) return;

    try {
      setLoading(true);

      const newMessages = [
        ...messages,
        { role: "user", content: inputMessage },
      ];

      setMessages(newMessages);
      setInputMessage("");

      // NEW: Check for candidate source selection context
      const lastMessage = messages[messages.length - 1];

      // Check if we're waiting for candidate selection
      if (lastMessage.type === 'candidateList' &&
        lastMessage.candidates &&
        lastMessage.candidates.length > 0 &&
        lastMessage.awaitingCandidateSelection) {
        // Parse the input as a candidate index
        const candidateIndex = parseInt(inputMessage.trim(), 10);
        const candidates = lastMessage.candidates;

        // Check if the input is a valid candidate index
        if (candidateIndex > 0 && candidateIndex <= candidates.length) {
          const selectedCandidate = candidates[candidateIndex - 1];
          // Call the handleCandidateSelection method with isSharedPool flag
          handleCandidateSelection(selectedCandidate, lastMessage.isSharedPool);
          return;
        } else {
          // Invalid candidate selection
          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: "Invalid candidate selection. Please choose a number from the list.",
              type: 'error'
            }
          ]);
          return;
        }
      }

      // Check if user selected My Pool (1), Shared Pool (2), or Upload Resume (3)
      if (lastMessage.component === "selection" &&
        lastMessage.options?.some(opt => opt.id === "myPool" || opt.id === "sharedPool" || opt.id === "uploadResume")) {
        if (inputMessage.trim() === '1' || inputMessage.trim() === '2') {
          try {
            const isSharedPool = inputMessage.trim() === '2';
            const apiEndpoint = isSharedPool
              ? '/api/candidates/shared'
              : '/api/candidates';

            const response = await fetch(apiEndpoint, {
              method: "GET",
              credentials: "include",
              headers: {
                'Content-Type': 'application/json',
              }
            });

            if (!response.ok) {
              throw new Error(`Failed to fetch ${isSharedPool ? 'shared' : 'my'} pool candidates`);
            }

            const candidates = await response.json();

            // Format candidates for display
            const dropdownContent = (
              <CandidateDropdown
                candidates={candidates}
                onSelect={(value) => handleDropdownSelection(value, lastMessage)}
              />
            );

            setMessages((prev) => [
              ...prev,
              {
                role: "assistant",
                content: `Candidates from ${isSharedPool ? 'Shared' : 'My'} Pool (Total: ${candidates.length}):\n\n`,
                type: 'candidateList',
                candidates: candidates,
                awaitingCandidateSelection: true,
                isSharedPool: isSharedPool,
                component: "candidate-selection"
              }
            ]);
            return;  // Add return here to prevent further processing
          } catch (error) {
            setMessages((prev) => [
              ...prev,
              {
                role: "assistant",
                content: `Error retrieving candidates: ${error.message}`,
                type: 'error'
              }
            ]);
            return;  // Add return here as well
          }
        } else if (
          // Check if a candidate is being selected after initial pool selection
          lastMessage.type === 'candidateList' &&
          lastMessage.candidates &&
          lastMessage.candidates.length > 0 &&
          lastMessage.awaitingCandidateSelection
        ) {
          // Parse the input as a candidate index
          const candidateIndex = parseInt(inputMessage.trim(), 10);
          const candidates = lastMessage.candidates;

          // Check if the input is a valid candidate index
          if (candidateIndex > 0 && candidateIndex <= candidates.length) {
            const selectedCandidate = candidates[candidateIndex - 1];

            // Call the handleCandidateSelection method
            handleCandidateSelection(selectedCandidate);
          } else {
            // Invalid candidate selection
            setMessages((prev) => [
              ...prev,
              {
                role: "assistant",
                content: "Invalid candidate selection. Please choose a number from the list.",
                type: 'error'
              }
            ]);
          }
        } else if (inputMessage.trim() === '3') {
          // Upload Resume option
          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: t('talexaB2B.candidateResume'),
              component: "upload-resume",
              accept: ".pdf",
              multiple: false
            }
          ]);
          return;
        }
      }

      // Check if input matches a job selection (e.g., "1", "2", etc.)

      const selectedIndex = parseInt(inputMessage.trim()) - 1;

      if (isSearchingCandidates && !isNaN(selectedIndex) && selectedIndex >= 0 && selectedIndex < jobOpenings.length) {
        setIsSearchingCandidates(true); // Add this line to set the state to true when a job is selected

        const selectedJob = jobOpenings[selectedIndex];

        try {
          // Show initial message
          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: t("talexaB2B.findCandidates", { role: selectedJob.role }),
            },
          ]);

          // Show loading message first
          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: t("talexaB2B.searchingCandidates"),
              type: "loading"
            }
          ]);

          // Validate search query
          if (!selectedJob.role) {
            setMessages((prev) => [
              ...prev,
              {
                role: "assistant",
                content: t("talexaB2B.invalidSearchQuery"),
              },
            ]);
            setIsSearchingCandidates(false);
            return;
          }

          console.log('Selected Job:', selectedJob);

          // First fetch complete job details
          const jobResponse = await fetch(`${API_URL}/job-offers/${selectedJob.id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            credentials: 'include'
          });

          if (!jobResponse.ok) {
            throw new Error('Failed to fetch job details');
          }

          const jobDetails = await jobResponse.json();
          console.log('Complete Job Details:', jobDetails);

          // Get skills from the complete job details
          const jobSkills = jobDetails.skills || [];
          console.log('Job Skills:', jobSkills);

          // Construct the query string with role and skills only
          const skillsString = jobSkills.length > 0 ? ` ${jobSkills.join(", ")}` : "";
          const queryString = `${selectedJob.role}${skillsString}`;
          console.log('Search Query:', queryString);
          console.log('Location:', jobDetails.city);
          console.log('Experience:', jobDetails.min_year_experience);

          console.log('job_offer_id:', selectedJob.id);
          console.log('Fetched User:', fetchedUser); // Check if fetchedUser is null
          console.log('User ID:', fetchedUser?.userId); // Check if userId is available
          console.log('Tenant ID:', fetchedUser?.tenantId); // Check if tenantId is available

          // Use our backend proxy endpoint for LinkedIn search
          const response = await fetch(`${API_URL}/linkedin/search`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            credentials: 'include',
            body: JSON.stringify({
              query: queryString,
              location: jobDetails.city,
              experience: jobDetails.min_year_experience,
              job_offer_id: selectedJob.id,
              user_id: fetchedUser?.userId,
              tenant_id: fetchedUser?.tenantId
            })
          });

          const data = await response.json();

          if (!response.ok) {
            throw new Error(data.error || 'Failed to fetch candidates');
          }

          // Display no candidates message if the API returns empty results
          const candidates = data.candidates || data;

          if (!candidates || candidates.length === 0) {
            setMessages((prev) => [
              ...prev,
              {
                role: "assistant",
                content: t("talexaB2B.noCandidatesFound"),
              },
            ]);
            setIsSearchingCandidates(false);
            return;
          }

          // Display each candidate's details
          candidates.forEach((candidate, index) => {
            const candidateName = candidate.Name !== 'N/A' ? candidate.Name : `${t("talexaB2B.candidate")} ${index + 1}`;
            console.log('Adding candidate to messages with job data:', { candidate, selectedJob }); // Debug log

            setMessages(prev => [
              ...prev,
              {
                role: "assistant",
                content: `**${t("talexaB2B.candidate")} ${index + 1}:**
**${t("talexaB2B.experience")}:** ${candidate["Most Recent Experience"] || t("notSpecified")}
**${t("talexaB2B.skills")}:** ${candidate.Skills?.length > 0 ? candidate.Skills.join(", ") : t("notListed")}`,
                type: 'candidate',
                candidateData: candidate,
                jobData: {
                  role: selectedJob.role,  // Explicitly include only what we need
                  id: selectedJob.id
                },
                showAddButton: true
              }
            ]);
          });

          // Reset search state after displaying results
          setIsSearchingCandidates(false);
          return; // Add return to prevent default message
        } catch (error) {
          console.error("Search error:", error);
          let errorMessage = error.message;

          try {
            // Try to parse the error response for more details
            const errorData = await error.response?.json();
            if (errorData?.details) {
              errorMessage = `${errorData.error}: ${errorData.details}`;
            }
          } catch (e) {
            // If we can't parse the error response, just use the original error message
          }

          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: `${t("talexaB2B.searchError")}: ${errorMessage}. ${t("talexaB2B.tryAgain")}`,
            },
          ]);
          setIsSearchingCandidates(false);
          return; // Add return to prevent default message
        }
      }

      if (isComparingResumes && !isNaN(selectedIndex) && selectedIndex >= 0 && selectedIndex < jobOpenings.length) {
        const selectedJob = jobOpenings[selectedIndex];
        setSelectedJobForResumes(selectedJob);
        setIsComparingResumes(false);
        setIsInResumeComparisonFlow(true); // Set the flag when entering resume comparison flow

        // Prompt for resume upload
        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: `${t("talexaB2B.candidateSourceSelection")}\n\n${t("talexaB2B.candidateSource.myPool")}: ${t("talexaB2B.candidateSource.myPoolDescription")}\n${t("talexaB2B.candidateSource.sharedPool")}: ${t("talexaB2B.candidateSource.sharedPoolDescription")}\n${t("talexaB2B.candidateSource.uploadResume")}: ${t("talexaB2B.candidateSource.uploadResumeDescription")}`,
            component: "selection",
            options: [
              {
                id: "myPool",
                label: t("talexaB2B.candidateSource.myPool"),
                description: t("talexaB2B.candidateSource.myPoolDescription"),
                type: "select"
              },
              {
                id: "sharedPool",
                label: t("talexaB2B.candidateSource.sharedPool"),
                description: t("talexaB2B.candidateSource.sharedPoolDescription"),
                type: "select"
              },
              {
                id: "uploadResume",
                label: t("talexaB2B.candidateSource.uploadResume"),
                description: t("talexaB2B.candidateSource.uploadResumeDescription"),
                type: "file-upload",
                accept: ".pdf,.doc,.docx",
                multiple: false
              }
            ],
            onSelect: (selectedOption) => {
              switch (selectedOption) {
                case "myPool":
                  // Logic for My Pool selection
                  setMessages((prev) => [
                    ...prev,
                    {
                      role: "assistant",
                      content: t("talexaB2B.messages.openingMyPool")
                    }
                  ]);
                  break;
                case "sharedPool":
                  // Logic for Shared Pool selection
                  setMessages((prev) => [
                    ...prev,
                    {
                      role: "assistant",
                      content: t("talexaB2B.messages.openingSharedPool")
                    }
                  ]);
                  break;
                case "uploadResume":
                  // Trigger file upload
                  setMessages((prev) => [
                    ...prev,
                    {
                      role: "assistant",
                      content: t("talexaB2B.manualQuestions.uploadResumesForComparison"),
                      component: "file-upload",
                      accept: ".pdf,.docx,.doc",
                      multiple: false,
                      onUpload: handleResumeUpload
                    }
                  ]);
                  break;
              }
            }
          }
        ]);
        return;
      }

      if (!isNaN(selectedIndex) && selectedIndex >= 0 && selectedIndex < hiringRequests.length && !isSearchingCandidates) {
        const job = hiringRequests[selectedIndex];
        const res = await fetch(`/api/hiring-requests/${job.id}`);
        const det = await res.json();

        // Store job details
        setJobOfferData({
          role: det.role,
          department: det.department,
          costCenter: det.cost_center,
          contractType: det.contract_type,
          employmentType: det.employment_type
        });

        // Only show city prompt if we're in the job offer generation flow
        // and not in candidate selection or resume comparison mode
        if (!lastMessage?.type?.includes('candidate') &&
          !lastMessage?.type?.includes('candidateList') &&
          !lastMessage?.awaitingCandidateSelection &&
          !isInResumeComparisonFlow) {
          setMessages(prev => [...prev, { role: "assistant", content: t("talexaB2B.manualQuestions.enterCity") }]);
          setManualQuestionField("city");
        }

        return;
      }



      // Handle responses to manual questions

      if (manualQuestionField) {

        const updatedJobOfferData = { ...jobOfferData };

        updatedJobOfferData[manualQuestionField] = inputMessage;

        setJobOfferData(updatedJobOfferData);



        // Determine next field to ask

        let nextField = null;

        let nextQuestion = "";



        switch (manualQuestionField) {

          case "city":

            nextField = "startHour";


            nextQuestion = t("talexaB2B.manualQuestions.enterStartHour");
            break;

          case "startHour":

            nextField = "endHour";
            nextQuestion = t("talexaB2B.manualQuestions.enterEndHour");
            break;

          case "endHour":

            nextField = "minSalary";
            nextQuestion = t("talexaB2B.manualQuestions.enterMinSalary");
            break;

          case "minSalary":

            nextField = "maxSalary";

            nextQuestion = t("talexaB2B.manualQuestions.enterMaxSalary");
            break;

          case "maxSalary":

            nextField = null;

            // Update the job offer data with the final field

            const finalJobOfferData = {

              ...updatedJobOfferData,

              maxSalary: inputMessage,

            };

            setJobOfferData(finalJobOfferData);



            try {

              const response = await fetch(`${API_URL}/generate-job-offer`, {

                method: "POST",

                headers: {

                  "Content-Type": "application/json",

                },

                credentials: "include",

                body: JSON.stringify({

                  role: finalJobOfferData.role,

                  department: finalJobOfferData.department,

                  costCenter: finalJobOfferData.costCenter,

                  employmentType: finalJobOfferData.employmentType,

                  contractType: finalJobOfferData.contractType,

                  city: finalJobOfferData.city,

                  startHour: finalJobOfferData.startHour,

                  endHour: finalJobOfferData.endHour,

                  minSalary: finalJobOfferData.minSalary,

                  maxSalary: finalJobOfferData.maxSalary,

                }),

              });



              if (response.ok) {

                const generatedOffer = await response.json();

                if (generatedOffer.jobOffer) {

                  // Parse the AI response to extract fields

                  const aiResponse = generatedOffer.jobOffer;

                  const detectedLanguage = detectLanguage(aiResponse);
                  console.log("Detected Language:", detectedLanguage);
                  console.log("Original AI Response:", aiResponse);

                  // Updated multilingual regex patterns
                  const createMultilingualPattern = (field) => {
                    return new RegExp(
                      `\\*\\*(${field}|Rôle|Rol|Ruolo|Rolle|Cargo)\\*\\*:\\s*(.*?)(?=\\n|$)`,
                      'i'
                    );
                  };

                  const patterns = {
                    role: createMultilingualPattern('Role|Rôle|Rol|Ruolo|Rolle|Cargo'),
                    companyName: createMultilingualPattern('Company Name|Nom de l\'entreprise|Nombre de la empresa|Nome dell\'azienda|Firmenname|Nome da empresa'),
                    companyDescription: createMultilingualPattern('Company Description|Description de l\'entreprise|Descripción de la empresa|Descrizione dell\'azienda|Firmenbeschreibung|Descrição da empresa'),
                    employmentType: createMultilingualPattern('Employment Type|Type d\'emploi|Tipo de empleo|Tipo di impiego|Beschäftigungsart|Tipo de emprego'),
                    contractType: createMultilingualPattern('Contract Type|Type de contrat|Tipo de contrato|Tipo di contratto|Vertragsart|Tipo de contrato'),
                    missions: createMultilingualPattern('Missions|Missions|Misiones|Missioni|Aufgaben|Missões'),
                    responsibilities: createMultilingualPattern('Responsibilities|Responsabilités|Responsabilidades|Responsabilità|Verantwortlichkeiten|Responsabilidades'),
                    requirements: createMultilingualPattern('Requirements|Exigences|Requisitos|Requisiti|Anforderungen|Requisitos'),
                    minYearExperience: createMultilingualPattern('Minimum Years of Experience|Années d\'expérience minimales|Años mínimos de experiencia|Anni minimi di esperienza|Mindestberufserfahrung|Anos mínimos de experiência'),
                    roleLevel: createMultilingualPattern('Role Level|Niveau du poste|Nivel del puesto|Livello del ruolo|Position Level|Nível do cargo'),
                    skills: createMultilingualPattern('Skills|Compétences|Habilidades|Competenze|Fähigkeiten|Habilidades'),
                    offerContent: createMultilingualPattern('Offer Content|Contenu de l\'offre|Contenido de la oferta|Contenuto dell\'offerta|Stellenangebot|Conteúdo da oferta'),
                    perks: createMultilingualPattern('Perks|Avantages|Beneficios|Benefit|Vorteile|Benefícios'),
                    hiringProcess: createMultilingualPattern('Hiring Process|Processus de recrutement|Proceso de contratación|Processo di selezione|Einstellungsprozess|Processo de contratação'),
                    tone: createMultilingualPattern('Tone|Ton|Tono|Tono|Ton|Tom')
                  };

                  // Update the parseAIResponse function
                  const parseAIResponse = (aiResponse) => {
                    const parsedFields = {};

                    Object.entries(patterns).forEach(([field, pattern]) => {
                      const match = aiResponse.match(pattern);
                      if (match && match[2]) {
                        parsedFields[field] = match[2].trim();

                        // Special handling for numeric fields
                        if (field === 'minYearExperience') {
                          const numericMatch = match[2].match(/\d+/);
                          if (numericMatch) {
                            parsedFields[field] = numericMatch[0];
                          }
                        }
                      }
                    });

                    // Add additional validation and cleanup
                    if (parsedFields.skills) {
                      parsedFields.skills = parsedFields.skills.split(',').map(skill => skill.trim()).filter(Boolean);
                    }

                    return parsedFields;
                  };

                  const parsedFields = parseAIResponse(aiResponse);
                  console.log("Parsed AI Fields:", parsedFields);

                  if (Object.keys(parsedFields).length === 0) {
                    console.warn("No fields were parsed from AI response. Response:", aiResponse);
                    // Optionally show an error message to the user
                    return;
                  }

                  // Combine manual and AI fields
                  const combinedJobOfferData = {

                    // Manual fields with their original names

                    role: finalJobOfferData.role,

                    department: finalJobOfferData.department,

                    costCenter: finalJobOfferData.costCenter,

                    employmentType: finalJobOfferData.employmentType,

                    contractType: finalJobOfferData.contractType,

                    city: finalJobOfferData.city,

                    startHour: finalJobOfferData.startHour,

                    endHour: finalJobOfferData.endHour,

                    minSalary: finalJobOfferData.minSalary,

                    maxSalary: finalJobOfferData.maxSalary,

                    // AI fields

                    ...parsedFields,

                  };



                  console.log("Final Combined Data:", combinedJobOfferData);



                  // Update the jobOfferData state with combined data

                  setJobOfferData(combinedJobOfferData);



                  setMessages((prev) => [

                    ...prev,

                    {

                      role: "assistant",

                      content: generatedOffer.jobOffer,

                      showRegenerateButton: true,

                    },

                  ]);



                  // Reset other states

                  setManualQuestionField(null);

                  setShowActionButtons(true);

                  return;

                }

              } else {

                const errorData = await response.json();

                if (errorData.missingFields) {

                  // Handle missing fields

                  const nextMissingField = Object.keys(

                    errorData.missingFields

                  )[0];

                  setManualQuestionField(nextMissingField);

                  setMessages((prev) => [

                    ...prev,

                    {

                      role: "assistant",

                      content: errorData.missingFields[nextMissingField],

                    },

                  ]);

                  return;

                }

                throw new Error(

                  errorData.error || "Failed to generate job offer"

                );

              }

            } catch (error) {

              logError("Error submitting job offer:", error);

              setMessages((prev) => [

                ...prev,

                {

                  role: "assistant",

                  content: `Error: ${error.message}`,

                },

              ]);

            }

            break;

        }



        if (nextField) {

          setManualQuestionField(nextField);

          setMessages((prev) => [

            ...prev,

            { role: "assistant", content: nextQuestion },

          ]);

          return;

        }

        // Reset states if no next field

        setManualQuestionField(null);

        setShowActionButtons(true);

      }



      // Default behavior for other messages

      const token = localStorage.getItem("token");

      const endpoint = currentConversationId

        ? `/api/conversations/${currentConversationId}/messages`

        : "/api/chat";



      const response = await fetch(endpoint, {

        method: "POST",

        headers: {

          "Content-Type": "application/json",

          Authorization: `Bearer ${token}`,

        },

        body: JSON.stringify({

          messages: newMessages,

          userId: user?.id,

          tenantId: user?.tenant_id,

          message: inputMessage,

        }),

      });



      if (!response.ok) {

        const errorData = await response.json();

        console.error("Server error:", errorData);

        throw new Error(

          errorData.error || errorData.details || "chat.networkError"

        );

      }



      const data = await response.json();

      if (data.message) {

        setMessages((prev) => [

          ...prev,

          { role: "assistant", content: data.message },

        ]);

        if (data.conversationId && !currentConversationId) {

          setCurrentConversationId(data.conversationId);

        }

      }

    } catch (error) {

      console.error("Full error details:", error);

      logError(error);

      setMessages((prev) => [

        ...prev,

        {

          role: "assistant",

          content:

            "An error occurred while processing your request. Please try again.",

        },

      ]);

    } finally {

      setLoading(false);

    }

  };



  const handleKeyPress = (e) => {

    if (e.key === "Enter" && !e.shiftKey) {

      e.preventDefault();

      handleSendMessage(e);

    }

  };



  const handleFileSelect = (event) => {

    const files = Array.from(event.target.files);

    if (files.length > 2) {

      alert(t("talexaB2B.maxTwoFiles"));

      return;

    }

    setSelectedFiles(files);

  };



  const handleFileUpload = async () => {

    if (selectedFiles.length !== 2) {

      alert(t("talexaB2B.needTwoFiles"));

      return;

    }



    try {

      setLoading(true);

      const formData = new FormData();



      selectedFiles.forEach((file) => {

        formData.append("documents", file);

      });



      const token = localStorage.getItem("token");

      const response = await fetch("/api/chat/upload", {

        method: "POST",

        headers: {

          Authorization: `Bearer ${token}`,

        },

        body: formData,

      });



      const data = await response.json();



      if (data.success) {

        setMessages((prev) => [

          ...prev,

          {

            role: "assistant",

            content: data.message,

          },

        ]);



        // Clear files

        setSelectedFiles([]);

        if (fileInputRef.current) {

          fileInputRef.current.value = "";

        }

      } else {

        throw new Error(data.error || "Upload failed");

      }

    } catch (error) {

      console.error("File upload error:", error);

      setMessages((prev) => [

        ...prev,

        {

          role: "assistant",

          content: `Error: ${error.message}`,

        },

      ]);

    } finally {

      setLoading(false);

    }

  };



  // New function to handle Compare Resumes button click
  const handleCompareResumes = () => {
    setIsComparingResumes(true);

    const compareResumeMessage = `${t("talexaB2B.manualQuestions.selectJob")}`;
    setMessages((prev) => [
      ...prev,
      {
        role: "assistant",
        content: compareResumeMessage,
        component: "jobSelection",
        jobs: jobOpenings,
        onSelect: handleCompareResume
      },
    ]);
  };

  const handleCandidateSelection = async (candidate, isSharedPool = false) => {
    try {
      // Get candidate details - use different endpoint based on pool type
      const candidateEndpoint = isSharedPool
        ? `/api/candidates/${candidate.id}`
        : `/api/candidates/${candidate.id}`;

      const candidateResponse = await fetch(candidateEndpoint, {
        method: "GET",
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
        }
      });

      if (!candidateResponse.ok) {
        throw new Error('Failed to fetch candidate details');
      }

      const candidateDetails = await candidateResponse.json();

      // Get job details
      const jobResponse = await fetch(`/api/job-offers/${selectedJobForResumes.id}`, {
        method: "GET",
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
        }
      });

      if (!jobResponse.ok) {
        throw new Error('Failed to fetch job details');
      }

      const jobDetails = await jobResponse.json();

      // Create messages showing both job and candidate details
      setMessages(prev => [
        ...prev,
        {
          role: "assistant",
          content: t('talexaB2B.selectedCandidateForComparison', {
            firstName: candidateDetails.first_name,
            lastName: candidateDetails.last_name,
            role: jobDetails.role
          }),
          type: 'candidate-selected'
        },
        {
          role: "assistant",
          content: t('talexaB2B.jobOpeningDetails', {
            role: jobDetails.role,
            department: jobDetails.company_name,
            employmentType: jobDetails.employment_type,
            location: jobDetails.city || t('talexaB2B.notSpecified'),
            skills: Array.isArray(jobDetails.skills) ? jobDetails.skills.join(', ') : (jobDetails.skills || t('talexaB2B.notSpecified')),
            experience: jobDetails.min_year_experience,
            description: jobDetails.company_description || t('talexaB2B.notSpecified')
          }),
          type: 'job-details'
        },
        {
          role: "assistant",
          content: t('talexaB2B.candidateDetails', {
            name: `${candidateDetails.first_name} ${candidateDetails.last_name}`,
            email: candidateDetails.email || t('talexaB2B.notProvided'),
            phone: candidateDetails.phone || t('talexaB2B.notProvided'),
            skills: candidateDetails.skills?.join(', ') || t('talexaB2B.notSpecified'),
            experience: candidateDetails.experience || t('talexaB2B.notSpecified'),
            education: candidateDetails.education || t('talexaB2B.notSpecified'),
            linkedin: candidateDetails.linkedin_url || t('talexaB2B.notProvided')
          }),
          type: 'candidate-details'
        },
        {
          role: "assistant",
          content: "⏳" + t('talexaB2B.candidateAnalyzing'),
          type: 'loading'
        }
      ]);
      // Get AI analysis of the match
      const analysisResponse = await fetch(`${API_URL}/analyze-match`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          candidateDetails: candidateDetails.data || candidateDetails,  // Access the data property from candidateDetails
          jobDetails,
          language: i18n.language // Add the current language
        })
      });

      if (!analysisResponse.ok) {
        throw new Error('Failed to get AI analysis');
      }

      const { comparisonResult } = await analysisResponse.json();

      if (!comparisonResult) {
        throw new Error('No analysis results received');
      }

      // Remove loading message and add the AI analysis with candidate details
      setMessages(prev => {
        const withoutLoading = prev.filter(msg => msg.type !== 'loading');
        return [
          ...withoutLoading,
          {
            role: "assistant",
            content: `\n🤖 ${t('talexaB2B.aiAnalysis')}\n${comparisonResult}`,
            type: 'ai-analysis',
            candidate: candidateDetails.data || candidateDetails
          }
        ];
      });
    } catch (error) {
      console.error('Error fetching details:', error);
      setMessages(prev => {
        const withoutLoading = prev.filter(msg => msg.type !== 'loading');
        return [
          ...withoutLoading,
          {
            role: "assistant",
            content: `Error retrieving details: ${error.message}`,
            type: 'error'
          }
        ];
      });
    }
  };

  const handleJobSelectionForCompare = (jobIndex) => {
    const selectedJob = jobOpenings[jobIndex - 1];

    if (selectedJob) {
      setSelectedJobForResumes(selectedJob);

      const candidateSourceMessage = `From where you will like to pick the candidate for comparison:\n\n1): My Pool.\n2): Shared Pool.\n3): Upload a resume of Candidate`;

      setMessages((prev) => [
        ...prev,
        { role: "assistant", content: candidateSourceMessage }
      ]);
    }
  };

  // New function to handle resume upload and comparison
  const handleResumeUpload = async (files) => {
    if (!selectedCompareJob) {
      console.error("No job selected for resume comparison");
      return;
    }

    try {
      setLoading(true);

      // Create FormData for file upload
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`resume_${index}`, file);
      });
      formData.append('jobId', selectedCompareJob.id);

      // Send resumes to backend for comparison
      const response = await fetch(`${API_URL}/compare-resumes`, {
        method: 'POST',
        credentials: 'include',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Resume comparison failed');
      }

      const comparisonResults = await response.json();

      // Display comparison results
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: `**${t("talexaB2B.resumeComparison.title")}**\n\n${t("talexaB2B.resumeComparison.jobRole")}: ${selectedCompareJob.role}`
        },
        ...comparisonResults.map((result, index) => ({
          role: "assistant",
          content: `**${t("talexaB2B.candidate")} ${index + 1}:**
**${t("talexaB2B.name")}:** ${result.name}
**${t("talexaB2B.resumeComparison.matchScore")}:** ${result.matchScore}%
**${t("talexaB2B.resumeComparison.keySkillsMatch")}:** ${result.keySkillsMatch.join(", ")}
**${t("talexaB2B.resumeComparison.missingSkills")}:** ${result.missingSkills.join(", ")}`
        }))
      ]);

    } catch (error) {
      console.error("Resume comparison error:", error);
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: `${t("talexaB2B.resumeComparison.error")}: ${error.message}`
        }
      ]);
    } finally {
      setLoading(false);
      setSelectedCompareJob(null);
      setIsInResumeComparisonFlow(false); // Reset the flag after comparison
    }
  };



  // Function to handle job opening selection and show candidate selection modal
  const handleJobOpeningSelection = (jobOpening) => {
    setSelectedJobOpening(jobOpening);
    setShowCandidateSelectionModal(true);
  };

  // Function to handle candidate selection method
  const handleCandidateSelectionMethod = (method) => {
    setCandidateSelectionMethod(method);
    if (method === 'upload') {
      fileInputRef.current.click();
    }
  };

  // Function to handle file upload
  const handleFileUploadForCandidateSelection = async (event) => {
    const file = event.target.files[0];
    if (!file || file.type !== 'application/pdf') {
      setMessages(prev => [...prev, {
        role: "assistant",
        content: "Please upload a valid PDF file.",
        type: "error"
      }]);
      return;
    }

    try {
      // Show loading message
      setMessages(prev => [...prev, {
        role: "assistant",
        type: "loading",
        content: t('talexaB2B.analyzingResume')
      }]);

      const formData = new FormData();
      formData.append('resume', file);

      // First, extract information from PDF using AI
      const extractResponse = await fetch(`${API_URL}/extract-resume`, {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });

      if (!extractResponse.ok) {
        throw new Error('Failed to extract resume information');
      }

      const candidateDetails = await extractResponse.json();

      // Format the extracted information

      const formattedInfo = t('talexaB2B.extractedCandidateDetails', {
        name: candidateDetails.data.name,
        email: candidateDetails.data.email,
        phone: candidateDetails.data.phone,
        skills: Array.isArray(candidateDetails.data.skills) ? candidateDetails.data.skills.join(', ') : (candidateDetails.data.skills || t('talexaB2B.notSpecified')),
        experience: candidateDetails.data.experience || t('talexaB2B.notSpecified'),
        education: candidateDetails.data.education || t('talexaB2B.notSpecified'),
        linkedin: candidateDetails.data.linkedin !== 'Not found' ? candidateDetails.data.linkedin : '',
      });

      const jobComparisonMessage = t('talexaB2B.jobComparisonMessage');

      // Store the extracted info
      setSelectedCandidate({
        first_name: candidateDetails.name && candidateDetails.name !== 'Not found'
          ? candidateDetails.name.split(' ')[0]
          : '',
        last_name: candidateDetails.name && candidateDetails.name !== 'Not found'
          ? candidateDetails.name.split(' ').slice(1).join(' ')
          : '',
        skills: Array.isArray(candidateDetails.skills) ? candidateDetails.skills : [],
        experience: candidateDetails.experience || '',
        education: candidateDetails.education || '',
        linkedin_url: candidateDetails.linkedin !== 'Not found' ? candidateDetails.linkedin : '',
        email: candidateDetails.email !== 'Not found' ? candidateDetails.email : '',
        phone: candidateDetails.phone !== 'Not found' ? candidateDetails.phone : ''
      });

      // Add the extracted info to messages
      setMessages(prev => [...prev, {
        role: "assistant",
        content: formattedInfo
      }]);

      // Fetch job details
      const jobResponse = await fetch(`/api/job-offers/${selectedJobForResumes.id}`, {
        method: "GET",
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
        }
      });

      if (!jobResponse.ok) {
        throw new Error('Failed to fetch job details');
      }

      const jobDetails = await jobResponse.json();

      // Display extracted information and job details
      setMessages(prev => {
        const withoutLoading = prev.filter(msg => msg.type !== 'loading');
        return [
          ...withoutLoading,
          {
            role: "assistant",
            content: t('talexaB2B.jobOpeningDetails', {
              role: jobDetails.role,
              department: jobDetails.company_name,
              employmentType: jobDetails.employment_type,
              location: jobDetails.city || t('talexaB2B.notSpecified'),
              skills: Array.isArray(jobDetails.skills) ? jobDetails.skills.join(', ') : (jobDetails.skills || t('talexaB2B.notSpecified')),
              experience: jobDetails.min_year_experience,
              description: jobDetails.company_description || t('talexaB2B.notSpecified')
            }),
            type: 'job-details'
          },
          {
            role: "assistant",
            content: "⏳" + t('talexaB2B.candidateAnalyzing'),
            type: 'loading'
          }
        ];
      });

      const analysisResponse = await fetch(`${API_URL}/analyze-match`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          candidateDetails: candidateDetails.data || candidateDetails,  // Access the data property from candidateDetails
          jobDetails,
          language: i18n.language // Add the current language
        })
      });

      if (!analysisResponse.ok) {
        throw new Error('Failed to get AI analysis');
      }

      const { comparisonResult } = await analysisResponse.json();

      if (!comparisonResult) {
        throw new Error('No analysis results received');
      }

      // Remove loading message and add the AI analysis with candidate details
      setMessages(prev => {
        const withoutLoading = prev.filter(msg => msg.type !== 'loading');
        return [
          ...withoutLoading,
          {
            role: "assistant",
            content: `\n🤖 ${t('talexaB2B.aiAnalysis')}\n${comparisonResult}`,
            type: 'ai-analysis',
            candidate: candidateDetails.data || candidateDetails
          }
        ];
      });



    } catch (error) {
      console.error('Error processing resume:', error);
      setMessages(prev => {
        const withoutLoading = prev.filter(msg => msg.type !== 'loading');
        return [...withoutLoading, {
          role: "assistant",
          content: `Error processing resume: ${error.message}`,
          type: 'error'
        }];
      });
    }
  };

  const fetchMyPoolCandidates = async () => {
    try {
      const response = await fetch('/api/candidates', {
        method: "GET",
        credentials: "include",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch My Pool candidates");
      }

      const candidates = await response.json();
      console.log("Fetched My Pool candidates:", candidates);

      // Limit to 10 candidates for chat interface
      const limitedCandidates = candidates.slice(0, 10);

      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: t('talexaB2B.myPoolCandidatesTitle', { count: candidates.length }),
          component: "candidate-list",
          candidates: limitedCandidates.map(candidate => ({
            id: candidate.id,
            name: `${candidate.first_name} ${candidate.last_name}`,
            email: candidate.email,
            phone: candidate.phone || "No phone",
            linkedin: shortenLinkedInUrl(candidate.linkedin_url) || "No LinkedIn",
            skills: candidate.skills?.join(", ") || "Not specified"
          })),
          onSelect: (selectedCandidateId) => {
            const selectedCandidate = limitedCandidates.find(c => c.id === selectedCandidateId);
            if (selectedCandidate) {
              // Handle candidate selection for resume comparison
              setSelectedCandidateForComparison(selectedCandidate);
              setMessages((prev) => [
                ...prev,
                {
                  role: "assistant",
                  content: `Selected candidate: ${selectedCandidate.first_name} ${selectedCandidate.last_name}`,
                  component: "candidate-details",
                  candidate: {
                    name: `${selectedCandidate.first_name} ${selectedCandidate.last_name}`,
                    email: selectedCandidate.email,
                    phone: selectedCandidate.phone,
                    linkedin: shortenLinkedInUrl(selectedCandidate.linkedin_url),
                    skills: selectedCandidate.skills?.join(", ") || "No skills"
                  }
                }
              ]);
            }
          }
        }
      ]);
    } catch (error) {
      console.error("Error fetching My Pool candidates:", error);
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: t("talexaB2B.errors.fetchMyPoolFailed", {
            message: error.message || "Unknown error occurred"
          })
        }
      ]);
    }
  };

  // Modify the onSelect handler for My Pool
  const handleMyPoolSelection = useCallback(() => {
    fetchMyPoolCandidates();
  }, []);

  // Modify the existing code to use handleMyPoolSelection
  const onSelect = useCallback((selectedOption) => {
    switch (selectedOption) {
      case "myPool":
        // Fetch and display My Pool candidates
        const fetchMyPoolCandidates = async () => {
          try {
            const response = await fetch('/api/candidates', {
              method: "GET",
              credentials: "include",
              headers: {
                'Content-Type': 'application/json',
              }
            });

            if (!response.ok) {
              throw new Error('Failed to fetch candidates');
            }

            const candidates = await response.json();

            // Create the dropdown content while preserving the original data structure
            const dropdownContent = (
              <CandidateDropdown
                candidates={candidates}
                onSelect={(value) => handleDropdownSelection(value)}
              />
            );

            setMessages((prev) => [
              ...prev,
              {
                role: "assistant",
                content: t('talexaB2B.myPoolCandidatesTitle', { count: candidates.length }),
                type: 'candidateList',
                candidates: candidates,
                awaitingCandidateSelection: true
              }
            ]);
          } catch (error) {
            setMessages((prev) => [
              ...prev,
              {
                role: "assistant",
                content: `Error retrieving candidates: ${error.message}`,
                type: 'error'
              }
            ]);
          }
        };

        fetchMyPoolCandidates();
        break;

      case "sharedPool":
        // Prepare for Shared Pool implementation
        const fetchSharedPoolCandidates = async () => {
          try {
            const response = await fetch('/api/candidates/shared', {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
            });

            if (!response.ok) {
              throw new Error('Failed to fetch shared pool candidates');
            }

            const candidates = await response.json();

            // Create the dropdown content while preserving the original data structure
            const dropdownContent = (
              <CandidateDropdown
                candidates={candidates}
                onSelect={(value) => handleDropdownSelection(value)}
              />
            );

            setMessages((prev) => [
              ...prev,
              {
                role: 'assistant',
                content: t('talexaB2B.sharedPoolCandidatesTitle', { count: candidates.length }),
                type: 'candidateList',
                candidates: candidates,
                awaitingCandidateSelection: true
              }
            ]);

            // Remove any additional prompts after this point
            // The next step should directly handle the user input for candidate selection
            return;
          } catch (error) {
            setMessages((prev) => [
              ...prev,
              {
                role: 'assistant',
                content: `Error retrieving shared pool candidates: ${error.message}`,
                type: 'error'
              }
            ]);
          }
        };

        fetchSharedPoolCandidates();
        break;

      case "upload":
        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: "Please upload the candidate's resume in PDF format",
            component: "upload-resume",
            accept: ".pdf",
            multiple: false
          }
        ]);
        break;

      default:
        console.warn('Unknown candidate source selected');
    }
  }, []);

  // Candidate Selection Modal
  const CandidateSelectionModal = () => (
    <div className="modal">
      <div className="modal-content">
        <h2>Select Candidates for Comparison</h2>
        <p>From where would you like to select candidates for {selectedJobOpening?.title}?</p>
        <div className="selection-options">
          <button
            onClick={() => handleCandidateSelectionMethod('myPool')}
          >
            My Pool
          </button>
          <button
            onClick={() => handleCandidateSelectionMethod('sharedPool')}
          >
            Shared Pool
          </button>
          <button
            onClick={() => handleCandidateSelectionMethod('upload')}
          >
            Upload Candidate Resume
          </button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".pdf"
            onChange={handleFileUploadForCandidateSelection}
          />
        </div>
        <button onClick={() => setShowCandidateSelectionModal(false)}>Cancel</button>
      </div>
    </div>
  );

  // Move actionPrompts inside component to access t function

  const actionPrompts = useMemo(

    () => ({

      searchCandidates: {

        icon: t("talexaB2B.actions.searchCandidates.icon"),

        label: t("talexaB2B.actions.searchCandidates.label"),

        prompt: `Please help me search for candidates with these criteria:

1. Required skills and qualifications
2. Years of experience
3. Location preferences
4. Contract type preferences
5. Specific industry experience

Please provide a structured search strategy and candidate requirements.`,

      },

      compareResume: {

        icon: t("talexaB2B.actions.compareResume.icon"),

        label: t("talexaB2B.actions.compareResume.label"),

        prompt: `Please analyze the provided CV and job offer to:

1. Calculate overall match percentage
2. Identify key matching skills
3. List missing requirements
4. Highlight candidate's strengths
5. Point out areas for improvement
6. Provide final recommendation`,

      },

    }),

    [t]

  ); // Memorize and update when language changes



  const handleActionClick = async (action) => {

    try {

      setLoading(true);



      const response = await fetch("/api/chat", {

        method: "POST",

        headers: {

          "Content-Type": "application/json",

          Authorization: `Bearer ${localStorage.getItem("token")}`,

        },

        body: JSON.stringify({

          message: actionPrompts[action].prompt,

          conversationId: currentConversationId,

        }),

      });



      if (!response.ok) {

        throw new Error("Failed to get AI response");

      }



      const data = await response.json();

      setMessages((prev) => [

        ...prev,

        {

          role: "assistant",

          content: data.message,

        },

      ]);

    } catch (error) {

      logError(error);

      setMessages((prev) => [

        ...prev,

        {

          role: "assistant",

          content: `Error: ${error.message}`,

        },

      ]);

    } finally {

      setLoading(false);

    }

  };



  // Generate a single job offer message

  const handleGenerateJobOffer = () => {

    const jobOffersMessage = hiringRequests

      .map((request, index) => {

        return `${index + 1}) ${request.role} at ${request.department}`;

      })

      .join("\n\n");


    const jobOfferMessage = `${t("talexaB2B.manualQuestions.availableHiringRequests")}\n\n${jobOffersMessage}\n\n${t("talexaB2B.manualQuestions.selectGenerateJob")}`;



    setMessages((prev) => [

      ...prev,

      { role: "assistant", content: jobOfferMessage },

    ]);

  };



  const handleRegenerateResponse = async () => {

    setRegenerating(true);

    try {

      // Use the preserved jobOfferData which contains all fields

      const response = await fetch(`${API_URL}/generate-job-offer`, {

        method: "POST",

        headers: {

          "Content-Type": "application/json",

        },

        credentials: "include",

        body: JSON.stringify({

          role: jobOfferData.role || "Software Engineer",

          department: jobOfferData.department || "Engineering",

          costCenter: jobOfferData.costCenter,

          city: jobOfferData.city,

          startHour: jobOfferData.startHour,

          endHour: jobOfferData.endHour,

          minSalary: jobOfferData.minSalary,

          maxSalary: jobOfferData.maxSalary,

        }),

      });



      if (response.ok) {

        const generatedOffer = await response.json();

        if (generatedOffer.jobOffer) {

          // Replace the last message with the new response

          setMessages((prev) => [

            ...prev.slice(0, -1),

            {

              role: "assistant",

              content: generatedOffer.jobOffer,

              showRegenerateButton: true,

            },

          ]);

        }

      } else {

        const errorData = await response.json();

        throw new Error(errorData.error || "Failed to regenerate job offer");

      }

    } catch (error) {

      logError("Error regenerating job offer:", error);

      setMessages((prev) => [

        ...prev,

        {

          role: "assistant",

          content:

            "There was an error regenerating your job offer. Please try again.",

        },

      ]);

    } finally {

      setRegenerating(false);

    }

  };



  const downloadAnalysisAsPDF = (content, candidate) => {
    console.log('PDF Generation - Raw Content:', content);

    // Multilingual section mapping
    const sectionMap = {
      fr: {
        matchPercentage: 'Pourcentage de correspondance:',
        strengths: 'Points forts:',
        areasForImprovement: 'Points à améliorer:',
        recommendedQuestions: 'Questions d\'entretien recommandées:'
      },
      en: {
        matchPercentage: 'Match Percentage:',
        strengths: 'Strengths:',
        areasForImprovement: 'Areas for Improvement:',
        recommendedQuestions: 'Recommended Interview Questions:'
      },
      es: {
        matchPercentage: 'Porcentaje de coincidencia:',
        strengths: 'Puntos fuertes:',
        areasForImprovement: 'Puntos a mejorar:',
        recommendedQuestions: 'Preguntas de entrevista recomendadas:'
      },
      it: {
        matchPercentage: 'Percentuale di corrispondenza:',
        strengths: 'Punti di forza:',
        areasForImprovement: 'Punti da migliorare:',
        recommendedQuestions: 'Domande di intervista consigliate:'
      },
      de: {
        matchPercentage: 'Übereinstimmungsprozentsatz:',
        strengths: 'Stärken:',
        areasForImprovement: 'Zu verbessernde Punkte:',
        recommendedQuestions: 'Empfohlene Interviewfragen:'
      },
      pt: {
        matchPercentage: 'Percentual de correspondência:',
        strengths: 'Pontos fortes:',
        areasForImprovement: 'Pontos a melhorar:',
        recommendedQuestions: 'Perguntas de entrevista recomendadas:'
      }
    };

    // Detect language
    const detectLanguage = (text) => {
      const languageKeywords = Object.keys(sectionMap);
      for (const lang of languageKeywords) {
        if (Object.values(sectionMap[lang]).some(keyword => text.includes(keyword))) {
          return lang;
        }
      }
      return 'en'; // Default to English
    };

    const detectedLanguage = detectLanguage(content);
    console.log('Detected Language:', detectedLanguage);

    // Multilingual titles
    const titles = {
      en: {
        analysisReport: 'AI Analysis Report',
        matchPercentage: 'Match Percentage',
        strengths: 'Strengths',
        areasForImprovement: 'Areas for Improvement',
        recommendedQuestions: 'Recommended Interview Questions'
      },
      fr: {
        analysisReport: 'Rapport d\'analyse IA',
        matchPercentage: 'Pourcentage de correspondance',
        strengths: 'Points forts',
        areasForImprovement: 'Points à améliorer',
        recommendedQuestions: 'Questions d\'entretien recommandées'
      },
      es: {
        analysisReport: 'Informe de Análisis de IA',
        matchPercentage: 'Porcentaje de coincidencia',
        strengths: 'Puntos fuertes',
        areasForImprovement: 'Puntos a mejorar',
        recommendedQuestions: 'Preguntas de entrevista recomendadas'
      },
      it: {
        analysisReport: 'Rapporto di Analisi IA',
        matchPercentage: 'Percentuale di corrispondenza',
        strengths: 'Punti di forza',
        areasForImprovement: 'Punti da migliorare',
        recommendedQuestions: 'Domande di intervista consigliate'
      },
      de: {
        analysisReport: 'KI-Analysebericht',
        matchPercentage: 'Übereinstimmungsprozentsatz',
        strengths: 'Stärken',
        areasForImprovement: 'Zu verbessernde Punkte',
        recommendedQuestions: 'Empfohlene Interviewfragen'
      },
      pt: {
        analysisReport: 'Relatório de Análise de IA',
        matchPercentage: 'Percentual de correspondência',
        strengths: 'Pontos fortes',
        areasForImprovement: 'Pontos a melhorar',
        recommendedQuestions: 'Perguntas de entrevista recomendadas'
      }
    };

    const currentDate = new Date().toISOString().split('T')[0];
    const candidateData = candidate.data || candidate;

    const doc = new jsPDF();
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(20);
    doc.setTextColor(74, 144, 226);
    doc.text(`${titles[detectedLanguage].analysisReport} - ${candidateData.name || `${candidateData.first_name} ${candidateData.last_name}`}(${currentDate})`, 105, 20, { align: 'center' });

    doc.setDrawColor(74, 144, 226);
    doc.setLineWidth(0.5);
    doc.line(20, 25, 190, 25);

    let yPos = 40;

    // Sections configuration
    const sections = [
      {
        key: 'matchPercentage',
        color: [0, 0, 0],
        process: (text) => {
          const percentage = text.split(':')[1].trim();
          return `${titles[detectedLanguage].matchPercentage}: ${percentage}`;
        }
      },
      {
        key: 'strengths',
        color: [40, 167, 69],
        process: (text) => text
      },
      {
        key: 'areasForImprovement',
        color: [220, 53, 69],
        process: (text) => text
      },
      {
        key: 'recommendedQuestions',
        color: [74, 144, 226],
        process: (text) => text
      }
    ];

    // Parse and render sections
    sections.forEach(section => {
      const sectionKeyword = sectionMap[detectedLanguage][section.key];

      if (content.includes(sectionKeyword)) {
        // Find the next section or end of content
        const nextSectionIndex = sections
          .slice(sections.indexOf(section) + 1)
          .map(nextSection => content.indexOf(sectionMap[detectedLanguage][nextSection.key]))
          .filter(index => index !== -1)
          .reduce((a, b) => Math.min(a, b), content.length);

        const sectionText = content.substring(content.indexOf(sectionKeyword), nextSectionIndex).trim();

        // Render section title
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(16);
        doc.setTextColor(...section.color);
        doc.text(titles[detectedLanguage][section.key], 20, yPos);
        yPos += 10;

        // Render section content
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);

        const processedContent = section.process(sectionText);
        const lines = doc.splitTextToSize(processedContent, 160);

        lines.forEach(line => {
          if (yPos > 280) {
            doc.addPage();
            yPos = 20;
          }
          doc.text(line, 25, yPos);
          yPos += 7;
        });

        yPos += 10;
      }
    });

    doc.save(`AI_Analysis_Report_${candidateData.name || candidateData.first_name}_${currentDate}.pdf`);
  };
  const MessageContent = ({ message }) => {
    if (message.type === 'candidateList') {
      return (
        <div>
          <div>{message.content}</div>
          <CandidateDropdown
            candidates={message.candidates}
            onSelect={(value) => handleDropdownSelection(value, message)}
          />
          <div>{t('talexaB2B.selectCandidateByNumber')}</div>
        </div>
      );
    }

    if (message.type === 'ai-analysis') {
      return (
        <div>
          <div style={{ whiteSpace: "pre-line" }}>{message.content}</div>
          <div style={{
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            <button
              onClick={() => downloadAnalysisAsPDF(message.content, message.candidate)}
              style={{
                backgroundColor: '#4a90e2',
                color: 'white',
                border: 'none',
                padding: '8px 16px',
                borderRadius: '4px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
              }}
            >
              📥 {t('talexaB2B.downloadAnalysisReport')}
            </button>
          </div>
        </div>
      );
    }

    if (message.showRegenerateButton) {
      return (
        <div>
          <div style={{ whiteSpace: "pre-line" }}>{message.content}</div>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              onClick={handleRegenerateResponse}
              disabled={regenerating}
              style={{
                padding: "8px 16px",
                backgroundColor: "#007bff",
                color: "#ffffff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                marginTop: "10px",
              }}
            >
              {regenerating ? (
                <>
                  <div
                    style={{
                      width: "16px",
                      height: "16px",
                      border: "2px solid #ffffff",
                      borderTop: "2px solid transparent",
                      borderRadius: "50%",
                      animation: "spin 1s linear infinite",
                      marginRight: "8px",
                    }}
                  />
                  Regenerating...
                </>
              ) : (
                t("talexaB2B.regenerateResponse")
              )}
            </button>
            <button
              onClick={() => saveJobOfferData(jobOfferData)}
              style={{
                padding: "8px 16px",
                backgroundColor: "#28a745",
                color: "#ffffff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "10px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              {t("talexaB2B.addJobOpening")}
            </button>
          </div>
        </div>
      );
    }

    if (message.type === 'loading') {
      return (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          padding: '10px',
          backgroundColor: '#f8f9fa',
          borderRadius: '4px',
          marginTop: '10px'
        }}>
          <div style={{
            width: '20px',
            height: '20px',
            border: '2px solid #4a90e2',
            borderTopColor: 'transparent',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite'
          }} />
          <span>{message.content}</span>
        </div>
      );
    }

    if (message.component === "upload-resume") {
      return (
        <div key={message.index} className="message-content">
          <div>{message.content}</div>
          <input
            type="file"
            accept=".pdf"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileUploadForCandidateSelection}
          />
          <button
            onClick={() => fileInputRef.current.click()}
            style={{
              padding: "8px 16px",
              backgroundColor: "#4a90e2",
              color: "#ffffff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              marginTop: "10px",
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: "8px" }}
            >
              <path
                d="M8 1L8 11M8 1L4 5M8 1L12 5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M2 11V14H14V11"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            {t('talexaB2B.uploadResumeBtn')}
          </button>
        </div>
      );
    }

    if (message.component === "jobSelection") {
      return (
        <div className="message-content">
          <div>{message.content}</div>
          <JobSelectionDropdown
            jobs={message.jobs}
            onSelect={message.onSelect}
          />
        </div>
      );
    }

    // Other message type handling...
    return <div style={{ whiteSpace: "pre-line" }}>{message.content}</div>;
  };

  const handleDropdownSelection = (value, message) => {
    if (!value) return;

    const candidateIndex = parseInt(value) - 1;
    const selectedCandidate = message.candidates[candidateIndex];

    if (selectedCandidate) {
      handleCandidateSelection(selectedCandidate, message.isSharedPool);
    }
  };

  const handleCandidateSourceSelection = useCallback(async (selectedSource) => {
    try {
      if (selectedSource === '1') {
        const fetchMyPoolCandidates = async () => {
          try {
            const response = await fetch('/api/candidates', {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              }
            });

            if (!response.ok) {
              throw new Error('Failed to fetch candidates');
            }

            const candidates = await response.json();

            setMessages((prev) => [
              ...prev,
              {
                role: 'assistant',
                content: t('talexaB2B.myPoolCandidatesTitle', { count: candidates.length }),
                type: 'candidateList',
                candidates: candidates,
                awaitingCandidateSelection: true
              }
            ]);
          } catch (error) {
            setMessages((prev) => [
              ...prev,
              {
                role: 'assistant',
                content: `Error retrieving candidates: ${error.message}`,
                type: 'error'
              }
            ]);
          }
        };

        fetchMyPoolCandidates();
      } else if (selectedSource === '2') {
        const fetchSharedPoolCandidates = async () => {
          try {
            const response = await fetch('/api/candidates/shared', {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
            });

            if (!response.ok) {
              throw new Error('Failed to fetch shared pool candidates');
            }

            const candidates = await response.json();

            setMessages((prev) => [
              ...prev,
              {
                role: 'assistant',
                content: t('talexaB2B.sharedPoolCandidatesTitle', { count: candidates.length }),
                type: 'candidateList',
                candidates: candidates,
                awaitingCandidateSelection: true
              }
            ]);

            // Remove any additional prompts after this point
            // The next step should directly handle the user input for candidate selection
            return;
          } catch (error) {
            setMessages((prev) => [
              ...prev,
              {
                role: 'assistant',
                content: `Error retrieving shared pool candidates: ${error.message}`,
                type: 'error'
              }
            ]);
          }
        };

        fetchSharedPoolCandidates();
      } else if (selectedSource === '3') {
        // Upload Resume option
        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: "Please upload the candidate's resume in PDF format",
            component: "upload-resume",
            accept: ".pdf",
            multiple: false
          }
        ]);
      }
    } catch (error) {
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: `Error: ${error.message}`,
          type: 'error'
        }
      ]);
    }
  }, []);

  const handleCandidateSelectionNew = useCallback(async (selectedSource) => {
    try {
      if (selectedSource === 'myPool') {
        // My Pool selected
        const myPoolCandidates = await fetchMyPoolCandidates();

        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: t('talexaB2B.myPoolCandidatesTitle', { count: myPoolCandidates.length }),
            type: 'candidateList',
            candidates: myPoolCandidates,
            awaitingCandidateSelection: true
          }
        ]);
      } else if (selectedSource === 'sharedPool') {
        // Prepare for Shared Pool implementation
        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: "Shared Pool functionality coming soon.",
            type: 'info'
          }
        ]);
      } else if (selectedSource === 'uploadResume') {
        // Trigger resume upload flow
        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: t("talexaB2B.manualQuestions.uploadResumesForComparison"),
            component: "upload-resume",
            multiple: true,
            accept: ".pdf,.docx,.doc",
            onUpload: handleResumeUpload
          }
        ]);
      }
    } catch (error) {
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: `Error retrieving candidates: ${error.message}`,
          type: 'error'
        }
      ]);
    }
  }, [setMessages, t, handleResumeUpload]);

  const fetchSharedCandidateDetails = async (id) => {
    const response = await fetch(`${API_URL}/candidates/shared/${id}`);
    if (!response.ok) {
      throw new Error("Failed to fetch shared candidate details");
    }
    return await response.json();
  };

  const handleSharedPoolCandidateSelection = async (candidateId) => {
    try {
      const candidateDetails = await fetchSharedCandidateDetails(candidateId);
      const jobDetails = await fetchJobDetails(selectedJobId);
      const analysisResults = await runAIAnalysis(candidateDetails, jobDetails);
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: t('talexaB2B.selectedCandidateForComparison', {
            firstName: candidateDetails.first_name,
            lastName: candidateDetails.last_name,
            role: jobDetails.role
          }),
          type: 'candidate-selected',
          awaitingCandidateSelection: false
        },
        {
          role: "assistant",
          content: t('talexaB2B.jobOpeningDetails', {
            role: jobDetails.role,
            department: jobDetails.company_name,
            employmentType: jobDetails.employment_type,
            location: jobDetails.city || t('talexaB2B.notSpecified'),
            skills: Array.isArray(jobDetails.skills) ? jobDetails.skills.join(', ') : (jobDetails.skills || t('talexaB2B.notSpecified')),
            experience: jobDetails.min_year_experience,
            description: jobDetails.company_description || t('talexaB2B.notSpecified')
          }),
          type: 'job-details'
        },
        {
          role: "assistant",
          content: `\n👤 Candidate Details:\n` +
            `Name: ${candidateDetails.first_name} ${candidateDetails.last_name}\n` +
            `Email: ${candidateDetails.email || 'Not provided'}\n` +
            `Phone: ${candidateDetails.phone || 'Not provided'}\n` +
            `Skills: ${candidateDetails.skills?.join(', ') || 'Not specified'}\n` +
            `Experience: ${candidateDetails.experience || 'Not specified'}\n` +
            `Education: ${candidateDetails.education || 'Not specified'}\n` +
            `LinkedIn: ${candidateDetails.linkedin_url || 'Not provided'}\n`,
          type: 'candidate-details'
        },
        {
          role: "assistant",
          content: "⏳" + t('talexaB2B.candidateAnalyzing'),
          type: 'loading'
        }
      ]);
      setMessages((prev) => {
        const withoutLoading = prev.filter(msg => msg.type !== 'loading');
        return [
          ...withoutLoading,
          {
            role: "assistant",
            content: `\n🤖 ${t('talexaB2B.aiAnalysis')}\n${analysisResults}`,
            type: 'ai-analysis',
            candidate: candidateDetails.data || candidateDetails
          }
        ];
      });
    } catch (error) {
      console.error('Error fetching details:', error);
      setMessages((prev) => {
        const withoutLoading = prev.filter(msg => msg.type !== 'loading');
        return [
          ...withoutLoading,
          {
            role: "assistant",
            content: `Error retrieving details: ${error.message}`,
            type: 'error'
          }
        ];
      });
    }
  };

  // Add new function to handle PDF resume upload and AI extraction
  const handlePDFResumeUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file || !file.name.toLowerCase().endsWith('.pdf')) {
        setMessages(prev => [
          ...prev,
          {
            role: "assistant",
            content: "Please upload a valid PDF file.",
            type: 'error'
          }
        ]);
        return;
      }

      setMessages(prev => [
        ...prev,
        {
          role: "assistant",
          type: "loading",
          content: "Analyzing the uploaded resume... Please wait."
        }
      ]);

      const formData = new FormData();
      formData.append('resume', file);

      // First, extract information from PDF using AI
      const extractResponse = await fetch(`${API_URL}/extract-resume`, {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });

      if (!extractResponse.ok) {
        throw new Error('Failed to extract resume information');
      }

      const candidateDetails = await extractResponse.json();

      // Get job details
      const jobResponse = await fetch(`/api/job-offers/${selectedJobForResumes.id}`, {
        method: "GET",
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
        }
      });

      if (!jobResponse.ok) {
        throw new Error('Failed to fetch job details');
      }

      const jobDetails = await jobResponse.json();

      // Display extracted information and job details
      setMessages(prev => {
        const withoutLoading = prev.filter(msg => msg.type !== 'loading');
        return [
          ...withoutLoading,
          {
            role: "assistant",
            content: `Successfully extracted information from resume: ${file.name}`,
            type: 'success'
          },
          {
            role: "assistant",
            content: t('talexaB2B.jobOpeningDetails', {
              role: jobDetails.role,
              department: jobDetails.company_name,
              employmentType: jobDetails.employment_type,
              location: jobDetails.city || t('talexaB2B.notSpecified'),
              skills: Array.isArray(jobDetails.skills) ? jobDetails.skills.join(', ') : (jobDetails.skills || t('talexaB2B.notSpecified')),
              experience: jobDetails.min_year_experience,
              description: jobDetails.company_description || t('talexaB2B.notSpecified')
            }),
            type: 'job-details'
          },
          {
            role: "assistant",
            content: t('talexaB2B.candidateDetails', {
              name: `${candidateDetails.first_name} ${candidateDetails.last_name}`,
              email: candidateDetails.email || t('talexaB2B.notProvided'),
              phone: candidateDetails.phone || t('talexaB2B.notProvided'),
              skills: candidateDetails.skills?.join(', ') || t('talexaB2B.notSpecified'),
              experience: candidateDetails.experience || t('talexaB2B.notSpecified'),
              education: candidateDetails.education || t('talexaB2B.notSpecified'),
              linkedin: candidateDetails.linkedin_url || t('talexaB2B.notProvided')
            }),
            type: 'candidate-details'
          },
          {
            role: "assistant",
            content: "⏳" + t('talexaB2B.candidateAnalyzing'),
            type: 'loading'
          }
        ];
      });

      // Get AI analysis of the match
      const analysisResponse = await fetch(`${API_URL}/analyze-match`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          candidateDetails: candidateDetails.data || candidateDetails,  // Access the data property from candidateDetails
          jobDetails,
          language: i18n.language // Add the current language
        })
      });

      if (!analysisResponse.ok) {
        throw new Error('Failed to get AI analysis');
      }

      const { comparisonResult } = await analysisResponse.json();

      if (!comparisonResult) {
        throw new Error('No analysis results received');
      }

      // Remove loading message and add the AI analysis with candidate details
      setMessages(prev => {
        const withoutLoading = prev.filter(msg => msg.type !== 'loading');
        return [
          ...withoutLoading,
          {
            role: "assistant",
            content: `\n🤖 ${t('talexaB2B.aiAnalysis')}\n${comparisonResult}`,
            type: 'ai-analysis',
            candidate: candidateDetails.data || candidateDetails
          }
        ];
      });



    } catch (error) {
      console.error('Error processing resume:', error);
      setMessages(prev => {
        const withoutLoading = prev.filter(msg => msg.type !== 'loading');
        return [
          ...withoutLoading,
          {
            role: "assistant",
            content: `Error processing resume: ${error.message}`,
            type: 'error'
          }
        ];
      });
    }
  };

  const CandidateDropdown = ({ candidates, onSelect }) => {
    return (
      <div className="candidate-dropdown-container">
        <select className="candidate-select" onChange={(e) => onSelect(e.target.value)}>
          <option value="">{t('talexaB2B.chooseCandidate')}</option>
          {candidates.map((candidate, index) => (
            <option key={index + 1} value={index + 1}>
              {candidate.first_name} {candidate.last_name} - {shortenLinkedInUrl(candidate.linkedin_url)}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const JobSelectionDropdown = ({ jobs, onSelect }) => {
    return (
      <div className="candidate-dropdown-container">
        <select
          className="candidate-select"
          onChange={(e) => onSelect(e.target.value)}
          disabled={isSearchLoading}
        >
          <option value="">Select a job position...</option>
          {jobs.map((job, index) => (
            <option key={index} value={index}>
              {job.role} {job.is_ai_generated ? '(AI Generated)' : ''}
            </option>
          ))}
        </select>
        {isSearchLoading && (
          <div className="loader-container">
            <div className="loader"></div>
            <span>Searching candidates...</span>
          </div>
        )}
      </div>
    );
  };

  const handleJobSelection = (selectedIndex) => {
    if (selectedIndex !== "") {
      const numericIndex = parseInt(selectedIndex, 10);
      const selectedJob = jobOpenings[numericIndex];
      setIsSearchingCandidates(true);

      if (selectedJob) {
        try {
          // Show initial message
          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: t("talexaB2B.findCandidates", { role: selectedJob.role }),
            },
          ]);

          // Show loading message first
          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: t("talexaB2B.searchingCandidates"),
              type: "loading"
            }
          ]);

          // Validate search query
          if (!selectedJob.role) {
            setMessages((prev) => [
              ...prev,
              {
                role: "assistant",
                content: t("talexaB2B.invalidSearchQuery"),
              },
            ]);
            setIsSearchingCandidates(false);
            return;
          }

          // First fetch complete job details
          fetch(`${API_URL}/job-offers/${selectedJob.id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            credentials: 'include'
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to fetch job details');
            }
            return response.json();
          })
          .then(jobDetails => {
            const jobSkills = jobDetails.skills || [];
            const skillsString = jobSkills.length > 0 ? ` ${jobSkills.join(", ")}` : "";
            const queryString = `${selectedJob.role}${skillsString}`;

            // Check if this is the same search query
            if (currentSearchQuery === queryString) {
              // If same query, increment page
              setCurrentPage(prev => prev + 1);
            } else {
              // If new query, reset page to 1 and save new query
              setCurrentPage(1);
              setCurrentSearchQuery(queryString);
            }

            // Use our backend proxy endpoint for LinkedIn search
            return fetch(`${API_URL}/linkedin/search`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
              },
              credentials: 'include',
              body: JSON.stringify({
                query: queryString,
                location: jobDetails.city,
                experience: jobDetails.min_year_experience,
                job_offer_id: selectedJob.id,
                user_id: fetchedUser?.userId,
                tenant_id: fetchedUser?.tenantId,
                page: currentSearchQuery === queryString ? currentPage + 1 : 1
              })
            });
          })
          .then(response => response.json())
          .then(data => {
            const candidates = data.candidates || data;

            if (!candidates || candidates.length === 0) {
              setMessages((prev) => [
                ...prev,
                {
                  role: "assistant",
                  content: t("talexaB2B.noCandidatesFound"),
                },
              ]);
              setIsSearchingCandidates(false);
              return;
            }

            // Display each candidate's details
            candidates.forEach((candidate, index) => {
              const candidateName = candidate.Name !== 'N/A' ? candidate.Name : `${t("talexaB2B.candidate")} ${index + 1}`;
              const globalIndex = ((currentPage - 1) * 10) + index + 1;

              setMessages(prev => [
                ...prev,
                {
                  role: "assistant",
                  content: `**${t("talexaB2B.candidate")} ${globalIndex}:**
**${t("talexaB2B.experience")}:** ${candidate["Most Recent Experience"] || t("notSpecified")}
**${t("talexaB2B.skills")}:** ${candidate.Skills?.length > 0 ? candidate.Skills.join(", ") : t("notListed")}`,
                  type: 'candidate',
                  candidateData: candidate,
                  jobData: {
                    role: selectedJob.role,
                    id: selectedJob.id
                  },
                  showAddButton: true
                }
              ]);
            });
          })
          .catch(error => {
            console.error("Search error:", error);
            setMessages(prev => [
              ...prev,
              {
                role: "assistant",
                content: `${t("talexaB2B.searchError")}: ${error.message}. ${t("talexaB2B.tryAgain")}`,
              },
            ]);
          })
          .finally(() => {
            setIsSearchingCandidates(false);
          });
        } catch (error) {
          console.error("Error in job selection:", error);
          setMessages(prev => [
            ...prev,
            {
              role: "assistant",
              content: `${t("talexaB2B.searchError")}: ${error.message}. ${t("talexaB2B.tryAgain")}`,
            },
          ]);
          setIsSearchingCandidates(false);
        }
      }
    }
  };

  const handleCompareResume = (selectedIndex) => {
    if (selectedIndex !== "") {
      const numericIndex = parseInt(selectedIndex, 10);
      const selectedJob = jobOpenings[numericIndex];
      setSelectedJobForResumes(selectedJob);
      setIsComparingResumes(false);
      setIsInResumeComparisonFlow(true); // Set the flag when entering resume comparison flow

      // Prompt for resume upload
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: `${t("talexaB2B.candidateSourceSelection")}\n\n${t("talexaB2B.candidateSource.myPool")}: ${t("talexaB2B.candidateSource.myPoolDescription")}\n${t("talexaB2B.candidateSource.sharedPool")}: ${t("talexaB2B.candidateSource.sharedPoolDescription")}\n${t("talexaB2B.candidateSource.uploadResume")}: ${t("talexaB2B.candidateSource.uploadResumeDescription")}`,
          component: "selection",
          options: [
            {
              id: "myPool",
              label: t("talexaB2B.candidateSource.myPool"),
              description: t("talexaB2B.candidateSource.myPoolDescription"),
              type: "select"
            },
            {
              id: "sharedPool",
              label: t("talexaB2B.candidateSource.sharedPool"),
              description: t("talexaB2B.candidateSource.sharedPoolDescription"),
              type: "select"
            },
            {
              id: "uploadResume",
              label: t("talexaB2B.candidateSource.uploadResume"),
              description: t("talexaB2B.candidateSource.uploadResumeDescription"),
              type: "file-upload",
              accept: ".pdf,.doc,.docx",
              multiple: false
            }
          ],
          onSelect: (selectedOption) => {
            switch (selectedOption) {
              case "myPool":
                // Logic for My Pool selection
                setMessages((prev) => [
                  ...prev,
                  {
                    role: "assistant",
                    content: t("talexaB2B.messages.openingMyPool")
                  }
                ]);
                break;
              case "sharedPool":
                // Logic for Shared Pool selection
                setMessages((prev) => [
                  ...prev,
                  {
                    role: "assistant",
                    content: t("talexaB2B.messages.openingSharedPool")
                  }
                ]);
                break;
              case "uploadResume":
                // Trigger file upload
                setMessages((prev) => [
                  ...prev,
                  {
                    role: "assistant",
                    content: t("talexaB2B.manualQuestions.uploadResumesForComparison"),
                    component: "file-upload",
                    accept: ".pdf,.docx,.doc",
                    multiple: false,
                    onUpload: handleResumeUpload
                  }
                ]);
                break;
            }
          }
        }
      ]);
      return;
    }
  };

  return (

    <div className="talexa-page">

      <div className="title-banner">

        <h1 className="talexa-title">{t("talexaB2B.title")}</h1>

      </div>

      <div className="chat-container">

        <div className="sidebar">

          <button

            className="new-chat-button"

            onClick={handleNewChat}

            disabled={loading}

          >

            <img src={plusIcon} alt="+" className="plus-icon" />

            {t("talexaB2B.newChat")}

          </button>

          <div className="conversations-list">

            {conversations.map((conv) => (

              <div

                key={conv.id}

                className={`conversation-item ${conv.id === currentConversationId ? "active" : ""

                  }`}

                onClick={() => setCurrentConversationId(conv.id)}

              >

                {conv.messages[0]?.content.substring(0, 30)}...

              </div>

            ))}

          </div>

        </div>

        <div className="chat-interface">

          {showActionButtons && (

            <div className="action-buttons">

              {Object.entries(actionPrompts).map(([key, action]) => (

                <button

                  key={key}

                  className="action-button"

                  onClick={

                    key === 'generateJobOffer'

                      ? handleGenerateJobOffer

                      : key === 'searchCandidates'

                        ? handleSearchCandidates :

                        key === 'compareResume'

                          ? handleCompareResumes :

                          () => handleActionClick(key)

                  }

                  disabled={loading}

                >

                  <span className="action-icon typing-dot">{action.icon}</span>

                  {action.label}

                </button>

              ))}

            </div>

          )}

          <div className="chat-messages">

            {messages.map((message, index) => (

              <div

                key={index}

                className={`message ${message.role === "assistant" ? "assistant" : "user"

                  }`}

              >

                <div className="message-content">

                  <MessageContent message={message} />

                  {message.type === 'candidate' && message.showAddButton && (
                    <button
                      className="add-pipeline-btn"
                      onClick={() => handleAddToPipeline(message.candidateData, message)}
                    >
                      {t("talexaB2B.addToPipeline")}
                    </button>
                  )}

                </div>

              </div>

            ))}

            {loading && (

              <div className="message assistant">

                <div className="message-content typing">

                  <span>.</span>

                  <span>.</span>

                  <span>.</span>

                </div>

              </div>

            )}

            <div ref={messagesEndRef} />

          </div>



          <form onSubmit={handleSendMessage} className="chat-input">

            <input

              type="text"

              value={inputMessage}

              onChange={(e) => setInputMessage(e.target.value)}

              onKeyPress={handleKeyPress}

              placeholder={t("talexaB2B.typeYourMessage")}

              disabled={loading}

            />

            <button type="submit" disabled={loading || !inputMessage.trim()}>

              {t("talexaB2B.send")}

            </button>

          </form>

        </div>

      </div>

      {showCandidateSelectionModal && <CandidateSelectionModal />}

    </div>

  );
}
export default TalexaB2B;